import React, {useState} from 'react';
import './style.css';
import {
    IconFooterFacebook, IconFooterGeo,
    IconFooterInstagram,
    IconFooterLinkedIn, IconFooterMail,
    IconFooterYouTube, IconTikTock
} from "../../IconComponents/IconComponents";
import {useNavigate} from "react-router-dom";
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {clearTemplates} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {useDispatch} from "react-redux";
import TermsAndConditions from "../Modals/TermsAndConditions/TermsAndConditions";
import DataProtection from "../Modals/DataProtection/DataProtection";

const Footer = () => {
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
    const [dataProtection, setDataProtection] = useState(false);

    const navigateToTermsAndConditions = () => {
        setTermsAndConditionsOpen(true);
    }
    const navigateToDataProtection = () => {
        setDataProtection(true);
    }

    const navigateToPage = (path: string) => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate(path);
    };


    const toggleSection = (section: React.SetStateAction<string>) => {
        setActiveSection(activeSection === section ? '' : section);
    };
    return (
        <footer className='footer'>
            <div className='ft-info'>
                <div className='ft-logo'>
                    <img className='ft-logo-img' src='/img_projects/gobuild.svg'/>
                </div>
                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('information')}>
                        <h1>MAIN INFORMATION</h1>
                        <span className={`arrow ${activeSection === 'information' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'information' ? 'show' : ''}`}>
                        <p onClick={() => navigateToPage('/home')}>Home</p>
                        <p onClick={() => navigateToPage('/aboutUs')}>About Us</p>
                        <p onClick={() => navigateToPage('/pricing')}>Pricing</p>
                        <p onClick={() => navigateToPage('/functionality')}>Functionality</p>
                        <p onClick={() => navigateToPage('/support')}>Support</p>
                    </div>
                </div>

                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('data')}>
                        <h1>DATA</h1>
                        <span className={`arrow ${activeSection === 'data' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'data' ? 'show' : ''}`}>
                        <div className='ft-contacts-geo'>
                            <div><IconFooterGeo/></div>
                            <p className='ft-click' onClick={navigateToTermsAndConditions}>Terms and
                                Conditions</p>
                        </div>
                        <div className='ft-contacts'>
                            <div><IconFooterMail/></div>
                            <p className='ft-click' onClick={navigateToDataProtection}>Data Protection</p>
                        </div>
                    </div>
                </div>


                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('account')}>
                        <h1>CONTACTS</h1>
                        <span className={`arrow ${activeSection === 'account' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'account' ? 'show' : ''}`}>
                        <div className='ft-contacts-geo'>
                            <div><IconFooterGeo/></div>
                            <p>HEAD OFFICE: GoBuild360 Online (Pty) Ltd Unit 5 Circle Park 1 Sacks Circle Bellville
                                South Cape Town 7560 South Africa</p>
                        </div>
                        <div className='ft-contacts'>
                            <div><IconFooterMail/></div>
                            <p>info@gobuild360.io</p>
                        </div>
                    </div>
                </div>
                <div className='ft-info-block contact-us'>
                    <div className='ft-h' onClick={() => toggleSection('contact')}>
                        <h1>SOCIAL MEDIA</h1>
                        <span className={`arrow ${activeSection === 'contact' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'contact' ? 'show' : ''}`}>
                        <div className='ft-list-social'>
                            <div
                                title='Facebook'
                                onClick={() => window.location.href = 'https://www.facebook.com/share/14bEDsTYxd/?mibextid=LQQJ4d'}>
                                <IconFooterFacebook/></div>
                            <div
                                title='Instagram'
                                onClick={() => window.location.href = 'https://www.instagram.com/gobuildbom?igsh=MmFleTZkamdoZG84'}>
                                <IconFooterInstagram/></div>
                            <div
                                title='LinkedIn'
                            ><IconFooterLinkedIn/></div>
                            <div
                                title='YouTube'
                            ><IconFooterYouTube/></div>
                            <div
                                title='TikTok'
                                onClick={() => window.location.href = 'https://www.tiktok.com/@gobuildbom?_t=8rPPKWMiGYy&_r=1'}>
                                <IconTikTock/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ft-footer'>
                <div className='ft-footer-block'>
                    <div className='ft-footer-block-container'>
                        <p>Powered by GoBuild360°</p>
                        <p>@All rights reserved</p>
                    </div>
                </div>
            </div>
            <TermsAndConditions isOpen={termsAndConditionsOpen} onCancel={() => setTermsAndConditionsOpen(false)} />
            <DataProtection isOpen={dataProtection} onCancel={() => setDataProtection(false)} />
        </footer>
    );
}

export default Footer;
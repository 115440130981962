import React, { useState, useEffect } from 'react';
import './SwitcherTools.css';

interface SwitcherProps {
    options: Array<'Recommended' | 'Minimum'>;
    onChange: (selectedOption: 'Recommended' | 'Minimum') => void;
}

const SwitcherTools: React.FC<SwitcherProps> = ({ options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState< 'Recommended'|'Minimum'>(options[0]);

    useEffect(() => {
        onChange(selectedOption);
    }, [selectedOption, onChange]);

    const handleClick = (option: 'Recommended' | 'Minimum') => {
        setSelectedOption(option);
    };

    return (
        <div className={`switcher-tools switcher-tools-${options.length}`}>
            {options.map((option, index) => (
                <div
                    title={option === 'Recommended' ? 'Calculated QTY = Min + Max' : 'Calculated QTY = Max'}
                    key={index}
                    className={`switcher-option-tools ${selectedOption === option ? 'active-switcher' : ''}`}
                    onClick={() => handleClick(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default SwitcherTools;

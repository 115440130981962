export const unifiedColumnFieldMapping: Record<string, string> = {
    'Name': 'title',
    'Date': 'created_at',
    'Progress': 'progress',
    'Role': 'role',
    'User': 'username',
    'Projects': 'projects_count',
    'Project': 'title',
    'E-mail': 'email',
    'ID': 'id',
    'Address': 'address',
    'Status': 'status',
    'Total': 'total',
};
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TMeInfo} from "../../types/MeInfoTypes/TMeInfo";

interface UserState {
    meInfoLocalStorage: TMeInfo | null;
}

const initialState: UserState = {
    meInfoLocalStorage: null,
};

const MeSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setMeInfoLocalStorage: (state, action: PayloadAction<TMeInfo>) => {
            state.meInfoLocalStorage = action.payload;
            localStorage.setItem('MeInfo', JSON.stringify(action.payload));
        },
        loadMeInfoLocalStorage: (state) => {
            const storedUserInfo = localStorage.getItem('MeInfo');
            if (storedUserInfo) {
                state.meInfoLocalStorage = JSON.parse(storedUserInfo);
            }
        },
    },
});

export const { setMeInfoLocalStorage, loadMeInfoLocalStorage } = MeSlice.actions;

export default MeSlice.reducer;

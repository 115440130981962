import React from 'react';
import {IconClientsLanding, IconProjectLanding, IconTabletLanding} from "../../IconComponents/IconComponents";

interface countInfoSectionProps {
    iconVisible:boolean
}

const CountInfoSection:React.FC<countInfoSectionProps> = ({iconVisible}) => {
    return (
        <section className='landing-middle-info'>
            {iconVisible && (
                <div className='middle-block-text-info'>
                    <div className='middle-block-info-tablet'>
                        <div>
                            <IconTabletLanding/>
                        </div>
                        <div>
                            <p className='middle-block-text-info-number'>5895</p>
                            <p className='middle-block-text-info-text'>Project done</p>
                        </div>
                    </div>
                    <div className='middle-block-info-clients'>
                        <div>
                            <IconClientsLanding/>
                        </div>
                        <div>
                            <p className='middle-block-text-info-number'>220</p>
                            <p className='middle-block-text-info-text'>Clients</p>
                        </div>
                    </div>
                    <div className='middle-block-info-project'>
                        <div>
                            <IconProjectLanding/>
                        </div>
                        <div>
                            <p className='middle-block-text-info-number'>250</p>
                            <p className='middle-block-text-info-text'>Running projects</p>
                        </div>
                    </div>
                </div>
            )}

            <div className='middle-block-text'>
                <p>
                    With over three decades of experience as builders, construction managers, quantity surveyors and
                    architects, we’ve faced the same frustration time and again—there was no solution to automate the
                    measurement of physical quantities from building plans. Whether PDF or digital.
                </p>
                <p>Driven by the need for greater efficiency, we created a comprehensive Bill of Materials (BoM) library
                    and decided to take matters into our own hands.</p>
            </div>
        </section>
    );
};

export default CountInfoSection;
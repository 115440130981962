// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.func-video{
    margin-bottom: 20px;
}
.landing-middle-info-nav-func-info{
    font-size: 24px;
    font-weight: bold;
}
.landing-middle-info-nav-func{
    width: 100%;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
}
.landing-middle-info-nav-func-text{
    width: 100%;
    display: flex;
    justify-content: center;
}
.landing-middle-info-nav-func-text>p{
    width: 80%;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
}
.func-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    height: 205px;
}
.func-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;
    height: 205px;
    text-align: center;
}
.func-block-name{
    font-size: 24px;
    font-weight: bold;
}
.func-block>p:last-child {
    font-size: 16px;
    color: var(--basic-text-landing-color);
    line-height: 1.5;
    margin: 0;
}

.landing-page-support{
    margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Page/LandingPageHome/Functionality/Functionality.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,oBAAoB;IACpB,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,SAAS;AACb;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,sCAAsC;IACtC,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".func-video{\n    margin-bottom: 20px;\n}\n.landing-middle-info-nav-func-info{\n    font-size: 24px;\n    font-weight: bold;\n}\n.landing-middle-info-nav-func{\n    width: 100%;\n    padding-bottom: 25px;\n    display: flex;\n    justify-content: center;\n    font-size: 24px;\n    font-weight: 500;\n}\n.landing-middle-info-nav-func-text{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n.landing-middle-info-nav-func-text>p{\n    width: 80%;\n    text-align: center;\n    font-size: 36px;\n    font-weight: bold;\n    margin: 0;\n}\n.func-section{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 60px 0;\n    height: 205px;\n}\n.func-block{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 380px;\n    height: 205px;\n    text-align: center;\n}\n.func-block-name{\n    font-size: 24px;\n    font-weight: bold;\n}\n.func-block>p:last-child {\n    font-size: 16px;\n    color: var(--basic-text-landing-color);\n    line-height: 1.5;\n    margin: 0;\n}\n\n.landing-page-support{\n    margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react';
import AllProjects from "../../components/AllProjects/AllProjects";
import Templates from "../../components/ProjectsAssistanceTemplates/Templates";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import BOMAllProjects from "../../components/AllProjects/BOMAllProjects";
import {roleArhitector, roleBom} from "../../Enums/UserRoleEnum";
import {useNavigate} from "react-router-dom";
import ArhitectorAllProjects from "../ArchitectPage/ArchitectComponents/ArhitectorAllProject/ArhitectorAllProjects";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {fetchAssistance} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {Project} from "../../types/ProjectTypes";
import {BOMProject} from "../../types/ProjectBOMInterface";
import {ArchitectProject} from "../../types/ArhitectProject";
import {CategoryTemplates} from "../../types/TemplateType";

type PropsFromRedux = ConnectedProps<typeof connector>;

const ProjectAssistance: React.FC<PropsFromRedux> = ({isAuthenticated}) => {
    const [projectsLoading, setProjectsLoading] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const [projectsCustomer, setProjectsCustomer] = useState<Project[]>([]);
    const [projectsBom, setProjectsBom] = useState<BOMProject[]>([]);
    const [projectsArchitect, setProjectsArchitect] = useState<ArchitectProject[]>([]);
    const [categories, setCategories] = useState<CategoryTemplates[]>([]);
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 920);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchLastProjects = async () => {
        setProjectsLoading(true);
        try {
            const response = await fetchAssistance();
            if (response.status === 200) {
                setProjectsCustomer(
                    response.data?.projects?.data && Array.isArray(response.data.projects.data)
                        ? response.data.projects.data
                        : []
                );

                setProjectsBom(
                    response.data?.bom_projects && response.data.bom_projects.data && Array.isArray(response.data.bom_projects.data)
                        ? response.data.bom_projects.data
                        : []
                );

                setProjectsArchitect(
                    response.data?.architect_projects && response.data.architect_projects.data && Array.isArray(response.data.architect_projects.data)
                        ? response.data.architect_projects.data
                        : []
                );


                const fetchedCategories = response.data?.category && response.data.category.data && Array.isArray(response.data.category.data)
                    ? response.data.category.data
                    : [];

                setCategories(fetchedCategories);


                const maxPages = fetchedCategories.reduce((max: number, category: {
                    templates: { pagination: { lastPage: number; }; };
                    templateGroups: { pagination: { lastPage: number; }; };
                }) => {
                    const templatesLastPage = category.templates.pagination?.lastPage || 1;
                    const templateGroupsLastPage = category.templateGroups.pagination?.lastPage || 1;
                    return Math.max(max, templatesLastPage, templateGroupsLastPage);
                }, 1);

                setTotalPages(maxPages);
            } else {
                toast.error('Failed to get projects');
                console.error(response.data);
                setProjectsCustomer([]);
                setProjectsBom([]);
                setProjectsArchitect([]);
                setCategories([]);
                setTotalPages(1);
            }
        } catch (err) {
            console.error(err);
            setProjectsCustomer([]);
            setProjectsBom([]);
            setProjectsArchitect([]);
            setCategories([]);
            setTotalPages(1);
        } finally {
            setProjectsLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchLastProjects();
        } else {
            navigate('/auth/login');
        }
    }, [isAuthenticated]);

    return (
        <div className='wrapper'>
            {!isSmallScreen ? (
                <div className='assistance-placeholder'><p>Home/Project group assistance</p></div>
            ) : (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                    ]}
                />
            )}
            {projectsLoading ? (
                <Loader/>
            ) : (
                <section>
                    {roleArhitector() && <ArhitectorAllProjects projectsArchitect={projectsArchitect}/>}
                    {roleBom() && <BOMAllProjects projectsBom={projectsBom}/>}
                    <AllProjects projects={projectsCustomer}/>
                    <Templates categories={categories} totalPages={totalPages}/>
                </section>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated
});

const connector = connect(mapStateToProps, null);
export default connector(ProjectAssistance);

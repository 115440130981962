import React, { useState, useEffect } from 'react';
import './SwitcherStep2.css';
import {toast} from "react-toastify";

interface SwitcherProps {
    options: Array<'Sections' | 'Product type'>;
    onChange: (selectedOption: 'Sections' | 'Product type') => void;
}

const SwitcherStep2: React.FC<SwitcherProps> = ({ options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState< 'Product type'|'Sections'>(options[0]);

    useEffect(() => {
        onChange(selectedOption);
    }, [selectedOption, onChange]);

    const handleClick = (option: 'Product type' | 'Sections') => {
        setSelectedOption(option);
        toast.success(option === 'Sections' ? 'Everything in one block' : 'Sorting by type. Labors and tools separately')
    };

    return (
        <div className={`switcher switcher-${options.length}`}>
            {options.map((option, index) => (
                <div
                    title={option === 'Sections' ? 'Everything in one block' : 'Sorting by type. Labors and tools separately'}
                    key={index}
                    className={`switcher-option ${selectedOption === option ? 'active-switcher' : ''}`}
                    onClick={() => handleClick(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default SwitcherStep2;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {getAllStores} from "../../api/APIWraper";

interface Store {
    id: number;
    city: string;
}

interface AllStoresState {
    stores: Store[];
    isFetching: boolean;
    error: string | null;
}

const initialState: AllStoresState = {
    stores: [],
    isFetching: false,
    error: null,
};

export const fetchStores = createAsyncThunk<Store[], void, { rejectValue: string }>(
    'stores/fetchStores',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAllStores();
            if (!response.ok) {
                throw new Error('Failed to fetch stores');
            }
            const data = await response.json();
            return data.stores as Store[];
        } catch (error: any) {
            return rejectWithValue(error.message ?? 'Unknown Error');
        }
    }
);

const storesSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchStores.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchStores.fulfilled, (state, action: PayloadAction<Store[]>) => {
                state.isFetching = false;
                state.stores = action.payload;
            })
            .addCase(fetchStores.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isFetching = false;
                state.error = action.payload || 'Failed to load stores';
            });
    }
});

export default storesSlice.reducer;

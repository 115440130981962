import React from 'react';
import Modal from 'react-modal';
import './Purchase3dProject.css';
import { IconWarning} from "../../../IconComponents/IconComponents";

interface ModalProps {
    isOpen: boolean;
    onCancel: () => void;
}

const Purchase3dProject: React.FC<ModalProps> = ({ isOpen, onCancel }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onCancel}
            className="purchase-modal"
            overlayClassName="overlay-purchase-modal"
        >
            <div className="purchase-content">
                <button className="close-button-purchase" onClick={onCancel}>&times;</button>
                <IconWarning/>
                <p className="purchase-text">
                    To upload a 3D file to your project you need to pay $20
                </p>
                <p className='purchase-text-download'>Payment is requested for each download of a new 3D file.</p>
                <div className="button-container-purchase">
                    <button className="cancel-button-purchase" onClick={onCancel}>Cancel</button>
                    <button className="confirm-button-purchase">Proceed to payment</button>
                </div>
            </div>
        </Modal>
    );
};

export default Purchase3dProject;

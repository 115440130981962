import { useDispatch } from 'react-redux';
import { setAuthenticated } from "../../Slice/AuthSlice/AuthSlice";
import { toast, ToastContainer } from "react-toastify";
import { PuffLoader } from "react-spinners";
import { get, post } from "../../api/APIWraper";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconHidePassword, IconShowPassword } from "../../IconComponents/IconComponents";

interface AuthFields {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [authFields, setAuthFields] = useState<AuthFields>({
        email: "",
        password: ""
    });

    const [isBadEmail, setIsBadEmail] = useState(false);
    const [isBadPassword, setIsBadPassword] = useState(false);
    const [isWaitingForSignIn, setIsWaitingForSignIn] = useState(false);
    const [isInputEmailEmpty, setIsInputEmailEmpty] = useState(true);
    const [isInputPasswordEmpty, setIsInputPasswordEmpty] = useState(true);
    const location = useLocation();

    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [passwordTooShort, setPasswordTooShort] = useState(false);
    const [passwordContainsCyrillic, setPasswordContainsCyrillic] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    useEffect(() => {
        const savedRememberMe = localStorage.getItem('rememberMe');
        if (savedRememberMe) {
            setRememberMe(JSON.parse(savedRememberMe));
        }
    }, []);

    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
        localStorage.setItem('rememberMe', JSON.stringify(!rememberMe));
    };

    useEffect(() => {
        setIsBadEmail(false);
        if (authFields.email.match(validEmailRegex)) {
            setIsInputEmailEmpty(false);
        } else {
            setIsInputEmailEmpty(true);
        }
    }, [authFields.email, validEmailRegex]);

    useEffect(() => {
        if (passwordTouched) {
            const password = authFields.password;

            setPasswordTooShort(false);
            setPasswordContainsCyrillic(false);
            setIsBadPassword(false);

            if (password.length < 6) {
                setPasswordTooShort(true);
                setIsBadPassword(true);
                setTimeout(() => setPasswordTooShort(false), 3000);
            }

            if (/[а-яА-ЯЁё]/.test(password)) {
                setPasswordContainsCyrillic(true);
                setIsBadPassword(true);
                setTimeout(() => setPasswordContainsCyrillic(false), 3000);
            }

            if (password === '') {
                setIsInputPasswordEmpty(true);
            } else {
                setIsInputPasswordEmpty(false);
            }
        } else {
            setIsBadPassword(false);
            setPasswordTooShort(false);
            setPasswordContainsCyrillic(false);
        }
    }, [authFields.password, passwordTouched]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            email: e.target.value
        });
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!passwordTouched) {
            setPasswordTouched(true);
        }
        const newPassword = e.target.value;
        setAuthFields({
            ...authFields,
            password: newPassword
        });

        if (newPassword === '') {
            setTimeout(() => setIsBadPassword(false), 2000);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsWaitingForSignIn(true);
        e.preventDefault();
        try {
            const userData = {
                email: authFields.email,
                password: authFields.password,
            };

            let isError = false;
            if (!userData.email.match(validEmailRegex)) {
                setIsBadEmail(true);
                toast.dismiss()
                toast.error("Invalid email format");
                setIsWaitingForSignIn(false);
                isError = true;
            }

            if (userData.password.length < 6) {
                setPasswordTooShort(true);
                setIsBadPassword(true);
                toast.dismiss()
                toast.error("The password must contain at least 6 characters");
                setIsWaitingForSignIn(false);
                isError = true;
            }

            if (/[а-яА-ЯЁё]/.test(userData.password)) {
                setPasswordContainsCyrillic(true);
                setIsBadPassword(true);
                toast.dismiss()
                toast.error("The password must not contain Cyrillic characters");
                setIsWaitingForSignIn(false);
                isError = true;
            }

            if (!isError) {
                const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/login`, userData);
                localStorage.removeItem('DeadToken')

                if (response.data.token) {
                    rememberMe
                        ? localStorage.setItem("Bearer", response.data.token)
                        : sessionStorage.setItem("Bearer", response.data.token);

                    dispatch(setAuthenticated(true));
                    toast.dismiss()
                    toast.success("Login successful");

                    const responseMe = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
                    localStorage.setItem('role', responseMe.data.role.role);
                    localStorage.setItem('userId', responseMe.data.id);
                    localStorage.setItem('Iam',JSON.stringify(responseMe.data))

                    const redirectPath = localStorage.getItem('redirectAfterLogin') || location.state?.from || '/assistance';
                    const scrollPosition = location.state?.scrollPosition || 0;

                    localStorage.removeItem('redirectAfterLogin');

                    setTimeout(() => {
                        navigate(redirectPath);

                        setTimeout(() => {
                            if (scrollPosition) {
                                window.scrollTo(0, scrollPosition);
                            }
                        }, 100);
                    }, 3000);
                } else {
                    toast.dismiss()
                    toast.error("Login failed");
                }
            }
        } catch (error) {
            toast.dismiss()
            toast.error("An error occurred");
        } finally {
            setIsWaitingForSignIn(false);
        }
    };


    const handleForgotPassword = () => {
        navigate("/auth/forgot-password");
    };

    const handleRegistration = () => {
        navigate("/auth/register");
    };

    return (
        <div className="layout-login">
            <div className="login-card">
                <div className="login-card-top">
                    <h3>Log In To Your Account</h3>
                </div>

                <form className="login-card-form" onSubmit={handleSubmit}>
                    <div>
                        <div className={`login-card-form-input-container ${isBadEmail ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>
                                Email
                                <span style={{ color: 'green', marginLeft: '5px' }}>*</span>
                            </label>
                            <input
                                className={`login-card-form-input 
                                ${isBadEmail ? 'red-font-error ' : ''}
                                ${isInputEmailEmpty ? '' : 'login-card-form-input-not-empty'}`}
                                type="email"
                                value={authFields.email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        {isBadEmail && (
                            <p className="login-card-form-input-message">Invalid email</p>
                        )}
                    </div>

                    <div>
                        <div className={`login-card-form-input-container ${isBadPassword ? 'red-font-error' : ''}`}>
                            <label className={`login-card-form-label ${isBadPassword ? 'red-font-error' : ''}`}>
                                Password
                                <span style={{ color: 'green', marginLeft: '5px' }}>*</span>
                            </label>
                            <input
                                className={`login-card-form-input 
                                ${isBadPassword ? 'red-font-error' : ''}
                                ${isInputPasswordEmpty ? '' : 'login-card-form-input-not-empty'}`}
                                type={showPassword ? 'text' : 'password'}
                                value={authFields.password}
                                onChange={handlePasswordChange}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className='login-card-show-password'
                            >
                                {showPassword ? <IconShowPassword /> : <IconHidePassword />}
                            </span>
                        </div>
                        {isBadPassword && (
                            <div className="login-card-form-input-message-container">
                                {passwordTooShort && (
                                    <p className="login-card-form-input-message">
                                        Minimum password length is 6 characters
                                    </p>
                                )}
                                {passwordContainsCyrillic && (
                                    <p className="login-card-form-input-message">
                                        The password must not contain Cyrillic characters
                                    </p>
                                )}
                            </div>
                        )}
                    </div>

                    <div className='login-card-optionals-btn'>
                        <div className='login-card-remember-me'>
                            <label className='login-card-remember-me-label'>
                                <input
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={handleCheckboxChange}
                                    className='login-card-checkbox-remember'
                                />
                                Remember me
                            </label>
                        </div>

                        <p
                            className={`login-card-form-button-forgot ${isWaitingForSignIn ? 'gray-background' : ''}`}
                            onClick={handleForgotPassword}
                        >
                            Forgot password?
                        </p>
                    </div>

                    <div className='auth-block-button'>
                        <button
                            className={`login-card-form-button ${isWaitingForSignIn ? 'gray-background' : ''}`}
                            type="submit"
                        >
                            {!isWaitingForSignIn && "SIGN IN"}
                            {isWaitingForSignIn && (
                                <div className="login-loading-spin">
                                    <PuffLoader size={25} />
                                </div>
                            )}
                        </button>

                        <button
                            type="button"
                            className={`login-card-form-button-registration ${isWaitingForSignIn ? 'gray-background' : ''}`}
                            onClick={handleRegistration}
                        >
                            No account? Create one here
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default Login;

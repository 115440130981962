import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import './style.css';
import PaginationAssistance from "../PaginationAssistance/pagination";
import ModalAddTemplate from "../ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import {IconTemplateEyes, IconTemplatesAssistance} from "../../IconComponents/IconComponents";
import {getAllCategoriesTemplates} from "../../api/APIWraper";
import {CategoryTemplates} from "../../types/TemplateType";
import {Loader} from "../Loader/Loader";

interface AssisTemplateProps {
    categories: CategoryTemplates[];
    totalPages: number;
}

const AsisTemplate: React.FC<AssisTemplateProps> = ({categories, totalPages}) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTempId, setSelectedTempId] = useState<number>(0);
    const [selectedTempTitle, setSelectedTempTitle] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [paginatedCategories, setPaginatedCategories] = useState<CategoryTemplates[]>(categories);
    const [hasFetched, setHasFetched] = useState(false);

    const templatesPerPage = 4;

    const infoTemplateToModal = (id: number, title: string) => {
        setIsModalOpen(true);
        setSelectedTempId(id);
        setSelectedTempTitle(title);
    };

    const fetchCategories = async (page: number) => {
        setLoading(true);
        try {
            const {categories: fetchedCategories} = await getAllCategoriesTemplates(page, templatesPerPage);
            setPaginatedCategories(fetchedCategories);
            setHasFetched(true);
        } catch (error) {
            console.error("Error fetching categories:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (hasFetched || currentPage > 1) {
            fetchCategories(currentPage);
        } else {
            setPaginatedCategories(categories);
        }
    }, [currentPage]);

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
    }, [isModalOpen]);

    return (
        <div>
            <div className='template-name-content-assistance'>
                <p>Templates</p>
                <IconTemplatesAssistance/>
            </div>

            {loading ? <Loader/> : (
                <div className='template-main'>
                    {paginatedCategories.map(category => (
                        <div key={category.id}>
                            {(category.templates.data.length > 0 || category.templateGroups.data.length > 0) && (
                                <h2 className='template-title-text'>{category.category}</h2>
                            )}
                            <div className='template-main-container'>
                                {category.templates.data.map(template => {
                                    const templateImage = template.galleries?.[0]?.medias?.slice(-1)[0]?.url || '/img/house.jpg';
                                    return (
                                        <div className='template-project-list' key={template.id}>
                                            <div onClick={() => navigate(`/template/${template.id}`)}
                                                 className='template-project-get'>
                                                <div className='template-img-shadow' style={{
                                                    backgroundImage: `url(${templateImage})`
                                                }}>
                                                    <IconTemplateEyes/>
                                                </div>
                                                <div className='template-title-block'>
                                                    <p className='template-title'>{template.title}</p>
                                                </div>
                                                <div className='view-details'>View details <span
                                                    className='view-details-arrow'></span></div>
                                            </div>
                                            <div className='template-project-title'>
                                                <button
                                                    onClick={() => infoTemplateToModal(template.id, template.title)}
                                                    className='template-project-btn'>Add to a Project
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}

                                {category.templateGroups.data.map(templateGroup => {
                                    const templateImage = templateGroup.galleries?.[0]?.medias?.slice(-1)[0]?.url || '/img/house.jpg';
                                    return (
                                        <div className='template-project-list' key={templateGroup.id}>
                                            <div onClick={() => navigate(`/template-group/${templateGroup.id}`)}
                                                 className='template-project-get'>
                                                <div className='template-img-shadow' style={{
                                                    backgroundImage: `url(${templateImage})`
                                                }}>
                                                    <IconTemplateEyes/>
                                                </div>
                                                <div className='template-title-block'>
                                                    <p className='template-title'>{templateGroup.title}</p>
                                                </div>
                                                <div className='view-details'>View details <span
                                                    className='view-details-arrow'></span></div>
                                            </div>
                                            <div className='template-project-title'>
                                                <button
                                                    onClick={() => infoTemplateToModal(templateGroup.id, templateGroup.title)}
                                                    className='template-project-btn'>Add to a Project
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <PaginationAssistance
                projectsPerPage={templatesPerPage}
                totalProjects={totalPages * templatesPerPage}
                paginate={(page) => {
                    setCurrentPage(page);
                    if (page === 1) {
                        setHasFetched(false);
                    }
                }}
                currentPage={currentPage}
            />

            <ModalAddTemplate
                isOpen={isModalOpen}
                tempId={selectedTempId}
                tempTitle={selectedTempTitle}
                onClose={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default AsisTemplate;

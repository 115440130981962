import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {ItemType, TItem, TMerchanInfo} from "../../../types/TemplateType";
import {Project} from "../../../types/ProjectTypes";
import {get, patch} from "../../../api/APIWraper";
import {updateLocalTemplate} from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import ItemSelectionModal from "../ItemSelectionModal";
import IToolsItemType from "../../../Interfaces/ProjectInterfaces/IToolsItemType";
import {Vendor, vendorData} from "../../../Enums/VendorEnum";
import {IconArrowLightStpe2, IconStep2SelectArrow} from "../../../IconComponents/IconComponents";

interface ItemTypeListCalculationProps {
    toolsItemTypes: IToolsItemType[];
    templateIndex: number;
    templateId: number;
    project: Project;
    onUpdateProject: (newProject: Project) => void;
    onToggle: () => void;
    relatedProjectItemTypes?: ItemType[];
    viewModeTools: "Recommended" | "Minimum";
}

interface SelectedItemInfo {
    packaging: any;
    vendor: Vendor | null;
    merchant_info: TMerchanInfo;
    user_item_qty?: number;
    item_packaging_qty?: number;
    item_packaging_value?: string;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}

interface SelectedItem {
    itemTypeId: number;
    itemId: number;
    originalIndex: number;
    index: number;
    user_item_qty: number;
    item_packaging_qty: number;
    item_packaging_value: string;
    clone_id: string;
}

interface GroupedItem {
    item_type_id: number;
    item_ids: { id: number; qty: number }[];
    template_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    fragment_index: number;
    originalQty: number;
}

const ToolsItemTypeListCalculation: React.FC<
    ItemTypeListCalculationProps & PropsFromRedux
> = ({
         toolsItemTypes,
         project,
         onUpdateProject,
         onToggle,
         templateId,
         viewModeTools,
     }) => {
    const [mergedItemTypes, setMergedItemTypes] = useState<
        (IToolsItemType & { originalIndex: number })[]
    >([]);
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{
        [id: string]: SelectedItemInfo;
    }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{
        [id: string]: string;
    }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>(
        {}
    );

    const [itemTypeUnit,setItemTypeUnit] = useState<string>('')

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
        window.innerWidth <= 920
    );
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>(
        {}
    );

    const generateItemTypeKey = (item: TItem, idx: number) => {
        return `${item.id}-${idx}-${item.clone_id}-${item.title}`;
    };

    const generateItemKeyVisible = (
        itemInfo: SelectedItemInfo,
        selectedItem: SelectedItem,
        idx: number
    ) => {
        return `${itemInfo.id}-${itemInfo.title}-${selectedItem.index}-${idx}`;
    };

    const toggleVisibility = (key: string) => {
        setVisibilityMap((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const isVisible = (key: string) => {
        return visibilityMap[key] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const mergeItemTypes = (): (IToolsItemType & { originalIndex: number })[] => {
            const mergedMap: { [key: number]: IToolsItemType & { originalIndex: number } } = {};
            toolsItemTypes.forEach((item, index) => {
                const itemTypeId = item.item_type.id;
                if (mergedMap[itemTypeId]) {
                    const existing = mergedMap[itemTypeId];
                    item.item_type.items?.forEach((toolItem) => {
                        const existTool = existing.item_type.items?.find((ti) => ti.id === toolItem.id);
                        if (existTool) {
                            existTool.qty =
                                viewModeTools === "Minimum"
                                    ? Math.max(existTool.qty, toolItem.qty)
                                    : existTool.qty + toolItem.qty;
                            existTool.ordered_qty =
                                viewModeTools === "Minimum"
                                    ? Math.max(existTool.ordered_qty ?? 0, toolItem.ordered_qty ?? 0)
                                    : (existTool.ordered_qty ?? 0) + (toolItem.ordered_qty ?? 0);
                        } else {
                            existing.item_type.items?.push({...toolItem});
                        }
                    });
                } else {
                    const cloned = JSON.parse(JSON.stringify(item));
                    mergedMap[itemTypeId] = {...cloned, originalIndex: index};
                }
            });
            const mergedArr = Object.values(mergedMap);
            mergedArr.sort((a, b) => a.originalIndex - b.originalIndex);
            return mergedArr;
        };
        const merged = mergeItemTypes();
        setMergedItemTypes(merged);
    }, [toolsItemTypes, viewModeTools]);

    useEffect(() => {
        if (mergedItemTypes.length === 0) return;
        const initialSelectedItems: SelectedItem[] = mergedItemTypes.flatMap(
            (mergedItem, mergedIdx) =>
                (mergedItem.item_type.items || []).map((itm, itemIdx) => {
                    const packagingKeys = itm.packaging ? Object.keys(itm.packaging) : [];
                    return {
                        itemTypeId: mergedItem.item_type.id,
                        itemId: itm.id,
                        originalIndex: mergedItem.originalIndex,
                        index: mergedIdx,
                        user_item_qty: itm.qty,
                        item_packaging_qty: packagingKeys.length > 0 ? itm.packaging[packagingKeys[0]] : 1,
                        item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : "psc",
                        clone_id: `${itm.id}-${mergedIdx}-${itemIdx}`,
                        packaging:itm.packaging
                    };
                })
        );
        setSelectedItems(initialSelectedItems);

        const initialSelectedItemInfos = initialSelectedItems.reduce((acc, sel) => {
                const mergedItem = mergedItemTypes.find(
                    (mi) => mi.item_type.id === sel.itemTypeId
                );
                const itm = mergedItem?.item_type.items?.find((i: any) => i.id === sel.itemId);

                if (mergedItem && itm) {
                    const packagingKeys = itm.packaging ? Object.keys(itm.packaging) : [];
                    acc[sel.clone_id] = {
                        merchant_info: {} as TMerchanInfo,
                        vendor: itm.vendor,
                        user_item_qty: sel.user_item_qty,
                        item_packaging_qty: packagingKeys.length > 0 ? itm.packaging[packagingKeys[0]] : 1,
                        item_packaging_value: packagingKeys.length > 0 ? packagingKeys[0] : "psc",
                        title: itm.title,
                        id: itm.id,
                        qty: itm.qty || 0,
                        ordered_qty: itm.ordered_qty || 0,
                        unit: itm.unit,
                        price: itm.price || 0,
                        packaging:itm.packaging

                    };
                }
                return acc;
            }, {} as { [id: string]: SelectedItemInfo }
        );
        setSelectedItemInfos(initialSelectedItemInfos);

        const parsed = mergedItemTypes.reduce((acc, mergedItem, mergedIdx) => {
            const clone_id = `${mergedItem.item_type.id}-${mergedIdx}-${mergedIdx}`;
            const info = initialSelectedItemInfos[clone_id];
            const title = mergedItem.item_type.title;
            let qty = info ? info.qty : mergedItem.qty;
            if (!qty) qty = 0;
            let orderedQty = info ? info.ordered_qty : mergedItem.item_type.ordered_qty;
            if (!orderedQty) orderedQty = 0;
            const price =
                mergedItem.item_type.items && mergedItem.item_type.items.length > 0
                    ? mergedItem.item_type.items[0].price
                    : 0;
            const priceCurrentQty = info ? info.price * orderedQty : 0;
            const priceTotalQty = info ? info.price * qty : 0;
            const isItemSelected =
                mergedItem.item_type.items && mergedItem.item_type.items.length > 0;
            const unit =
                mergedItem.item_type.items && mergedItem.item_type.items.length > 0
                    ? mergedItem.item_type.items[0].unit
                    : "";
            const unitItemType = mergedItem.item_type.unit ? mergedItem.item_type.unit : "each";
            acc[mergedIdx] = {
                index: mergedIdx,
                originalIndex: mergedItem.originalIndex,
                title,
                qty,
                orderedQty,
                price,
                priceCurrentQty,
                priceTotalQty,
                isItemSelected,
                unit,
                unitItemType,
                itemId: mergedItem.item_type.id,
            };
            return acc;
        }, {} as { [index: number]: any });
        setParsedItemTypes(parsed);
        calculateTotalPrice();
    }, [mergedItemTypes]);

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    useEffect(() => {
        const handler = setTimeout(() => {
            Object.keys(debouncedOrderedQty).forEach((id) => {
                setSelectedItemInfos((prev) => {
                    const newQty = parseInt(debouncedOrderedQty[id]) || 0;
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            ordered_qty: newQty,
                        },
                    };
                });
            });
            calculateTotalPrice();
        }, 500);
        return () => clearTimeout(handler);
    }, [debouncedOrderedQty, selectedItems]);

    const calculateTotalPrice = () => {
        let total = 0;
        selectedItems.forEach((sel) => {
            const info = selectedItemInfos[sel.clone_id];
            if (info) {
                const generalPrice = info.price * (info.user_item_qty || info.qty);
                const difTotalPrice = Math.max(
                    0,
                    info.price * Math.min(info.ordered_qty, info.user_item_qty || info.qty)
                );
                total += generalPrice - difTotalPrice;
            }
        });
        setTotalPrice(total);
    };

    const storeId = project?.store_id ? project.store_id : 0;

    const fetchItems = async (mergedIndex: number, page: number, search: string = "") => {
        setSelectedItemTypeIndex(mergedIndex);
        setLoading(true);
        try {
            if (!mergedItemTypes[mergedIndex]) return;
            const itemTypeId = mergedItemTypes[mergedIndex].item_type.id;
            const itemTypeUnit = mergedItemTypes[mergedIndex].unit;

            const shouldIncludeUnit = itemTypeUnit && itemTypeUnit !== "each" && itemTypeUnit !== "psc";

            let url = `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${itemTypeId}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`;

            if (search) {
                url += `&title=${encodeURIComponent(search)}`;
            }
            if (shouldIncludeUnit) {
                url += `&packaging=${encodeURIComponent(itemTypeUnit)}`;
            }

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);

            const itemsWithQty = (Array.isArray(response.data.items.data)
                    ? response.data.items.data
                    : [response.data.items.data]
            ).map((item: TItem) => {
                let qty_item_type = 0;
                if (viewModeTools === "Minimum") {
                    qty_item_type = Math.max(
                        ...toolsItemTypes
                            .filter((t) => t.item_type.id === itemTypeId)
                            .map((t) => t.qty)
                    );
                } else {
                    qty_item_type = toolsItemTypes
                        .filter((t) => t.item_type.id === itemTypeId)
                        .reduce((total, current) => total + current.qty, 0);
                }
                return {...item, qty_item_type};
            });

            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };


    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };

    const handleButtonClick = (mergedIndex: number,itemTypeUnit:string) => {
        setIsModalOpen(true);
        setItemTypeUnit(itemTypeUnit)
        fetchItems(mergedIndex, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setItemTypeUnit('')
        setCurrentPage(1)
        setSearchTerm('')
    };

    const handleItemClick = (modalItems: TItem[], mergedIndex: number | null) => {
        if (mergedIndex === null) return;
        const currentOriginalIndex = mergedItemTypes[mergedIndex].originalIndex;
        const updatedSelectedItems = [
            ...selectedItems.filter((si) => si.originalIndex !== currentOriginalIndex),
            ...modalItems.map((item, idx) => ({
                itemTypeId: mergedItemTypes[mergedIndex].item_type.id,
                itemId: item.id,
                originalIndex: currentOriginalIndex,
                index: mergedIndex,
                user_item_qty: item.user_item_qty,
                item_packaging_qty:
                    item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value: Object.keys(item.packaging)[0] || "psc",
                clone_id: `${item.id}-${mergedIndex}-${idx}`,
                packaging:item.packaging
            })),
        ];

        const updatedItemInfos = modalItems.reduce((acc, item, idx) => {
            const clone_id = `${item.id}-${mergedIndex}-${idx}`;
            acc[clone_id] = {
                merchant_info: {} as TMerchanInfo,
                vendor: item.vendor,
                title: item.title,
                id: item.id,
                qty: item.qty,
                ordered_qty: item.ordered_qty || 0,
                user_item_qty: item.user_item_qty || 0,
                item_packaging_qty:
                    item.packaging[Object.keys(item.packaging)[0]] || 1,
                item_packaging_value: Object.keys(item.packaging)[0] || "psc",
                unit: item.unit,
                price: item.price || 0,
                packaging:item.packaging
            };
            return acc;
        }, {...selectedItemInfos});

        setSelectedItemInfos(updatedItemInfos);
        setSelectedItems(updatedSelectedItems);
        setParsedItemTypes((prev) => ({
            ...prev,
            [mergedIndex]: {
                ...prev[mergedIndex],
                isItemSelected: modalItems.length > 0,
                qty: modalItems.length > 0 ? modalItems[0].qty : 0,
                orderedQty: modalItems.length > 0 ? modalItems[0].ordered_qty || 0 : 0,
                user_item_qty: modalItems.length > 0 ? modalItems[0].user_item_qty || 0 : 0,
                unit: modalItems.length > 0 ? modalItems[0].unit : "",
                price: modalItems.length > 0 ? modalItems[0].price || 0 : 0,
                priceCurrentQty:
                    modalItems.length > 0
                        ? (modalItems[0].price || 0) * (modalItems[0].user_item_qty || 0)
                        : 0,
                priceTotalQty:
                    modalItems.length > 0 ? (modalItems[0].price || 0) * modalItems[0].qty : 0,
            },
        }));
        calculateTotalPrice();
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        setDebouncedOrderedQty((prev) => ({
            ...prev,
            [clone_id]: !isNaN(parsedQty) ? newQty : "",
        }));
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: !isNaN(parsedQty) ? parsedQty : 0,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async () => {
        function isMergedItemType(itemTypeId: number): boolean {
            return toolsItemTypes.filter(toolItem => toolItem.item_type.id === itemTypeId).length > 1;
        }

        function generateCascadeIndexes(itemTypeId: number) {
            return toolsItemTypes
                .filter(toolItem => toolItem.item_type.id === itemTypeId)
                .map(toolItem => ({
                    fragment_id: toolItem.fragment_id,
                    fragment_index: toolItem.fragment_index,
                    item_type_index: toolItem.item_type_index,
                    qty: toolItem.item_type?.qty || toolItem.qty,
                }));
        }

        async function handleItemOrderedQty(isCascade: boolean): Promise<Project | undefined> {
            const payload = Object.entries(debouncedOrderedQty)
                .map(([clone_id, qtyStr]) => {
                    const selectedItem = selectedItems.find(item => item.clone_id === clone_id);
                    if (!selectedItem) return null;
                    if (isMergedItemType(selectedItem.itemTypeId) !== isCascade) return null;
                    const userOrderedQty = parseInt(qtyStr, 10);
                    return {
                        ordered_qty: userOrderedQty,
                        template_index: toolsItemTypes[selectedItem.index]?.template_index || 0,
                        item_type_index: toolsItemTypes[selectedItem.index]?.item_type_index || 0,
                        template_id: templateId,
                        item_type_id: selectedItem.itemTypeId,
                        item_id: selectedItem.itemId,
                        fragment_id: toolsItemTypes[selectedItem.index]?.fragment_id || 0,
                        fragment_index: toolsItemTypes[selectedItem.index]?.fragment_index || 0,
                        ...(isCascade && {indexes: generateCascadeIndexes(selectedItem.itemTypeId)}),
                    };
                })
                .filter(item => item !== null);

            if (payload.length > 0) {
                const url = isCascade
                    ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty_cascade`
                    : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`;
                try {
                    const response = await patch(url, {data: payload});
                    return response.data;
                } catch (error) {
                    console.error(`Failed to update ordered_qty ${isCascade ? 'cascade' : ''}:`, error);
                }
            }
        }

        const groupedItems = selectedItems.reduce(
            (acc: { [key: string]: GroupedItem & { originalQty: number } }, selection) => {
                const {itemTypeId, index} = selection;
                const key = `${itemTypeId}-${index}`;
                const info = selectedItemInfos[selection.clone_id];
                const toolItem = toolsItemTypes[index];
                const originalQtyFromTool =
                    toolItem?.item_type?.items && toolItem.item_type.items.length > 0 ? toolItem.qty : 0;
                const originalQty = info && typeof info.qty !== "undefined" ? info.qty : originalQtyFromTool;

                if (!acc[key]) {
                    acc[key] = {
                        item_type_id: itemTypeId,
                        item_ids: [],
                        template_index: toolItem?.template_index || 0,
                        item_type_index: toolItem?.item_type_index || 0,
                        template_id: templateId,
                        fragment_id: toolItem?.fragment_id || 0,
                        fragment_index: toolItem?.fragment_index || 0,
                        originalQty,
                    };
                }
                if (selection.itemId) {
                    const qty =
                        (selection.user_item_qty !== undefined ? selection.user_item_qty : 1) *
                        (selection.item_packaging_qty || 1);
                    acc[key].item_ids.push({id: selection.itemId, qty});
                }
                return acc;
            },
            {} as { [key: string]: GroupedItem & { originalQty: number } }
        );

        const ordinaryItems = Object.values(groupedItems).filter(
            group => !isMergedItemType(group.item_type_id)
        );
        const mergedItems = Object.values(groupedItems).filter(group =>
            isMergedItemType(group.item_type_id)
        );

        const ordinaryChangedItems = ordinaryItems.filter(group => {
            const newQty = group.item_ids.reduce((sum, item) => sum + item.qty, 0);
            return newQty !== group.originalQty;
        });
        const mergedChangedItems = mergedItems.filter(group => {
            const newQty = group.item_ids.reduce((sum, item) => sum + item.qty, 0);
            return newQty !== group.originalQty;
        });

        const deletedItems = toolsItemTypes
            .filter(item => item.item_type.items && item.item_type.items.length > 0)
            .map(item => ({
                item_type_id: item.item_type.id,
                item_ids: [],
                template_index: item.template_index,
                item_type_index: item.item_type_index,
                template_id: templateId,
                fragment_id: item.fragment_id,
                fragment_index: item.fragment_index,
            }))
            .filter(item =>
                !selectedItems.some(selection => selection.itemTypeId === item.item_type_id)
            );

        let updatedProject: Project | undefined;

        try {
            const ordinaryQtyPayloadExists = Object.entries(debouncedOrderedQty).some(
                ([clone_id]) => {
                    const sel = selectedItems.find(item => item.clone_id === clone_id);
                    return sel && !isMergedItemType(sel.itemTypeId);
                }
            );
            if (ordinaryChangedItems.length > 0 || deletedItems.length > 0 || ordinaryQtyPayloadExists) {
                const payload = [...ordinaryChangedItems, ...deletedItems];
                if (payload.length > 0) {
                    await patch(
                        `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`,
                        {data: payload}
                    );
                }
                const ordOrderedResponse = await handleItemOrderedQty(false);
                if (ordOrderedResponse) {
                    updatedProject = ordOrderedResponse;
                }
            }

            const mergedQtyPayloadExists = Object.entries(debouncedOrderedQty).some(
                ([clone_id]) => {
                    const sel = selectedItems.find(item => item.clone_id === clone_id);
                    return sel && isMergedItemType(sel.itemTypeId);
                }
            );
            if (mergedChangedItems.length > 0 || mergedQtyPayloadExists) {
                const mergedPayload = mergedChangedItems.map(item => ({
                    ...item,
                    indexes: generateCascadeIndexes(item.item_type_id),
                }));
                if (mergedPayload.length > 0) {
                    await patch(
                        `${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item_cascade`,
                        {data: mergedPayload}
                    );
                }
                const mergedOrderedResponse = await handleItemOrderedQty(true);
                if (mergedOrderedResponse) {
                    updatedProject = mergedOrderedResponse;
                }
            }
        } catch (error) {
            console.error("Failed to save selections:", error);
        }

        handleCloseModal();
        if (updatedProject) {
            onUpdateProject(updatedProject);
            onToggle();
        }
    };


    if (!toolsItemTypes || !Array.isArray(toolsItemTypes)) {
        return null;
    }

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };

    return (
        <div>
            {toolsItemTypes.length > 0 && (
                <>
                    {Object.values(parsedItemTypes).map((parsedItem, idx) => {
                        if (!parsedItem) return null;
                        const {title, price,qty, priceTotalQty,unit,unitItemType} = parsedItem;
                        const totalQty = toolsItemTypes.filter((tool) => tool.item_type.id === mergedItemTypes[parsedItem.index]?.item_type.id)
                            .reduce((sum, tool) => sum + tool.qty, 0);
                        const selectedItemsForType = selectedItems.filter(
                            (si) =>
                                si.itemTypeId ===
                                mergedItemTypes[parsedItem.index]?.item_type.id
                        );
                        const itemTypeKey = generateItemTypeKey(parsedItem, idx);
                        return (
                            <div
                                key={`${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                            >
                                <label
                                    className='static-item-type-info'
                                    htmlFor={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                                >
                                    <p className='static-item-type-info-title'>{title}</p>
                                    <p className='static-item-type-info-qty'>{qty} ({unitItemType})</p>
                                </label>
                                <div>
                                    {selectedItemsForType.length === 0 ? (
                                        !isSmallScreen ? (
                                            <div className="step2-item-type-list">
                                                <button
                                                    className="step2-item-button-list"
                                                    id={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                                                    onClick={() => handleButtonClick(parsedItem.index,unit)}
                                                    disabled={loading}
                                                >
                                                    {loading &&
                                                    selectedItemTypeIndex === parsedItem.index
                                                        ? "Loading..."
                                                        : "Select Tools"}
                                                    <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                </button>
                                                <p className="step2-other-width-list"></p>
                                                <div className="step2-tooltip-container">
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        min="0"
                                                        disabled={!loading}
                                                    />
                                                </div>
                                                <p className="step2-other-width-list">
                                                    R {parseInt(price).toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list">
                                                    {isNaN(totalQty * price) || totalQty === 0
                                                        ? 0
                                                        : (totalQty * price).toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list-total">
                                                    R {Math.max(0, priceTotalQty).toFixed(2)}
                                                </p>
                                                <div className="step2-other-width-vendor-list"></div>
                                            </div>
                                        ) : (
                                            <div className="step2-item-type-list">
                                                <div className="step2-item-type-list-block-arrow">
                                                    <button
                                                        className="step2-item-button-list"
                                                        id={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}`}
                                                        onClick={() => handleButtonClick(parsedItem.index,unit)}
                                                        disabled={loading}
                                                    >
                                                        {loading &&
                                                        selectedItemTypeIndex === parsedItem.index
                                                            ? "Loading..."
                                                            : "Select Tools"}
                                                        <IconArrowLightStpe2/>

                                                    </button>
                                                    <div
                                                        onClick={() => toggleVisibility(itemTypeKey)}
                                                        className={
                                                            isVisible(itemTypeKey)
                                                                ? "icon-arrow-light-step2-up-calc"
                                                                : "icon-arrow-light-step2-down-calc"
                                                        }
                                                    >
                                                        <IconArrowLightStpe2/>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`step2-content-list ${
                                                        isVisible(itemTypeKey) ? "visible" : "hidden"
                                                    }`}
                                                >
                                                    <div className="step2-tooltip-container">
                                                        <span>QTY to order:</span>
                                                        <span className="dashed-line"></span>
                                                        <input
                                                            type="number"
                                                            className="step2-other-width-input"
                                                            min="0"
                                                            disabled={!loading}
                                                        />
                                                    </div>
                                                    <p className="step2-other-width-list">
                                                        <span>QTY ordered:</span>
                                                        <span className="dashed-line"></span>
                                                        {totalQty} (
                                                        {mergedItemTypes[parsedItem.index]?.item_type.unit
                                                            ? mergedItemTypes[parsedItem.index]?.item_type.unit
                                                            : "psc"}
                                                        )
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Unit price:</span>
                                                        <span className="dashed-line"></span>
                                                        R {parseInt(price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list">
                                                        <span>Total:</span>
                                                        <span className="dashed-line"></span>
                                                        {isNaN(totalQty * price) || totalQty === 0
                                                            ? 0
                                                            : (totalQty * price).toFixed(2)}
                                                    </p>
                                                    <p className="step2-other-width-list-total total-ordred-bold">
                                                        <span>Total ordered:</span>
                                                        <span className="dashed-line"></span>
                                                        R {Math.max(0, priceTotalQty).toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="step2-item-column">
                                            {selectedItemsForType.map((selectedItem, i) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.user_item_qty
                                                    ? item.price * item.user_item_qty
                                                    : 0;
                                                const oldPrice = item.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice)
                                                    ? 0
                                                    : oldPrice - difPrice;
                                                const userQty = item.user_item_qty ? item.user_item_qty : 0;
                                                const toolKeyVisible = generateItemKeyVisible(item, selectedItem, i);
                                                return !isSmallScreen ? (
                                                    <div
                                                        key={`${selectedItem.clone_id}`}
                                                        className="step2-item-type-list"
                                                    >
                                                        <button
                                                            className="step2-item-button-list"
                                                            id={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}-${i}`}
                                                            onClick={() => handleButtonClick(parsedItem.index,unit)}
                                                            disabled={loading}
                                                        >
                                                            {loading &&
                                                            selectedItemTypeIndex === parsedItem.index
                                                                ? "Loading..."
                                                                : item.title}
                                                            <div className='step2-select-arrow'><IconStep2SelectArrow/></div>

                                                        </button>
                                                        {/*<p className="step2-other-width-list">*/}
                                                        {/*    {item.user_item_qty || totalQty} (*/}
                                                        {/*    {item.packaging && typeof item.packaging === 'object' && Object.keys(item.packaging).length > 0*/}
                                                        {/*        ? Object.keys(item.packaging)[0]*/}
                                                        {/*        : item.unit ? item.unit : 'psc'}*/}
                                                        {/*    )*/}
                                                        {/*</p>*/}
                                                        <p className="step2-other-width-list">
                                                            {item.user_item_qty || totalQty} (psc)
                                                        </p>
                                                        <div className="step2-tooltip-container">
                                                            <input
                                                                type="number"
                                                                className="step2-other-width-input"
                                                                value={
                                                                    debouncedOrderedQty[selectedItem.clone_id] ??
                                                                    item.ordered_qty
                                                                }
                                                                onChange={(e) =>
                                                                    handleQtyChange(selectedItem.clone_id, e.target.value)
                                                                }
                                                                min="0"
                                                            />
                                                            <span className="packing-value-steps">psc</span>
                                                        </div>
                                                        <p className="step2-other-width-list">
                                                            R {parseInt(String(item.price)).toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width-list">
                                                            R{" "}
                                                            {isNaN(userQty * item.price)
                                                                ? 0
                                                                : (userQty * item.price).toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width-list-total">
                                                            R {Math.max(0, difTotalPrice).toFixed(2)}
                                                        </p>
                                                        <div className="step2-other-width-vendor-list">
                                                            {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                <p className="item-selection-modal-vendor">
                                                                    {vendorData[item.vendor as Vendor].displayName}
                                                                </p>
                                                            ) : (
                                                                <p className="item-selection-modal-no-vendor">
                                                                    No Vendor
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        key={`${selectedItem.clone_id}`}
                                                        className="step2-item-type-list"
                                                    >
                                                        <div className="step2-item-type-list-block-arrow">
                                                            <button
                                                                className="step2-item-button-list"
                                                                id={`button-${mergedItemTypes[parsedItem.index]?.item_type.id}-${idx}-${i}`}
                                                                onClick={() => handleButtonClick(parsedItem.index,unit)}
                                                                disabled={loading}
                                                            >
                                                                {loading &&
                                                                selectedItemTypeIndex === parsedItem.index
                                                                    ? "Loading..."
                                                                    : item.title}
                                                                <div className='step2-select-arrow'><IconStep2SelectArrow/></div>
                                                            </button>
                                                            <div
                                                                onClick={() =>
                                                                    toggleVisibility(generateItemKeyVisible(item, selectedItem, i))
                                                                }
                                                                className={
                                                                    isVisible(generateItemKeyVisible(item, selectedItem, i))
                                                                        ? "icon-arrow-light-step2-up-calc"
                                                                        : "icon-arrow-light-step2-down-calc"
                                                                }
                                                            >
                                                                <IconArrowLightStpe2/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`step2-content-list ${
                                                                isVisible(generateItemKeyVisible(item, selectedItem, i))
                                                                    ? "visible"
                                                                    : "hidden"
                                                            }`}
                                                        >
                                                            <div className="step2-tooltip-container">
                                                                <span>QTY to order:</span>
                                                                <span className="dashed-line"></span>
                                                                <input
                                                                    type="number"
                                                                    className="step2-other-width-input"
                                                                    value={
                                                                        debouncedOrderedQty[selectedItem.clone_id] ??
                                                                        item.ordered_qty
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleQtyChange(selectedItem.clone_id, e.target.value)
                                                                    }
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <p className="step2-other-width-list">
                                                                <span>QTY ordered:</span>
                                                                <span className="dashed-line"></span>
                                                                {item.user_item_qty || item.qty} (psc)
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Unit price:</span>
                                                                <span className="dashed-line"></span> R{" "}
                                                                {parseInt(String(item.price)).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list">
                                                                <span>Total:</span>
                                                                <span className="dashed-line"></span>{" "}
                                                                {isNaN(userQty * item.price)
                                                                    ? 0
                                                                    : (userQty * item.price).toFixed(2)}
                                                            </p>
                                                            <p className="step2-other-width-list-total total-ordred-bold">
                                                                <span>Total ordered:</span>
                                                                <span className="dashed-line"></span>
                                                                R {Math.max(0, difTotalPrice).toFixed(2)}
                                                            </p>
                                                            <div className="step2-other-width-vendor-list">
                                                                {item.vendor !== null && vendorData[item.vendor as Vendor] ? (
                                                                    <p className="item-selection-modal-vendor">
                                                                        {vendorData[item.vendor as Vendor].displayName}
                                                                    </p>
                                                                ) : (
                                                                    <p className="item-selection-modal-no-vendor">
                                                                        No Vendor
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            <ItemSelectionModal
                itemTypeUnit={itemTypeUnit ? itemTypeUnit : ''}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(
                    (si) => si.index === selectedItemTypeIndex
                )}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />
            <div className="step2-bottom-block">
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className="step2-bottom-block-btn-cart-list" onClick={handleSave}>Save</button>
                    </>
                ) : (
                    <>
                        <div className="step2-bottom-block-mobile">
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className="mobile-total-price-red">R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className="mobile-total-price-red"> R 0</span>
                            )}
                        </div>
                        <button className="step2-bottom-block-btn-cart-list" onClick={handleSave}>Save</button>
                    </>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToolsItemTypeListCalculation);

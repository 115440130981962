import React from "react";
import "./Loader.css";

export const Loader = () => {
    const bricks = Array.from({ length: 6 });

    return (
        <div className="loader-container">
            {bricks.map((_, index) => (
                <div key={index} className={`brick brick${index + 1}`}></div>
            ))}
        </div>
    );
};

export enum UserRoleEnum {
    SAU = "SAU",
    MAU = "MAU",
    Customer = "Customer",
    Contractor = "Contractor",
}

export const roleBom = (): boolean => {
    const role = localStorage.getItem('role') as UserRoleEnum;
    return role === UserRoleEnum.SAU || role === UserRoleEnum.Contractor;
};

import React from "react";
import {IconLogOutRevers} from "../../IconComponents/IconComponents";
import "./DropDownUserList.css";
import {User} from "../../types/ProjectTypes";
import {SideBarNotification, SideBarPlan, SideBarPrivacy, SideBarSettings} from "./Sidebar/IconsSidebar";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

interface DropDownUserListProps {
    userPhoto: string;
    user: User;
    isOpen: boolean;
}

const DropDownUserList: React.FC<DropDownUserListProps> = ({userPhoto, user, isOpen}) => {
    const navigate = useNavigate();

    const handleLogOut = () => {
        toast.info("Wait, we're coming out...");
        setTimeout(() => {
            sessionStorage.removeItem("Bearer");
            localStorage.removeItem("Bearer");
            localStorage.removeItem("role");
            localStorage.removeItem("oldRole");
            localStorage.removeItem("userId");
            localStorage.removeItem("Iam");
            navigate("/home");
            toast.success("You have successfully logged out!");
        }, 1000);
    };

    return (
        <section
            className={`drop-down-user-list-container ${isOpen ? 'open' : 'close'}`}>
            <div className="user-dropdown-user-name">
                <div>
                    <img src={userPhoto} alt={user.username} className="user-dropdown-avatar"/>
                </div>
                <p className="user-block-username">{user.username}</p>
                <p className="user-block-email">{user.email}</p>
            </div>
            <main className="drop-down-user-list-main">
                <div className="drop-down-user-list-block">
                    <SideBarSettings/>
                    <p className="drop-down-user-list-block-text">Account Settings</p>
                </div>
                <div className="drop-down-user-list-block">
                    <SideBarNotification/>
                    <p className="drop-down-user-list-block-text">Notification Preferences</p>
                </div>
                <div className="drop-down-user-list-block">
                    <SideBarPrivacy/>
                    <p className="drop-down-user-list-block-text">Privacy Preferences</p>
                </div>
                <div className="drop-down-user-list-block">
                    <SideBarPlan/>
                    <p className="drop-down-user-list-block-text">Subscription plan</p>
                </div>
            </main>
            <div className="user-dropdown-btn-block">
                <button onClick={handleLogOut} className="user-dropdown-btn-block-button">
                    <IconLogOutRevers/>
                    <p className="user-dropdown-btn-block-button-text">Log Out</p>
                </button>
            </div>
        </section>
    );
};

export default DropDownUserList;

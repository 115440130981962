export const clearLocalStorageExceptToken = () => {
    const sessionAuthToken = sessionStorage.getItem('Bearer');
    const visitedVendors = sessionStorage.getItem('visitedVendors');
    const localAuthToken = localStorage.getItem('Bearer');
    const role = localStorage.getItem('role');
    const visitedItems = localStorage.getItem('visitedItems');
    const rememberMe = localStorage.getItem('rememberMe');
    const url = localStorage.getItem('Url');
    const ItemQuantity = localStorage.getItem('ItemQuantity');
    const SocketItemModal = localStorage.getItem('SocketItemModal');
    const userId = localStorage.getItem('userId');
    const ItemVendors = localStorage.getItem('ItemVendors')
    const cashBuildLink = localStorage.getItem('cashBuildLink');
    const hasSeenGuide = localStorage.getItem('hasSeenGuide');
    const hasSeenContractorGuide = localStorage.getItem('hasSeenContractorGuide');
    const Iam = localStorage.getItem('Iam');
    const autodeskToken = localStorage.getItem('autodeskToken');
    localStorage.clear();

    if (sessionAuthToken) {
        sessionStorage.setItem('Bearer', sessionAuthToken);
    } else if (localAuthToken) {
        localStorage.setItem('Bearer', localAuthToken);
    }

    if (role) {
        localStorage.setItem('role', role);
    }
    if (hasSeenGuide) {
        localStorage.setItem('hasSeenGuide', hasSeenGuide);
    }
    if (hasSeenContractorGuide) {
        localStorage.setItem('hasSeenContractorGuide', hasSeenContractorGuide);
    }
    if (rememberMe) {
        localStorage.setItem('rememberMe', rememberMe);
    }
    if (visitedItems) {
        localStorage.setItem('visitedItems', visitedItems);
    }
    if (visitedVendors) {
        localStorage.setItem('visitedVendors', visitedVendors);
    }
    if(url){
        localStorage.setItem('Url', url);
    }
    if(ItemQuantity){
        localStorage.setItem('ItemQuantity', ItemQuantity);
    }
    if(SocketItemModal){
        localStorage.setItem('SocketItemModal', SocketItemModal);
    }
    if(userId){
        localStorage.setItem('userId', userId);
    }
    if(ItemVendors){
        localStorage.setItem('ItemVendors',ItemVendors)
    }
    if(cashBuildLink){
        localStorage.setItem('cashBuildLink',cashBuildLink)
    }
    if(Iam){
        localStorage.setItem('Iam', Iam)
    }
    if(autodeskToken){
        localStorage.setItem('autodeskToken',autodeskToken);
    }
};

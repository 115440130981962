import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";

interface ReceiptPDFProps {
    id?: number;
}

export const ReceiptPDF: React.FC<ReceiptPDFProps> = ({ id }) => {
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('Bearer');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    return (
        <Link className='pdf-btn' to={`${process.env.REACT_APP_BASE_URL_ADMIN}/generate-pdf/project/${id}?token=${token}`}>
            <button className='add-template-btn receipt'>Download PDF</button>
        </Link>
    );
};

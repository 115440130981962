import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import './style.css';
import {fetchAllTemplates} from "../../Slice/getAllTemplates/GetAllTemplatesSlice";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import PaginationAssistance from "../PaginationAssistance/pagination";
import ModalAddTemplate from "../ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import {Template} from "../../types/TemplateType";
import {IconTemplateEyes, IconTemplatesAssistance} from "../../IconComponents/IconComponents";

const templatesPerPage = 8;

interface AsisTemplateProps {
    setIsLoading: (loading: boolean) => void,
    isLoading: boolean,
}

const AsisTemplate: React.FC<PropsFromRedux & AsisTemplateProps> =
    ({
         error, isFetching, templates, totalTemplate, fetchAllTemplates,
         setIsLoading, isLoading, isAuthenticated
     }) => {
        const navigate = useNavigate();
        const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
        const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
        const [selectedTempId, isSelectedTempId] = useState<number>(0);
        const [selectedTempTitle, isSelectedTempTitle] = useState<string>('');

        const infoTemplateToModal = (id: number, title: string) => {
            setIsModalOpen(true);
            isSelectedTempId(id);
            isSelectedTempTitle(title);
        };

        const paginateTemplate = (pageNumber: number) => setCurrentTemplatePage(pageNumber);

        useEffect(() => {
            setIsLoading(isFetching);
        }, [isFetching]);

        useEffect(() => {
            if (isAuthenticated) {


                if (templates.length === 0) {
                    fetchAllTemplates({
                        page: currentTemplatePage,
                        perPage: templatesPerPage,
                        queryParams: '&with[]=title'
                    });

                }
            } else {
                navigate('/home')
            }
        }, [currentTemplatePage, isAuthenticated]);


        useEffect(() => {
            if (isModalOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        }, [isModalOpen]);

        if (error) return <p>Error: {error}</p>;

        const groupedTemplates = groupByCategory(templates);

        return (
            <>
                {!isLoading && (
                    <div>
                        <div className='template-name-content-assistance'>
                            <p>Projects</p>
                            <IconTemplatesAssistance/>
                        </div>
                        <div className='template-main'>
                            {Object.keys(groupedTemplates).map(category => (
                                <div key={category}>
                                    {category !== 'Others' && <h2 className='template-title-text'>{category}</h2>}
                                    <div className='template-main-container'>
                                        {groupedTemplates[category].map(template => {
                                            const templateImage = template.galleries && template.galleries.length > 0 && template.galleries[0].medias.length > 0
                                                ? template.galleries[0].medias[template.galleries[0].medias.length - 1].url
                                                : '/img/house.jpg';

                                            return (
                                                <div className='template-project-list' key={template.id}>
                                                    <div onClick={() => navigate(`/template/${template.id}`)}
                                                         className='template-project-get'>
                                                        <div className='template-img-shadow' style={{
                                                            backgroundImage: `url(${templateImage})`
                                                        }}>
                                                            <IconTemplateEyes/>
                                                        </div>
                                                        <div className='template-title-block'>
                                                            <p className='template-title'>{template.title}</p>
                                                        </div>
                                                        <div className='view-details'>View details <span
                                                            className='view-details-arrow'></span></div>
                                                    </div>
                                                    <div className='template-project-title'>
                                                        <button
                                                            onClick={() => infoTemplateToModal(template.id, template.title)}
                                                            className='template-project-btn'>Add to a Project
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>


                        {totalTemplate > templatesPerPage && (
                            <PaginationAssistance
                                projectsPerPage={templatesPerPage}
                                totalProjects={totalTemplate}
                                paginate={paginateTemplate}
                                currentPage={currentTemplatePage}
                            />
                        )}
                        <ModalAddTemplate
                            isOpen={isModalOpen}
                            tempId={selectedTempId}
                            tempTitle={selectedTempTitle}
                            onClose={() => setIsModalOpen(false)}
                        />
                    </div>
                )}
            </>
        );
    };

const mapStateToProps = (state: RootState) => ({
    templates: state.allTemplates.templates,
    totalTemplate: state.allTemplates.totalTemplate,
    isFetching: state.projectAssistance.isFetching,
    error: state.projectAssistance.error,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchAllTemplates,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AsisTemplate);

const groupByCategory = (templates: Template[]) => {
    const categories: { [key: string]: Template[] } = {
        Others: []
    };
    templates.forEach(template => {
        if (template.categories.length === 0) {
            categories.Others.push(template);
        } else {
            template.categories.forEach(category => {
                if (!categories[category.category]) {
                    categories[category.category] = [];
                }
                categories[category.category].push(template);
            });
        }
    });

    return categories;
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrderHistory } from '../../api/APIWraper';
import { OrderHistory } from "../../types/OrderHistory";

interface TemplateByIdState {
    orderHistory: OrderHistory[] | null;
    totalOrders: number;
    isFetching: boolean;
    error: string | null;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc' | null;
}

const initialState: TemplateByIdState = {
    orderHistory: null,
    totalOrders: 0,
    isFetching: false,
    error: null,
    sortBy: null,
    sortOrder: null,
};

export const fetchOrderHistory = createAsyncThunk<{ orders: OrderHistory[], totalOrders: number }, {page: number; perPage: number; sortBy?: string; sortOrder?: 'asc' | 'desc'  }>(
    'orderHistory/fetchOrderHistory',
    async ({  page, perPage,sortBy, sortOrder  }) => {
        return await getOrderHistory(page, perPage,sortBy, sortOrder);
    }
);
const OrderHistorySlice = createSlice({
    name: 'orderHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderHistory.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchOrderHistory.fulfilled, (state, action: PayloadAction<{ orders: OrderHistory[], totalOrders: number }>) => {
                state.isFetching = false;
                state.orderHistory = action.payload.orders;
                state.totalOrders = action.payload.totalOrders;
            })
            .addCase(fetchOrderHistory.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default OrderHistorySlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FragmentVisibilityState {
    visibleFragments: { [key: string]: boolean };
    isLaborVisible: boolean;
    isToolVisible: boolean;
    isPageActive: boolean;
}

const initialState: FragmentVisibilityState = {
    visibleFragments: {},
    isLaborVisible: true,
    isToolVisible: true,
    isPageActive: false,
};

const fragmentSlice = createSlice({
    name: 'fragments',
    initialState,
    reducers: {
        toggleFragmentVisibility: (state, action: PayloadAction<{ fragment_id: number; fragmentIndex: number }>) => {
            const { fragment_id, fragmentIndex } = action.payload;
            const fragmentKey = `${fragment_id}-${fragmentIndex}`;
            state.visibleFragments[fragmentKey] = !state.visibleFragments[fragmentKey];
        },
        toggleLaborVisibility: (state) => {
            state.isLaborVisible = !state.isLaborVisible;
        },
        toggleToolVisibility: (state) => {
            state.isToolVisible = !state.isToolVisible;
        },
        setPageActiveCalculation: (state, action: PayloadAction<boolean>) => {
            state.isPageActive = action.payload;
        }
    },
});

export const { toggleFragmentVisibility,toggleLaborVisibility,toggleToolVisibility, setPageActiveCalculation } = fragmentSlice.actions;

export default fragmentSlice.reducer;

import { Template } from "../types/TemplateType";

interface Answer {
    title: string;
    value: number;
    selected: boolean;
}

interface Question {
    [key: string]: any;
    answers: Answer[];
}

interface Fragment {
    fragment_id: number;
    qty: any;
    questions: Question[];
}

interface TransformedTemplate {
    id: number;
    questions_from_template: Question[];
    questions_from_fragments: Fragment[];
    counted?: boolean;
}

export const transformTemplate = (template: Template, projectId?: number): TransformedTemplate => {
    const processAnswers = (question: any, ignoreOriginalAnswers: boolean): Answer[] => {
        if (ignoreOriginalAnswers) {
            return [{
                title: question.value ? question.value.toString() : "N/A",
                value: parseFloat(question.value) || 0,
                selected: true
            }];
        } else {
            if (question.answers && Array.isArray(question.answers)) {
                return question.answers.map((answer: any) => ({
                    title: answer.title,
                    value: parseFloat(answer.value) || 0,
                    selected: parseFloat(question.value) === parseFloat(answer.value)
                }));
            } else {
                return [{
                    title: question.value ? question.value.toString() : "N/A",
                    value: parseFloat(question.value) || 0,
                    selected: true
                }];
            }
        }
    };

    const processQuestion = (questionData: any, ignoreOriginalAnswers: boolean): Question => {
        const answers = processAnswers(questionData, ignoreOriginalAnswers);
        return {
            [questionData.key]: questionData.question,
            answers: answers
        };
    };

    const questions_from_template = Object.keys(template.data || {})
        .filter(key => !isNaN(Number(key)) && key !== "fragments" && template.data[key]?.key)
        .map(key => processQuestion(template.data[key], true));

    const questions_from_fragments = (template.data.fragments && Array.isArray(template.data.fragments)) ? template.data.fragments.map(fragment => ({
        fragment_id: fragment.id,
        qty: fragment.qty,
        questions: Object.keys(fragment.data || {})
            .filter(key => key !== "item_types" && fragment.data[key]?.key)
            .map(key => processQuestion(fragment.data[key], false))
    })) : [];

    return {
        id: template.id,
        questions_from_template,
        questions_from_fragments,
        ...(template.counted ? { counted: true } : {})
    };
};

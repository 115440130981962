import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './Step3Bom.css';
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import 'react-toastify/dist/ReactToastify.css';
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import { BOMItem, BOMItemType } from "../../types/ProjectBOMInterface";
import { IconEyesStep3 } from "../../IconComponents/IconComponents";
import {postBomEditItems} from "../../api/APIWraper";
import {toast} from "react-toastify";

const Step3Bom = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [selectedItemTypes, setSelectedItemTypes] = useState<BOMItemType[]>([]);
    const location = useLocation();
    const link = location.state?.link;
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [visited, setVisited] = useState<{ [key: number]: boolean }>({});
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const total = selectedItemTypes.reduce((acc, itemType, index) => {
            const key = `${itemType.id}-${index}`;
            const qty = quantities[key] || 0;
            return acc + (Number(itemType.item.price) * qty);
        }, 0);
        setTotalPrice(total);
    }, [quantities, selectedItemTypes]);

    useEffect(() => {
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItemsBom') || '{}');
        setVisited(storedVisitedItems);
    }, []);

    useEffect(() => {
        const storedSelectedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        setSelectedItemTypes(storedSelectedItems)
    }, []);

    const navigateToHome = () => {
        clearLocalStorageExceptToken();
        navigate('/home');
    };

    if (!selectedItemTypes) {
        return null;
    }

    const renderVendorBlock = (item: BOMItem, visited: boolean, index: number) => {
        if (!item.vendor) {
            return <p className='step3-bom-item-no-vendor'>No Vendor</p>;
        }
        if (!visited) {
            return (
                <div
                    onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                    className='step3-bom-items-container-vendor'>
                    <p className='step3-bom-items-container-vendor-purchase'>
                        <span className='step3-vendor-eyes'>
                            <IconEyesStep3 />
                        </span>
                        <span className='step3-vendor-p'>
                            Purchase from
                        </span>
                    </p>
                    <p className='step3-vendor-img'>
                        {item.vendor}
                    </p>
                </div>
            );
        } else {
            return (
                <div className='step3-bom-items-container-vendor-visited'>
                    <p
                        className='step3-vendor-img'
                        onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                    >
                        {item.vendor}
                    </p>
                    <span
                        onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                        className='step3-vendor-eyes'
                    >
                        <IconEyesStep3 />
                    </span>
                    <span className='step3-vendor-p-visited'>Visited</span>
                </div>
            );
        }
    };

    const handleLogoClick = (link: string, itemId: number, index: number) => {
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItemsBom') || '{}');
        if (!storedVisitedItems[itemId]) {
            storedVisitedItems[itemId] = true;
            localStorage.setItem('visitedItemsBom', JSON.stringify(storedVisitedItems));
            setVisited(prev => ({
                ...prev,
                [itemId]: true
            }));
        }
        if (link) {
            window.open(link, '_blank', 'noopener');
        }
    };

    const handleQtyChange = (key: string, value: number) => {
        setQuantities(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleSave = async () => {
        const logData = selectedItemTypes.map((itemType, index) => {
            const uniqueKey = `${itemType.id}-${index}`;
            const ordered_qty = quantities[uniqueKey] || 0;
            return {
                id: itemType.id,
                item: itemType.item.id,
                index: index,
                qty_ordered: ordered_qty
            };
        });

        const body = {
            item_types: logData
        };

        const { status } = await postBomEditItems(body, link);
        if(status === 200) {
            toast.success('The project has been successfully updated')
            setTimeout(() => {
                navigate(`/create/step2Bom/${link}`)
            },100)
            localStorage.removeItem('selectedItemTypes');
        } else {
            toast.error('Failed to update the project')
        }

    };


    return (
        <div className='step2'>
            <div>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            { to: '/', label: 'Cashbuild' },
                            { to: '', label: '>' },
                            { to: '/', label: 'Home' },
                            { to: '', label: '>' },
                            { to: '/home', label: 'Project assistance' },
                            { to: '', label: '>' },
                            { to: '', label: 'Create new project space' }
                        ]}
                    />
                ) : (
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToHome} className='template-link-assistance'>Project group
                                assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                )}
                <div className='step-info'>
                    <div className='step-info-block'>
                        <h3>Step 3</h3>
                        <span>/</span>
                        <p className='step-info-block-name'>Order view</p>
                    </div>
                </div>
                <section className='step2-bom-container'>
                    <div className='step2-bom-cost-summary'>
                        <p className='cost-summary-bom'>Cost summary</p>
                    </div>

                    <div className='step2-content-block'>
                        <div className='step2-bom-bom-project-title'>
                            <p className='step2-bom-title'>Wall material & options</p>
                        </div>

                        <div className='step3-bom-header-info'>
                            <p className='step3-bom-item-title-info'>Material</p>
                            <p className='step3-bom-qty-order-info'>QTY To order</p>
                            <p className='step3-bom-qty-ordered-info'>QTY ordered</p>
                            <p className='step3-bom-price-info'>Unit price</p>
                            <p className='step3-bom-total-value-info'>Total Value</p>
                            <p className='step3-bom-total-current-order-info'>Current Order</p>
                            <p className='step3-bom-total-orderd-vendor-info'>Vendor</p>
                        </div>

                        <div>
                            {selectedItemTypes.map((itemType, index) => {
                                const uniqueKey = `${itemType.id}-${index}`;
                                const item = itemType.item;
                                const isVisited = visited[item.id] || false;
                                const qtyOrdered = item.qty_ordered ? item.qty_ordered :  (quantities[uniqueKey] || 0);
                                return (
                                    <div key={uniqueKey} className='step3-bom-items'>
                                        <p className='step3-bom-item-title'>{item.title}</p>
                                        <div className='step3-bom-item-input'>
                                            <input
                                                type="number"
                                                className="step2-other-width-input"
                                                value={qtyOrdered}
                                                onChange={(e) => handleQtyChange(uniqueKey, Number(e.target.value))}
                                                min={0}
                                            />
                                        </div>
                                        <p className='step3-bom-qty-ordered'>{itemType.qty}</p>
                                        <p className='step3-bom-price'>R {parseFloat(item.price).toFixed(2)}</p>
                                        <p className='step3-bom-total'>R {(parseFloat(item.price) * itemType.qty).toFixed(2)}</p>
                                        <p className='step3-bom-total-ordered'>R {(parseFloat(item.price) * qtyOrdered).toFixed(2)}</p>
                                        {renderVendorBlock(item, isVisited, index)}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>

                <div className='step2-bom-total-price-section'>
                    <div className='step2-bom-total-price-block'>
                        <p className='step2-bom-total-price-total'>Total:</p>
                        <p className='step2-bom-total-price-price'>R {totalPrice.toFixed(2)}</p>
                    </div>
                </div>

            </div>

            <div className='step2-page-bottom-btn'>
                <div className='step2-page-bottom-btn-block'>
                    <div className='step2-page-btn'>
                        <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                    </div>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleSave}
                        >
                            Proceed to Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step3Bom;

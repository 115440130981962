import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './Step2Bom.css';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {getProjectBom, patchBomReplaceItems, postBomEditItems} from "../../api/APIWraper";
import 'react-toastify/dist/ReactToastify.css';
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {BOMItem, BOMItemType, BOMProject} from "../../types/ProjectBOMInterface";
import {toast} from "react-toastify";
import {notifyWarningOrderTemplate} from "../../components/Toast/AssisCreateToastNotification";
import SelectItemModal from "./ModalItemsBom";
import FragmentsComponent from "./Components/FragmentComponent";
import ItemTypesComponent from "./Components/ItemTypesComponent";
import {Fragment, ItemType, TItem} from "../../types/TemplateType";
import StepTotalsBlockBom from "../../components/TotalsBlockBOM/StepTotalsBlockBom";


const Step2Bom = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [projectBom, setProjectBom] = useState<BOMProject | null>(null);
    const [replace, setReplace] = useState<boolean>(false);
    const [replaceFragment, setReplaceFragment] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<{ [key: string]: any }>({});
    const [selectedItemsStep3, setSelectedItemsStep3] = useState<{ [key: string]: BOMItemType }>({});
    const [buttonSelectDisabled, setButtonSelectDisabled] = useState<boolean>(false);
    const {link} = useParams<{ link: string }>();

    const [productTypeTotal, setProductTypeTotal] = useState(0);
    const [fragmentTotal, setFragmentTotal] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentUniqueKey, setCurrentUniqueKey] = useState<string | null>(null);
    const [allowedItemsForModal, setAllowedItemsForModal] = useState<any[]>([]);
    const [selectedFragmentsStep3, setSelectedFragmentsStep3] = useState<Fragment[]>([]);


    const handleSelectedFragmentsChange = useCallback((fragments: Fragment[]) => {
        setSelectedFragmentsStep3(fragments);
    }, [setSelectedFragmentsStep3]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const calculateProductTypeTotal = () => {
            const selectedItemTypes = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');

            if (selectedItemTypes.length === 0) {
                return 0;
            }

            return selectedItemTypes.reduce((total: number, itemType: { qty: number; item: { price: string; }; }) => {
                const qty = itemType.qty || 0;
                const price = parseFloat(itemType.item.price) || 0;
                return total + qty * price;
            }, 0);
        };

        const calculateFragmentTotal = () => {
            const selectedFragments = JSON.parse(localStorage.getItem('selectedFragmentBom') || '[]');
            if (selectedFragments.length === 0) {
                return 0;
            }

            return selectedFragments.reduce((total: any, fragment: { data: { item_types: ItemType[]; }; }) => {
                const itemTypes = fragment.data?.item_types || [];

                return total + itemTypes.reduce((typeTotal, itemType) => {
                    const items = itemType.items || [];

                    return typeTotal + items.reduce((itemTotal, item) => {
                        const qty = item.qty || 0;
                        const price = parseFloat(String(item.price)) || 0;
                        return itemTotal + qty * price;
                    }, 0);
                }, 0);
            }, 0);
        };

        const productTotal = calculateProductTypeTotal();
        const fragmentTotal = calculateFragmentTotal();

        setProductTypeTotal(productTotal);
        setFragmentTotal(fragmentTotal);
    }, [selectedItemsStep3, handleSelectedFragmentsChange, selectedFragmentsStep3]);


    useEffect(() => {
        const storedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        const storedItemsMap = storedItems.reduce((acc: { [key: string]: BOMItemType }, item: BOMItemType) => {
            const uniqueKey = `${item.id}-${item.index}`;
            acc[uniqueKey] = item;
            return acc;
        }, {});
        setSelectedItemsStep3(storedItemsMap);
    }, []);

    useEffect(() => {
        if (link) {
            const fetchProject = async () => {
                try {
                    const response: BOMProject = await getProjectBom(link);

                    const itemTypesWithUniqueKey = response.data.item_types
                        ? response.data.item_types.map((itemType, index) => ({
                            ...itemType,
                            uniqueKey: `${itemType.id}-${index}`
                        }))
                        : [];

                    const fragments = response.data.fragments || [];

                    setProjectBom({
                        ...response,
                        data: {
                            ...response.data,
                            item_types: itemTypesWithUniqueKey,
                            fragments: fragments,
                        }
                    });
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, [link]);


    useEffect(() => {
        if (!replace) {
            setSelectedItems({});
        }
    }, [replace]);
    useEffect(() => {
        const storedSelectedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        if (storedSelectedItems.length !== 0 || selectedFragmentsStep3.length !== 0) {
            setButtonSelectDisabled(true);
        } else {
            setButtonSelectDisabled(false);
        }
    }, [selectedItemsStep3, projectBom, selectedFragmentsStep3]);


    const navigateToHome = () => {
        clearLocalStorageExceptToken();
        navigate('/auth/login');
    };

    const onToggle = (who: string) => {
        if (who === 'ItemType') {
            setReplace(prevState => !prevState);
            setIsModalOpen(false);
            setCurrentUniqueKey(null);
        } else if (who === 'Fragment') {
            setReplaceFragment(prevState => !prevState);
        }
    };

    const handleSelectItem = useCallback((selectedItem: any) => {
        if (!currentUniqueKey) return;
        setSelectedItems(prev => ({
            ...prev,
            [currentUniqueKey]: selectedItem
        }));
        setIsModalOpen(false);
        setCurrentUniqueKey(null);
    }, [currentUniqueKey]);

    const handleSave = async () => {
        if (!projectBom) return;

        const changedItems = projectBom.data.item_types
            .map((itemType, index) => ({itemType, index}))
            .filter(({itemType}) => selectedItems[itemType.uniqueKey])
            .map(({itemType, index}) => ({
                id: itemType.id,
                item: selectedItems[itemType.uniqueKey].id,
                index: index,
            }));

        if (changedItems.length === 0) {
            toast.info('There are no changes to save.');
            return;
        }

        const body = {
            item_types: changedItems
        };

        try {
            const response = await patchBomReplaceItems(body, link);
            if (response.status === 200) {
                const bomProject = response.data;
                const itemTypesWithUniqueKey: (BOMItemType & {
                    uniqueKey: string
                })[] = bomProject.data.item_types.map((itemType: { id: any; }, index: any) => ({
                    ...itemType,
                    uniqueKey: `${itemType.id}-${index}`
                }));
                setReplace(false)

                setProjectBom({
                    ...projectBom,
                    data: {
                        ...projectBom.data,
                        ...bomProject,
                        item_types: itemTypesWithUniqueKey
                    }
                });
                setSelectedItems({});
                toast.success('You have successfully replaced the products');
            } else {
                toast.error('Failed to replace products');
            }
        } catch (error) {
            console.error("Error when saving changes:", error);
            toast.error('An error occurred while saving changes');
        }
    };

    const handleToggleSelect = (uniqueKey: string, itemType: BOMItemType, index: number) => {
        setSelectedItemsStep3(prevSelectedItems => {
            let updatedItemsArray;
            let updatedSelectedItems;

            if (prevSelectedItems[uniqueKey]) {
                const {[uniqueKey]: _, ...rest} = prevSelectedItems;
                updatedSelectedItems = rest;
                updatedItemsArray = Object.values(rest);
            } else {
                updatedSelectedItems = {...prevSelectedItems, [uniqueKey]: {...itemType, index}};
                updatedItemsArray = [...Object.values(prevSelectedItems), {...itemType, index}];
            }

            localStorage.setItem('selectedItemTypes', JSON.stringify(updatedItemsArray));
            return updatedSelectedItems;
        });
    };

    if (!projectBom) {
        return null;
    }

    const handleOrderSelected = () => {
        const storedSelectedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        const storeSelectedItemsFragment = JSON.parse(localStorage.getItem('selectedFragmentBom') || '[]')
        if (storedSelectedItems.length !== 0 || storeSelectedItemsFragment.length !== 0) {
            navigate(`/create/step3Bom/${link}`, {state: {link: link}});
        } else {
            notifyWarningOrderTemplate('You must choose at least one product');
            return;
        }
    };

    const handleOrderProject = () => {
        setSelectedItemsStep3(prevSelectedItems => {
            const storedItems: BOMItemType[] = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
            const storedItemsMap = storedItems.reduce((acc: { [key: string]: BOMItemType }, item) => {
                const uniqueKey = `${item.id}-${item.index}`;
                acc[uniqueKey] = item;
                return acc;
            }, {});

            const newItems = projectBom.data.item_types.filter((itemType, index) => {
                const uniqueKey = `${itemType.id}-${index}`;
                return !storedItemsMap[uniqueKey];
            });

            const updatedItemsArray = [
                ...storedItems,
                ...newItems.map((itemType, index) => ({...itemType, index}))
            ];

            localStorage.setItem('selectedItemTypes', JSON.stringify(updatedItemsArray));

            const storedFragments: Fragment[] = JSON.parse(localStorage.getItem('selectedFragmentBom') || '[]');
            const storedFragmentsMap = storedFragments.reduce((acc: { [key: number]: Fragment }, fragment) => {
                acc[fragment.id] = fragment;
                return acc;
            }, {});

            const newFragments = projectBom.data.fragments.filter(fragment => !storedFragmentsMap[fragment.id]);

            const updatedFragmentsArray = [
                ...storedFragments,
                ...newFragments
            ];
            handleSelectedFragmentsChange(newFragments)
            localStorage.setItem('selectedFragmentBom', JSON.stringify(updatedFragmentsArray));

            return {
                ...prevSelectedItems,
                ...newItems.reduce((acc, item, index) => {
                    const uniqueKey = `${item.id}-${index}`;
                    acc[uniqueKey] = {...item, index};
                    return acc;
                }, {} as { [key: string]: BOMItemType }),
            };
        });

        setTimeout(() => {
            navigate(`/create/step3Bom/${link}`, {state: {link: link}});
        }, 100);
    };


    const openModal = (uniqueKey: string, allowedItems: any[], item: BOMItem) => {
        setCurrentUniqueKey(uniqueKey);
        setAllowedItemsForModal(allowedItems);
        setSelectedItems(prev => {
            if (prev[uniqueKey]) {
                return prev;
            }
            return {...prev, [uniqueKey]: item};
        });
        setIsModalOpen(true);
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentUniqueKey(null);
        setAllowedItemsForModal([]);
    };


    return (
        <div className='step2'>
            <div>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/home', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToHome} className='template-link-assistance'>Project group
                                assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                )}
                <div className='step-info'>
                    <div className='step-info-block'>
                        <h3>Step 2</h3>
                        <span>/</span>
                        <p className='step-info-block-name'>Specify parameters of building</p>
                    </div>
                </div>
                <section className='step2-bom-container'>
                    <div className='step2-bom-cost-summary'>
                        <p className='cost-summary-bom'>Cost summary</p>
                    </div>
                    {projectBom.data.fragments.length > 0 && (
                        <>
                            <p className='step2-bom-name-container'>List of fragments</p>
                            <div>
                                <FragmentsComponent
                                    fragments={projectBom.data.fragments}
                                    onToggle={onToggle}
                                    replace={replaceFragment}
                                    selectedItemsStep3={selectedItemsStep3}
                                    selectedItems={selectedItems}
                                    setProjectBom={setProjectBom}
                                    onSelectedFragmentsChange={handleSelectedFragmentsChange}
                                />
                            </div>
                        </>
                    )}

                    {projectBom.data.item_types.length > 0 && (
                        <>
                            <p className='step2-bom-name-container'>List of products</p>
                            <div className='step2-content-block'>
                                <ItemTypesComponent
                                    handleSave={handleSave}
                                    itemTypes={projectBom.data.item_types}
                                    replace={replace}
                                    onToggle={onToggle}
                                    selectedItems={selectedItems}
                                    selectedItemsStep3={selectedItemsStep3}
                                    handleToggleSelect={handleToggleSelect}
                                    openModal={openModal}
                                />
                            </div>
                        </>
                    )}

                </section>

                <StepTotalsBlockBom
                    project={projectBom}
                    productTypeTotal={productTypeTotal}
                    fragmentTotal={fragmentTotal}
                    isSmallScreen={isSmallScreen}
                    isVisible={true}
                />

            </div>

            <div className='step2-page-bottom-btn'>
                <div className='step2-page-bottom-btn-block'>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleOrderSelected}
                            style={{
                                backgroundColor: !buttonSelectDisabled ? '#cccccc' : '',
                                cursor: !buttonSelectDisabled ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Order selected
                        </button>
                    </div>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleOrderProject}
                        >
                            Order Project
                        </button>

                    </div>
                    <div className='step2-page-btn'>
                        <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                    </div>
                </div>
            </div>

            <SelectItemModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                items={allowedItemsForModal}
                onSelect={handleSelectItem}
                selectedItem={currentUniqueKey ? selectedItems[currentUniqueKey] : null}
            />
        </div>
    );
}

export default Step2Bom;

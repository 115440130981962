import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { UserRoleEnum } from "../Enums/UserRoleEnum";
import { toast } from "react-toastify";

interface UserRoleProps {
    redirectPath?: string;
    allowedRoles: UserRoleEnum[];
    children: React.ReactNode;
}

const UserRoleRouteMiddleware: React.FC<UserRoleProps> = ({ redirectPath = '/home', allowedRoles, children }) => {
    const [isAuthorized, setIsAuthorized] = useState(true);
    const [showNavigate, setShowNavigate] = useState(false);

    useEffect(() => {
        const checkRoleAndToken = () => {
            const token = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
            const role = localStorage.getItem('role') as UserRoleEnum;

            if (!token) return;

            if (!allowedRoles.includes(role)) {
                setIsAuthorized(false);
                toast.warning('Access to this page is only available with a paid subscription.');
                setTimeout(() => {
                    setShowNavigate(true);
                }, 500);
            }
        };

        checkRoleAndToken();

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'role' || event.key === 'Bearer') {
                checkRoleAndToken();
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [allowedRoles]);

    if (!isAuthorized && showNavigate) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default UserRoleRouteMiddleware;

import React from "react";
import './style.css';
import { Project } from "../../types/ProjectTypes";

interface ProgressBarProps {
    project: Project;
    showPercentagePosition?: 'above' | 'below' | 'side';
    customClass?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ project, showPercentagePosition = 'below', customClass = '' }) => {


    const percentage = project.progress;

    return (
        <div className={`progress-bar ${customClass}`}>
            {showPercentagePosition === 'above' && (
                <p className='progress-bar-percentage above'>{percentage}/100%</p>
            )}
            <div className='progress-bar-container'>
                <div className='progressBarFill' style={{ width: `${percentage}%` }}></div>
            </div>
            {showPercentagePosition === 'below' && (
                <p className='progress-bar-percentage-below below'>{percentage}/100%</p>
            )}
            {showPercentagePosition === 'side' && (
                <p className='progress-bar-percentage side'>{percentage}/100%</p>
            )}
        </div>
    );
}

export default ProgressBar;

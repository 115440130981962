import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './DataProtection.css';

interface ModalDeleteProps {
    isOpen: boolean;
    onCancel: () => void;
}

Modal.setAppElement('#root');

const parseTextWithLinks = (text: string): React.ReactNode[] => {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
        if (part.match(urlRegex)) {
            const href = part.startsWith('http') ? part : `http://${part}`;
            return (
                <a
                    key={index}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="terms-link"
                >
                    {part}
                </a>
            );
        } else {
            return <React.Fragment key={index}>{part}</React.Fragment>;
        }
    }).filter(Boolean);
};

const DataProtection: React.FC<ModalDeleteProps> = ({ isOpen, onCancel }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    const termsText = `
By viewing this website you hereby acknowledge that you have read and accept the following
Protection of Personal Information (POPI) disclaimer.
GoBuild360 (Pty) Ltd shall take all reasonable measures to protect the personal information of users
and for the purpose of this disclaimer “personal information” shall be defined as detailed in the:
1. Promotion of Access to Information Act, Act 2 of 2000 (“PAIA”) and the Protection of
Personal Information Act, Act 4 of 2013 (“POPI”). The PAIA and POPI Acts are available
online at www.gov.za/documents/acts.
2. California Privacy Rights Act is available online at https://www.caprivacy.org/cpra-
resource-center/
3. General Data Protection Regulation is available online at https://gdpr-info.eu

Personal information refers to information that identifies or relates specifically to you as a person or
data subject, for example, your name, age, gender, identity number and your email address. A
definition of personal information can be found in the GoBuild360 (Pty) Ltd Privacy policy.
The Collection of Personal Information
GoBuild360 (Pty) Ltd collects Personal information primarily for the following purposes:
Customer Information
o To render customer related services and administration of customer accounts;
o To conduct criminal, credit, reference and other related reference checks
o To authenticate the customer;
o To provide the customer with information which GoBuild360 (Pty) Ltd believes may be of
interest to the customer, such as information relating to public awareness campaigns and
matters of general public interest in which GoBuild360 (Pty) Ltd is involved or has
decided to lend its support to.

Supplier and Third Party Contractor/Service Provider Information
o To secure the products and services of the supplier/service provider or contractor as part
of GoBuild360 (Pty) Ltd’s product and service offering;
o To manage the GoBuild360 (Pty) Ltd supply chain and relationship with the supplier
and/or contractor for any purposes required by law by virtue of the relationship between
the supplier и GoBuild360 (Pty) Ltd
o To render services relating to the administration of supplier supplier/service provider or
contractor accounts;
o To provide the supplier/service provider or contractor with information which GoBuild360
(Pty) Ltd believes may be of interest, such as information relating to public awareness
campaigns and matters of general public interest in which GoBuild360 (Pty) Ltd is
involved or has decided to lend its support to.

Sources of Personal Information
Personal information may be collected from the following sources:
- Directly from the person when he/she submit forms requests or transactions, use our websites or
make use of any of the GoBuild360 (Pty) Ltd services;

The Storage of Personal Information
All personal information collected by GoBuild360 (Pty) Ltd will be stored as follows:
- In a secure and safe manner according to strict information security principles with safeguards to
ensure its privacy and confidentiality;
- For no longer than is necessary to achieve the purpose for which it was collected unless further
retention is
o Otherwise reasonably required by GoBuild360 (Pty) Ltd for lawful purposes related to its
functions and activities;
o Retained further with the person’s consent
After which the information will be de-identified and disposed of as per the GoBuild360 (Pty) Ltd
Control of Records policy.
Sharing of Personal Information
Any information supplied to GoBuild360 (Pty) Ltd will be treated as confidential and GoBuild360 (Pty)
Ltd will not disclose information unless legally permitted thereto. No information will be transferred to
a third party, third country or international organizations without the explicit consent of the data subject
unless legally obliged thereto. By providing the personal information, the data subject agrees that
GoBuild360 (Pty) Ltd may transfer the information to the following people and organisations in pursuit
of the data processing purposes set out in our Privacy Policy:
1. To the divisions and entities in the GoBuild360 (Pty) Ltd Group, including directors,
employees, contractors, agents, auditors, legal and other professional advisors who are
authorised to process this information;
2. To persons employed by GoBuild360 (Pty) Ltd to provide services on our behalf and that
adhere to principles similar to GoBuild360 (Pty) Ltd regarding the treatment of personal
information;
3. To any person or persons who may be permitted by applicable law or that you may consent
to.
Your Rights regarding your Personal Information
A data subject (employee, contractor, supplier and/or customer) has the following rights to his/her
personal information collected, processed and stored by GoBuild360 (Pty) Ltd:
- Right of access to and the right to rectify or update the personal information collected;
- The right to object at any time to the processing of the personal information in which
event the consequences of the objection will be explained to the data subject.
- The right to request GoBuild360 (Pty) Ltd to no longer process the personal information of
the data subject should the information not be required for further processing or by law.
- The right to request GoBuild360 (Pty) Ltd to delete all your personal information.
General Conditions pertaining to Personal Information.
GoBuild360 (Pty) Ltd accepts no liability whatsoever for any loss, damage (whether direct, indirect,
special or consequential) and/or expenses of any nature whatsoever which may arise as a result of,
or which may be attributable directly or indirectly from information made available on these pages or
links, or actions or transaction resulting there from.
`;

    return (
        <Modal
            isOpen={isOpen}
            className="terms-and-conditions"
            onRequestClose={onCancel}
            overlayClassName="overlay-terms-and-conditions"
        >
            <div className="warning">
                <button className="close-button" onClick={onCancel}>&times;</button>
            </div>
            <div className="terms-and-conditions-content">
                <h1 className='terms-and-conditions-h1'>Disclaimer – Protection of Personal Information</h1>
                {parseTextWithLinks(termsText).map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        <br />
                    </React.Fragment>
                ))}
            </div>
        </Modal>
    );
};

export default DataProtection;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template } from "../../types/TemplateType";

interface LocalTemplateState {
    templates: Template[];
    calculatedTemplates: number[];
}

const initialState: LocalTemplateState = {
    templates: [],
    calculatedTemplates: []
};

const localTemplateSlice = createSlice({
    name: 'localTemplate',
    initialState,
    reducers: {
        addTemplate(state, action: PayloadAction<Template>) {
            state.templates.push(action.payload);
        },
        removeTemplate(state, action: PayloadAction<number>) {
            state.templates = state.templates.filter(template => template.id !== action.payload);
        },
        updateLocalTemplate(state, action: PayloadAction<Template>) {
            const index = state.templates.findIndex(template => template.id === action.payload.id);
            if (index !== -1) {
                state.templates[index] = action.payload;
            } else {
                state.templates.push(action.payload);
            }
        },
        setCalculatedTemplate(state, action: PayloadAction<number>) {
            state.calculatedTemplates.push(action.payload);
        },
        removeCalculatedTemplate(state, action: PayloadAction<number>) {
            state.calculatedTemplates = state.calculatedTemplates.filter(id => id !== action.payload);
        },
        clearTemplates(state) {
            state.templates = [];
            state.calculatedTemplates = [];
        },
    }
});

export const { addTemplate, removeTemplate, updateLocalTemplate, setCalculatedTemplate, removeCalculatedTemplate, clearTemplates } = localTemplateSlice.actions;

export default localTemplateSlice.reducer;

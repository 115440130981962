export const setVarGlobalColors = () => {
    const root = document.documentElement;
    root.style.setProperty('--basic-color', '#0FBC74');
    root.style.setProperty('--basic-hover-color', '#12e389');
    root.style.setProperty('--basic-btn-color', '#0FBC74');
    root.style.setProperty('--background-checkbox-color', '#D7FEEE');


    root.style.setProperty('--disabled-btn-color', '#8DA1B0');
    root.style.setProperty('--basic-btn-dark-color', '#0E7B51');
    root.style.setProperty('--basic-btn-dark-hover-color', '#129a64');

    root.style.setProperty('--basic-table-background-color', '#FFFFFF');
    root.style.setProperty('--basic-table-shadow-color', '#F2F2F2');
    root.style.setProperty('--basic-footer-background-color', '#FDFDFD');
    root.style.setProperty('--background-header-btn-color', '#D9D9D9');
    root.style.setProperty('--header-list-line-color', '#E0E0E0');
    root.style.setProperty('--landing-list-line-color', '#E6E6E6');
    root.style.setProperty('--header-list-text-color', '#8DA1B0');
    root.style.setProperty('--basic-text-title-color', '#7F7F7F');
    root.style.setProperty('--basic-text-description-color', '#5F6368');

    root.style.setProperty('--basic-text-landing-color', '#979797');
    root.style.setProperty('--basic-background-landing-color', '#E6E6E6');
    root.style.setProperty('--basic-background-pricing-tablet-landing-color', '#F6F6F6');
};

import React from 'react';
import {
    IconAccuracyLanding, IconCustomizationLanding, IconSettingLanding,
    IconUserLanding
} from "../../IconComponents/IconComponents";

const InfoBlockBlog = () => {
    return (
        <section className='middle-content2'>
            <div>
                <div className='middle-block1-info1'>
                    <div className='middle-block1-info1-content'>
                        <IconSettingLanding/>
                        <p className='middle-block1-info1-name'>Seamless Integration</p>
                        <p className='middle-block1-info1-text'>Works effortlessly with 3D Revit architectural software for a streamlined workflow.</p>
                    </div>
                </div>
                <div className='middle-block1-section'>
                    <div className='middle-block1-info2'>
                        <div className='middle-block1-info2-content'>
                            <IconCustomizationLanding/>
                            <p className='middle-block1-info1-name'>Customization</p>
                            <p className='middle-block1-info1-text'>Enables tailored project planning to suit specific needs. </p>
                        </div>
                    </div>
                    <div className='middle-block1-info2'>
                        <div className='middle-block1-info1-content'>
                            <IconAccuracyLanding/>
                            <p className='middle-block1-info1-name'>Efficiency & Accuracy</p>
                            <p className='middle-block1-info1-text'>Reduces manual errors while enhancing precision.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='middle-block2-info1-last'>
                <div className='middle-block2-info1'>
                    <div className='middle-block2-info1-content'>
                        <IconUserLanding/>
                        <p className='middle-block1-info1-name'>User Empowerment</p>
                        <p className='middle-block1-info1-text'>Supports both professionals and DIY in cost estimation. </p>
                    </div>
                    <div className='middle-section-image-block'>
                        <img className='middle-section-image-screen1' src='/img_landing/screenBig.png' alt="Image pop up"/>
                        <div className='middle-section-image-block-twice'>
                            <img className='middle-section-image-screen2' src='/img_landing/screenSmall1.png' alt='Image select product'/>
                            <img className='middle-section-image-screen3' src='/img_landing/screenSmall2.png' alt='Image select project'/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InfoBlockBlog;
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { User } from "../../types/ProjectTypes";
import './style.css';
import ModalDelete from "../Modals/ModalDelete/ModalDelete";
import EditPermissionsModal from "../Modals/ModalEditUser/EditModalUser";
import { fetchProjectById, updateProjectPermissions } from '../../Slice/updateProject/updateProjectSlice';
import { RootState } from '../../store';
import GetPermission from "../RoleComponent/Role";
import { PermissionEdit } from "../RoleComponent/EnumRole";
import {IconRemove, IconSettings, IconUserDefaultProfile} from "../../IconComponents/IconComponents";

interface  IUserList{
    isSmallScreen:boolean
}

const UserList: React.FC<IUserList & PropsFromRedux> = ({ isSmallScreen,project, updateProjectPermissions, fetchProjectById }) => {
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditPermissionsModalOpen, setIsEditPermissionsModalOpen] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [currentRole, setCurrentRole] = useState<string>('User');

    if (!project) {
        return null;
    }

    const permissionsUsers = project.permissions.map(permission => permission.user);

    const uniqueUsers = Array.from(new Set(permissionsUsers.map(user => user?.id)))
        .map(id => permissionsUsers.find(user => user?.id === id) as User)
        .filter(user => user !== undefined);

    const handleDeleteClick = (user: User) => {
        setUserToDelete(user);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (userToDelete) {
            await updateProjectPermissions({ projectId: String(project.id), email: userToDelete.email, permissions: [] });
            setIsDeleteModalOpen(false);
            setUserToDelete(null);
            fetchProjectById(String(project.id));
        }
    };

    const handleEditPermissionsClick = (user: User, permissions?: []) => {
        setCurrentUser(user);
        const parsedPermissions = permissions?.filter((permission: any) => permission !== null) as PermissionEdit[];
        const userRole = parsedPermissions.includes(PermissionEdit.OWNER) ? 'Admin' : 'User';
        setCurrentRole(userRole);
        setIsEditPermissionsModalOpen(true);
    };

    const handleSavePermissions = async (email: string, role: string) => {
        if (project && currentUser) {
            const permissions = role === 'User'
                ? [PermissionEdit.READ]
                : [PermissionEdit.READ, PermissionEdit.UPDATE, PermissionEdit.DELETE, PermissionEdit.OWNER];

            await updateProjectPermissions({
                projectId: String(project.id),
                email: email,
                permissions: permissions.map(permission => PermissionEdit[permission])
            });
            fetchProjectById(String(project.id));
        }
        setIsEditPermissionsModalOpen(false);
    };
    return (
        <div className='wrapper'>
            <ul className="table-content-data-section-container-users">
                {uniqueUsers.map(user => {

                    const userPermissions = project.permissions.find(permission => permission.user_id === user.id);
                    const parsedPermissions = userPermissions ? userPermissions.level.filter((permission: any) => permission !== null) as PermissionEdit[] : [];
                    return (
                        <li key={user.id}>
                            {!isSmallScreen ? (
                            <section className="table-content-data-section-users">
                                    <>
                                        <div className="table-content-data-section-field-image-users">
                                            <div className='table-content-data-section-field-image-block'>
                                                {user.galleries && user.galleries.length > 0 ? (
                                                    <img
                                                        className="gallery-image-users icon-circle "
                                                        src={user.galleries[0].medias[user.galleries[0].medias.length - 1].url}
                                                        alt="User"
                                                    />
                                                ) : (
                                                    <div className='gallery-image-users icon-circle'>
                                                        <IconUserDefaultProfile/>
                                                    </div>
                                                )}
                                                <p className="table-content-data-section-field-image-title-users">
                                                    {user.username}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="project-table-content-data-section-field-center-users-email">
                                            {user.email}
                                        </p>
                                        <p className='project-table-content-data-section-field-center-users-address'>
                                            {project?.address?.full_address || 'No address'}
                                        </p>
                                        <p className="project-table-content-data-section-field-center-users-count">
                                            {user.projects_count}
                                        </p>
                                        <p className='project-table-content-data-section-field-center-users-permission'>{userPermissions ? GetPermission(parsedPermissions) : 'No permissions'}</p>
                                        <div className="table-content-data-section-field-actions-2btn-users">
                                            <button
                                                onClick={() => handleEditPermissionsClick(user, userPermissions?.level)}
                                                className='costs-action-btn'>
                                                <div title='Permission settings' className='setting-users'><IconSettings/></div>
                                            </button>
                                            <button onClick={() => handleDeleteClick(user)}
                                                    className='costs-btn-costs'>
                                                <div title='Remove user' className='delete-users'><IconRemove/></div>
                                            </button>
                                        </div>
                                    </>
                            </section>

                            ): (
                                <section className="table-content-data-section-team small-screen">
                                    <>
                                        <div className="user-info-block">
                                            <div className="user-image-block">
                                                {user.galleries && user.galleries.length > 0 ? (
                                                    <img
                                                        className="width-table-img-team icon-circle"
                                                        src={user.galleries[user.galleries.length - 1].medias[user.galleries[user.galleries.length - 1].medias.length - 1].url}
                                                        alt="User"
                                                    />
                                                ) : (
                                                    <div className='width-table-img-team icon-circle'>
                                                        <IconUserDefaultProfile/>
                                                    </div>
                                                )}

                                                </div>
                                            <div className="user-info-details">
                                                <p>{userPermissions ? GetPermission(parsedPermissions) : 'No permissions'}</p>
                                                <p>{user.email}</p>
                                                <p>Projects: {user.projects_count}</p>
                                                <p className='project-table-content-data-section-field-center-users-address-mobile'>{project?.address?.full_address || 'No address'}</p>
                                            </div>
                                        </div>
                                        <div className="users-actions-btn-mobile">
                                            <button
                                                onClick={() => handleEditPermissionsClick(user, userPermissions?.level)}
                                                className='users-action-btn'>
                                                <div className='setting-users-mobile'><IconSettings/></div>
                                            </button>
                                            <button onClick={() => handleDeleteClick(user)}
                                                    className='costs-btn-users'>
                                                <div className='delete-users-mobile'><IconRemove/></div>
                                            </button>
                                        </div>
                                    </>
                                </section>
                            )}
                        </li>
                    );
                })}
            </ul>

            {isDeleteModalOpen && userToDelete && (
                <ModalDelete
                    isOpen={isDeleteModalOpen}
                    onRequestClose={() => setIsDeleteModalOpen(false)}
                    onCancel={() => setIsDeleteModalOpen(false)}
                    onConfirm={handleConfirmDelete}
                    message={`Are you sure you want to delete ${userToDelete.first_name || 'this user'}?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
            <EditPermissionsModal
                isOpen={isEditPermissionsModalOpen}
                onRequestClose={() => setIsEditPermissionsModalOpen(false)}
                onSave={handleSavePermissions}
                currentEmail={currentUser?.email || ''}
                currentRole={currentRole}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.updateProject.currentProject,
});

const mapDispatchToProps = {
    updateProjectPermissions,
    fetchProjectById
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserList);

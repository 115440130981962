import React, {useEffect, useRef, useState} from "react";
import {TMedia} from "../../../types/TMedia";
import {toast} from "react-toastify";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";
import './CreateProjectBom.css';
import {BOMProject} from "../../../types/ProjectBOMInterface";

const G_KEY = process.env.REACT_APP_BASE_GOOGLE_KEY;
const G_COUNTRY = process.env.REACT_APP_BASE_GOOGLE_LOCATION_COUNTRY;

export interface AddressData {
    address: {
        full_address: string;
        latitude: string;
        longitude: string;
    };
    target_date: string;
    title: string;
}

export interface FileData {
    file: File[];
}

interface CreateProjectBomProps {
    onDataReady: (addressData: AddressData, fileData: FileData) => void;
    getProject?: BOMProject | null;
    getProjectHas?: boolean;
    isReadOnlyLink?: boolean;
}

const CreateProjectBom: React.FC<CreateProjectBomProps> = ({ onDataReady, getProject, getProjectHas, isReadOnlyLink }) => {
    const initialTargetDate = (() => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    })();

    const [targetDate, setTargetDate] = useState<string>(initialTargetDate);
    const [name, setName] = useState<string>('');

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<TMedia | string | null>(null);

    const [latitude, setLatitude] = useState<string>('');
    const [longitude, setLongitude] = useState<string>('');
    const [, setShowHint] = useState<boolean>(false);
    const [city, setCity] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [dontFindAddress, setDontFindAddress] = useState<boolean>(false);
    const [fullAddress, setFullAddress] = useState<string>('');
    const [showNearestAddress, setShowNearestAddress] = useState<boolean>(false);
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

    const geocoderRef = useRef<google.maps.Geocoder | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const markerRef = useRef<google.maps.Marker | null>(null);
    const cityAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const streetAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const [, setCityLocation] = useState<google.maps.LatLng | null>(null);

    const isReadOnlyCombined = getProjectHas;

    useEffect(() => {
        if (getProject?.target_date) {
            const projectDate = new Date(getProject.target_date.split(" ")[0]);
            const day = String(projectDate.getDate()).padStart(2, '0');
            const month = String(projectDate.getMonth() + 1).padStart(2, '0');
            const year = projectDate.getFullYear();
            const formattedDate = `${year}-${month}-${day}`;
            setTargetDate(formattedDate);
        } else if (!isReadOnlyCombined) {
            setTargetDate(initialTargetDate);
        }
    }, [getProject, isReadOnlyCombined, initialTargetDate]);

    useEffect(() => {
        const initializeGoogleMaps = () => {
            if (window.google) {
                if (!geocoderRef.current) {
                    geocoderRef.current = new window.google.maps.Geocoder();
                }

                if (!dontFindAddress) {
                    const initializeAutocomplete = () => {
                        const cityInput = document.getElementById('city') as HTMLInputElement;
                        const streetInput = document.getElementById('street') as HTMLInputElement;

                        if (cityInput && !cityAutocompleteRef.current) {
                            const cityAutoCompleteInstance = new window.google.maps.places.Autocomplete(cityInput, {
                                types: ['(cities)'],
                                componentRestrictions: { country: `${G_COUNTRY}` },
                            });
                            cityAutocompleteRef.current = cityAutoCompleteInstance;

                            cityAutoCompleteInstance.addListener('place_changed', () => {
                                const place = cityAutoCompleteInstance.getPlace();
                                if (place.geometry) {
                                    const location = place.geometry.location;
                                    setCity(place.formatted_address || place.name);
                                    setLatitude(String(location.lat()));
                                    setLongitude(String(location.lng()));
                                    setStreet('');
                                    setFullAddress(place.formatted_address || place.name);
                                    setCityLocation(location);
                                    setShowNearestAddress(true);

                                    if (mapRef.current) {
                                        mapRef.current.setCenter(location);
                                        mapRef.current.setZoom(12);
                                    }

                                    if (markerRef.current) {
                                        markerRef.current.setPosition(location);
                                    } else {
                                        markerRef.current = new window.google.maps.Marker({
                                            position: location,
                                            map: mapRef.current,
                                        });
                                    }
                                }
                            });
                        }

                        if (streetInput && !streetAutocompleteRef.current) {
                            const streetAutoCompleteInstance = new window.google.maps.places.Autocomplete(streetInput, {
                                types: ['address'],
                                componentRestrictions: { country: `${G_COUNTRY}` },
                            });
                            streetAutocompleteRef.current = streetAutoCompleteInstance;

                            streetAutoCompleteInstance.addListener('place_changed', () => {
                                const place = streetAutoCompleteInstance.getPlace();
                                if (place.geometry) {
                                    const location = place.geometry.location;
                                    setStreet(place.formatted_address || place.name);
                                    setLatitude(String(location.lat()));
                                    setLongitude(String(location.lng()));
                                    setFullAddress(place.formatted_address || place.name);
                                    setShowNearestAddress(true);

                                    if (mapRef.current) {
                                        mapRef.current.setCenter(location);
                                        mapRef.current.setZoom(15);
                                    }

                                    if (markerRef.current) {
                                        markerRef.current.setPosition(location);
                                    } else {
                                        const newMarker = new window.google.maps.Marker({
                                            position: location,
                                            map: mapRef.current,
                                        });
                                        markerRef.current = newMarker;
                                    }
                                }
                            });
                        }
                    };

                    initializeAutocomplete();
                }
            }
        };

        const loadGoogleMapsScript = () => {
            if (window.google) {
                initializeGoogleMaps();
                setScriptLoaded(true);
                if (isReadOnlyCombined && getProject) {
                    setMapToProject(getProject);
                }
            } else {
                const existingScript = document.getElementById('googleMaps');
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${G_KEY}&libraries=places`;
                    script.id = 'googleMaps';
                    script.async = true;
                    script.defer = true;
                    script.onload = () => {
                        initializeGoogleMaps();
                        setScriptLoaded(true);
                        if (isReadOnlyCombined && getProject) {
                            setMapToProject(getProject);
                        }
                    };
                    script.onerror = () => {
                        toast.error('Error loading Google Maps script.');
                    };
                    document.body.appendChild(script);
                } else {
                    existingScript.onload = () => {
                        initializeGoogleMaps();
                        setScriptLoaded(true);
                        if (isReadOnlyCombined && getProject) {
                            setMapToProject(getProject);
                        }
                    };
                }
            }
        };

        loadGoogleMapsScript();
    }, [dontFindAddress, isReadOnlyCombined, getProject]);

    useEffect(() => {
        if (scriptLoaded && window.google && !mapRef.current && !dontFindAddress) {
            const initialLocation = new window.google.maps.LatLng(-30.5595, 22.9375);
            const mapOptions: google.maps.MapOptions = {
                zoom: 6,
                center: initialLocation,
                draggable: !isReadOnlyCombined,
                zoomControl: !isReadOnlyCombined,
                scrollwheel: !isReadOnlyCombined,
                disableDoubleClickZoom: isReadOnlyCombined,
                gestureHandling: isReadOnlyCombined ? 'none' : 'auto',
                disableDefaultUI: isReadOnlyCombined,
                keyboardShortcuts: !isReadOnlyCombined,
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                clickableIcons: false
            };

            const mapInstance = new window.google.maps.Map(document.getElementById('map-modal') as HTMLElement, mapOptions);
            mapRef.current = mapInstance;

            if (!geocoderRef.current) {
                geocoderRef.current = new window.google.maps.Geocoder();
            }
            if (getProjectHas) {
                if (markerRef.current) {
                    markerRef.current.setClickable(false);
                }
            }
            if (!getProjectHas) {
                mapInstance.addListener('click', (e: google.maps.MapMouseEvent) => {
                    if (e.latLng) {
                        const lat = e.latLng.lat();
                        const lng = e.latLng.lng();
                        setLatitude(String(lat));
                        setLongitude(String(lng));

                        if (markerRef.current) {
                            markerRef.current.setMap(null);
                        }

                        markerRef.current = new window.google.maps.Marker({
                            position: e.latLng,
                            map: mapInstance,
                        });
                        setShowNearestAddress(true);

                        if (geocoderRef.current) {
                            geocoderRef.current.geocode({ location: { lat, lng } }, (results, status) => {
                                if (status === 'OK' && results && results[0]) {
                                    setFullAddress(results[0].formatted_address);

                                    const addressComponents = results[0].address_components;
                                    const cityComponent = addressComponents.find(comp => comp.types.includes("locality"));
                                    const routeComponent = addressComponents.find(comp => comp.types.includes("route"));
                                    const streetNumberComponent = addressComponents.find(comp => comp.types.includes("street_number"));

                                    const streetName = routeComponent ? routeComponent.long_name : '';
                                    const streetNumber = streetNumberComponent ? streetNumberComponent.long_name : '';

                                    const fullStreet = streetName && streetNumber ? `${streetNumber} ${streetName}` : streetName || streetNumber;

                                    setCity(cityComponent ? cityComponent.long_name : '');
                                    setStreet(fullStreet || '');

                                    if (mapRef.current) {
                                        mapRef.current.setCenter(results[0].geometry.location);
                                        mapRef.current.setZoom(15);
                                    }

                                    if (markerRef.current) {
                                        markerRef.current.setPosition(results[0].geometry.location);
                                    } else {
                                        markerRef.current = new window.google.maps.Marker({
                                            position: results[0].geometry.location,
                                            map: mapRef.current,
                                        });
                                    }
                                } else {
                                    toast.error('The address could not be determined from the selected coordinates.', {
                                        autoClose: 5000
                                    });
                                    setFullAddress('');
                                    setCity('');
                                    setStreet('');
                                }
                            });
                        }
                    }
                });
            }

            if (isReadOnlyCombined && getProject) {
                setMapToProject(getProject);
            }
        }
    }, [scriptLoaded, dontFindAddress, isReadOnlyCombined, getProject]);

    useEffect(() => {
        if (fullAddress || imageFile || targetDate || fullAddress) {
            handleSubmit();
        }
    }, [fullAddress, imageFile, imagePreview, targetDate, fullAddress]);

    useEffect(() => {
        if (getProject) {
            populateFieldsFromProject(getProject);
        }
    }, [getProject, isReadOnlyCombined]);

    const populateFieldsFromProject = (project: BOMProject) => {
        setName(project.title);
        setTargetDate(formatDate(project.target_date));

        setFullAddress(project.address?.full_address || '');
        setLatitude(project.address?.latitude || '');
        setLongitude(project.address?.longitude || '');

        if (project.address?.full_address) {
            const addressParts = project.address.full_address.split(", ");
            if (addressParts.length >= 2) {
                setCity(addressParts[addressParts.length - 3] || '');
                setStreet(addressParts[addressParts.length - 2] || '');
            } else {
                setCity('');
                setStreet('');
            }
        } else {
            setCity('');
            setStreet('');
        }

        if (mapRef.current && geocoderRef.current) {
            setMapToProject(project);
        }

        if (project.galleries && project.galleries.length > 0) {
            const images = project.galleries.flatMap((gallery: { medias: any[] }) =>
                gallery.medias.map(media => media.url)
            );
            const latestImage = images[images.length - 1];
            setImagePreview(latestImage ? latestImage : null);
            setImageFile(null);
        }
    };


    const setMapToProject = (project: BOMProject) => {
        const latitude = project.address?.latitude ? parseFloat(project.address.latitude) : null;
        const longitude = project.address?.longitude ? parseFloat(project.address.longitude) : null;

        if (latitude === null || longitude === null) {
            console.error("Invalid latitude or longitude values");
            return;
        }

        const location = new window.google.maps.LatLng(latitude, longitude);
        setLatitude(String(latitude));
        setLongitude(String(longitude));
        setFullAddress(project.address?.full_address || '');
        setShowNearestAddress(true);
        setCityLocation(location);

        if (mapRef.current) {
            mapRef.current.setCenter(location);
            mapRef.current.setZoom(15);

            mapRef.current.setOptions({
                draggable: !getProjectHas,
                zoomControl: !getProjectHas,
                scrollwheel: !getProjectHas,
                disableDoubleClickZoom: getProjectHas,
                gestureHandling: getProjectHas ? 'none' : 'auto',
                clickableIcons: !getProjectHas,
            });

            if (getProjectHas) {
                google.maps.event.clearListeners(mapRef.current, 'click');
            } else {
                mapRef.current.addListener('click', (e: google.maps.MapMouseEvent) => {
                    if (e.latLng) {
                        const lat = e.latLng.lat();
                        const lng = e.latLng.lng();
                        setLatitude(String(lat));
                        setLongitude(String(lng));

                        if (markerRef.current) {
                            markerRef.current.setPosition(e.latLng);
                        } else {
                            markerRef.current = new window.google.maps.Marker({
                                position: e.latLng,
                                map: mapRef.current,
                                draggable: true,
                            });
                        }
                    }
                });
            }
        }

        if (!markerRef.current) {
            markerRef.current = new window.google.maps.Marker({
                position: location,
                map: mapRef.current,
                draggable: false,
            });
        } else {
            markerRef.current.setPosition(location);
            markerRef.current.setDraggable(false);
        }
    };



    const handleFocus = () => {
        setShowHint(true);
        setTimeout(() => {
            setShowHint(false);
        }, 5000);
    };

    const handleBlur = () => {
        setShowHint(false);
    };

    const handleDontFind = () => {
        if (getProjectHas) return;

        setDontFindAddress(prevState => !prevState);
        if (!dontFindAddress) {
            toast.info('Please select a location on the map to determine coordinates.', {
                autoClose: 6000
            });

            if (cityAutocompleteRef.current) {
                window.google.maps.event.clearInstanceListeners(cityAutocompleteRef.current);
                cityAutocompleteRef.current = null;
            }

            if (streetAutocompleteRef.current) {
                window.google.maps.event.clearInstanceListeners(streetAutocompleteRef.current);
                streetAutocompleteRef.current = null;
            }

            setCity('');
            setStreet('');
            setFullAddress('');
            setLatitude('');
            setLongitude('');
            setShowNearestAddress(false);
            if (markerRef.current) {
                markerRef.current.setMap(null);
            }
        } else {
            setLatitude('');
            setLongitude('');
            setFullAddress('');
            setShowNearestAddress(false);

            if (markerRef.current) {
                markerRef.current.setMap(null);
            }
            if (mapRef.current) {
                mapRef.current.setCenter(new window.google.maps.LatLng(-30.5595, 22.9375));
                mapRef.current.setZoom(6);
                mapRef.current.setOptions({
                    draggable: true,
                    zoomControl: true,
                    scrollwheel: true,
                    disableDoubleClickZoom: false,
                    clickableIcons: true,
                    gestureHandling: 'auto',
                });
            }
        }
    };

    const geocodeAddress = () => {
        if (geocoderRef.current && city && street) {
            const fullAddress = `${street}, ${city}, South Africa`;
            geocoderRef.current.geocode({ address: fullAddress }, (results, status) => {
                if (status === 'OK' && results && results.length > 0) {
                    const location = results[0].geometry.location;
                    setLatitude(String(location.lat()));
                    setLongitude(String(location.lng()));
                    setFullAddress(results[0].formatted_address);
                    setShowNearestAddress(true);

                    if (mapRef.current) {
                        mapRef.current.setCenter(location);
                        mapRef.current.setZoom(15);
                    }

                    if (markerRef.current) {
                        markerRef.current.setPosition(location);
                    } else {
                        const newMarker = new window.google.maps.Marker({
                            position: location,
                            map: mapRef.current,
                        });
                        markerRef.current = newMarker;
                    }
                } else {
                    toast.error('The coordinates for the entered address could not be determined. Please try again.', {
                        autoClose: 5000
                    });
                }
            });
        }
    };

    const handleSubmit = () => {
        const addressData: AddressData = {
            address: {
                full_address: fullAddress,
                latitude,
                longitude
            },
            target_date: targetDate,
            title: name
        };
        const fileData: FileData = {
            file: imageFile ? [imageFile] : []
        };
        onDataReady(addressData, fileData);
    };

    return (
        <section className='project-bom-create'>
            <div className='create-project-bom-section'>
                <div className='project-bom-fields'>
                    <div className="template-user-info-image">
                        <ImageUpload
                            imageFile={imageFile}
                            setImageFile={setImageFile}
                            imagePreview={imagePreview}
                            setImagePreview={setImagePreview}
                            disabled={getProjectHas}
                            getProject={getProject}
                        />
                    </div>
                    <div className="modal-content">
                        <section>
                            <label>Project Space Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => {
                                    if (fullAddress) handleSubmit();
                                }}
                                placeholder="Project #1"
                                className="project-name-input-modal"
                                disabled={getProjectHas}
                            />
                        </section>
                        <section>
                            <label>Target Completion Date</label>
                            <input
                                type="date"
                                min={initialTargetDate}
                                value={targetDate}
                                onChange={(e) => setTargetDate(e.target.value)}
                                className="project-date-input-modal"
                                disabled={getProjectHas}
                            />
                        </section>
                    </div>
                </div>

                <div className='project-prompt-modal'>
                    Your Address:
                    <div className='map-bom' id="map-modal"></div>
                </div>

                <div className="create-bom-region">
                    <div className='create-bom-block-region'>
                        <div className="address-input-group">
                            <p
                                onClick={handleDontFind}
                                className={`project-city-label ${isReadOnlyCombined ? 'not-allowed' : 'clickable'}`}
                            >
                                Project City
                            </p>
                            <input
                                id="city"
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder="Enter city"
                                className="project-address-input-modal"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                disabled={dontFindAddress || getProjectHas}
                            />
                        </div>
                        <div className="address-input-group">
                            <p>Project Street</p>
                            <input
                                id="street"
                                type="text"
                                value={street}
                                placeholder="Enter street"
                                onChange={(e) => setStreet(e.target.value)}
                                onBlur={() => {
                                    handleBlur();
                                    geocodeAddress();
                                }}
                                className="project-address-input-modal"
                                onFocus={handleFocus}
                                disabled={dontFindAddress || getProjectHas}
                            />
                        </div>
                        {showNearestAddress && (
                            <div className="address-input-group">
                                <label>Nearest Address:</label>
                                <input
                                    type="text"
                                    value={fullAddress}
                                    className="project-address-input-modal"
                                    readOnly
                                    disabled={getProjectHas}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    }

    const date = new Date(dateString.split(" ")[0]);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
};

export default CreateProjectBom;

import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import '../../components/Style List View/style.css';
import './style.css';
import ElementList from "../../components/ElementList/ElementList";
import HeaderList from "../../components/HeaderRoutList/HeaderListRout";
import {fetchAllProjects} from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import {RootState} from "../../store";
import {fetchUsersFromOwnProjects} from "../../Slice/userFromOwnProject/UserFromOwnProjectSlice";
import AddMemberModal from "../../components/Modals/ModalAddNewMember/AddNewMember";
import {User} from "../../types/ProjectTypes";
import {IconSettings, IconTeamManagement, IconUserDefaultProfile} from "../../IconComponents/IconComponents";
import {Loader} from "../../components/Loader/Loader";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import ModalUserInformation from "../../components/Modals/ModalUserInformation/ModalUserInforamtion";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {unifiedColumnFieldMapping} from "../../utils/ColumnFieldSorting";
import {useNavigate} from "react-router-dom";

const TeamManagerPage: React.FC<PropsFromRedux> = ({
                                                       users,
                                                       totalUsers,
                                                       isFetching,
                                                       error,
                                                       fetchUsersFromOwnProjects,
                                                       allProjects,
                                                       isAuthenticated
                                                   }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [openUserInformation, setOpenUserInformation] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const navigate = useNavigate()
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);

    const handleSortChange = (column: string) => {
        const field = unifiedColumnFieldMapping[column];
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };


    const [perPage] = useState(10);

    useEffect(() => {
        if (isAuthenticated) {
            fetchUsersFromOwnProjects({
                page: currentPage,
                perPage: perPage,
                sortBy: sortBy ?? undefined,
                sortOrder: sortOrder ?? undefined
            });
        } else {
            navigate('/home')
        }
    }, [currentPage, perPage, sortBy, sortOrder,isAuthenticated]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isFetching) return <Loader/>;
    if (error) return <p>Error: {error}</p>;

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleOpenUserInformationModal = (user: User) => {
        setSelectedUser(user);
        setOpenUserInformation(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const columnsWidthClasses = ['width-user-username-team-mobile', 'width-user-projects-team-mobile',
        "width-user-email-team-mobile", "width-user-access-rights-team-mobile", "table-content-data-section-field-actions-1btn-team"];

    const renderUsers = () => {
        if (!Array.isArray(users)) {
            return <p>No users available</p>;
        }

        return users.map((user) => {
            const hasPermissions = allProjects.some(project =>
                project.permissions.some(permission => permission.user_id === user.id)
            );
            return (
                <li key={user.id}>
                    <section className={`table-content-data-section-team ${isSmallScreen ? 'small-screen' : ''}`}>
                        {isSmallScreen ? (
                            <>
                                <div className="user-info-block">
                                    <div className="user-image-block">
                                        {user.galleries && user.galleries.length > 0 &&
                                        user.galleries[user.galleries.length - 1] &&
                                        user.galleries[user.galleries.length - 1].medias &&
                                        user.galleries[user.galleries.length - 1].medias[user.galleries[user.galleries.length - 1].medias.length - 1] &&
                                        user.galleries[user.galleries.length - 1].medias[user.galleries[user.galleries.length - 1].medias.length - 1].url ? (
                                            <img
                                                className="width-table-img-team icon-circle"
                                                src={user.galleries[user.galleries.length - 1].medias[user.galleries[user.galleries.length - 1].medias.length - 1].url}
                                                alt="User"
                                            />
                                        ) : (
                                            <div className="width-table-img-team icon-circle">
                                                <IconUserDefaultProfile/>
                                            </div>
                                        )}
                                    </div>

                                    <div className="user-info-details">
                                        <p>{user.username} <strong>({user.role.role})</strong></p>
                                        <p>{user.email}</p>
                                        <p>Projects: {user.projects_count}</p>
                                    </div>
                                </div>
                                <div className="user-actions-block">
                                    {!hasPermissions ? '' : (
                                        <button
                                            onClick={() => handleOpenUserInformationModal(user)}
                                            className="team-action-btn-manag">
                                            <div className="setting">
                                                <IconSettings/></div>
                                        </button>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="table-content-data-section-field-image-team width-user-username-team">
                                    {user.galleries && user.galleries.length > 0 && user.galleries[0] &&
                                    user.galleries[0].medias && user.galleries[0].medias[user.galleries[0].medias.length - 1] &&
                                    user.galleries[0].medias[user.galleries[0].medias.length - 1].url ? (
                                        <img
                                            className="width-table-img-team icon-circle"
                                            src={user.galleries[0].medias[user.galleries[0].medias.length - 1].url}
                                            alt="User"
                                        />
                                    ) : (
                                        <div className="width-table-img-team icon-circle">
                                            <IconUserDefaultProfile className='custom-avatar-team' width="48px"
                                                                    height="48px"/>
                                        </div>
                                    )}
                                    <p className="table-content-data-section-field-image-title-team">
                                        {user.username}
                                    </p>
                                </div>
                                <p className="table-content-data-section-field-team width-user-projects-team">
                                    {user.projects_count}
                                </p>
                                <p className="table-content-data-section-field-team width-user-email-team">
                                    {user.email}
                                </p>
                                <p className="table-content-data-section-field-team width-user-access-rights-team">
                                    {user.role.role}
                                </p>
                                <div className="table-content-data-section-field-actions-1btn-team">
                                    {!hasPermissions ? '' : (
                                        <button
                                            onClick={() => handleOpenUserInformationModal(user)}
                                            className="team-action-btn-manag">
                                            <div title='User dependency on the project' className="setting">
                                                <IconSettings/></div>
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </section>
                </li>
            );
        });
    };

    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                        {to: '', label: '>'},
                        {to: '/team', label: 'Team management'}
                    ]}
                >
                </MobileNavigation>
            )}
            <HeaderList/>
            <div className='name-content-team'>
                <div className='team'>
                    <p>Team management</p>
                    <IconTeamManagement/>
                </div>
                <button className='mh-btn-member-users' onClick={handleOpenModal}>+ Add new member</button>
            </div>
            {!isSmallScreen && (
                <ElementList
                    columns={['User', 'Projects', 'E-mail', 'Role', 'Action']}
                    columnsWidthClasses={columnsWidthClasses}
                    sortBy={sortBy ?? undefined}
                    sortOrder={sortOrder ?? undefined}
                    onSortChange={handleSortChange}
                    isShow={true}
                />
            )}
            <div className='wrapper'>
                <ul className="table-content-data-section-container-team">
                    {renderUsers()}
                </ul>
            </div>
            <PaginationAssistance
                projectsPerPage={perPage}
                totalProjects={totalUsers.total}
                paginate={setCurrentPage}
                currentPage={currentPage}
            />
            <AddMemberModal
                pageTeamManagement={true}
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                onPermissionsUpdated={() => {
                }}
                showProjects={true}
            />
            <ModalUserInformation
                isOpen={openUserInformation}
                onClose={() => setOpenUserInformation(false)}
                user={selectedUser}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    allProjects: state.projectAssistance.allProjects,
    users: state.usersFromOwnProjects.users,
    isFetching: state.usersFromOwnProjects.isFetching,
    error: state.usersFromOwnProjects.error,
    totalUsers: state.usersFromOwnProjects.totalUsers,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchUsersFromOwnProjects,
    fetchAllProjects
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TeamManagerPage);

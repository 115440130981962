import { Template, TTemplateRequestObject } from "../../types/TemplateType";
import { TQuestionRequestObject } from "../../types/QuestionTypes/TQuestionRequestObject";

const parseTemplateToRequestObject = (template: Template): TTemplateRequestObject => {
    const data = {
        questions: [] as TQuestionRequestObject[],
        fragments: template.data.fragments.map((fragment: { data: { var_mapping: any; }; }) => ({
            ...fragment,
            data: {
                ...fragment.data,
                var_mapping: fragment.data.var_mapping
            }
        })),
    };

    for (const key in template.data) {
        if (!isNaN(Number(key)) && template.data[key].question && template.data[key].answers) {
            data.questions.push(template.data[key]);
        }
    }

    return {
        id: template.id,
        title: template.title,
        created_at: template.created_at,
        description: template.description ?? '',
        data,
        categories: template.categories,
        galleries: template.galleries,
    };
};

export default parseTemplateToRequestObject;

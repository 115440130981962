import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { connect, ConnectedProps } from "react-redux";
import './style.css';
import { PermissionEdit } from "../../RoleComponent/EnumRole";
import { updateProjectPermissions } from "../../../Slice/updateProject/updateProjectSlice";
import { RootState } from "../../../store";
import { fetchUsersFromOwnProjects } from "../../../Slice/userFromOwnProject/UserFromOwnProjectSlice";
import {fetchAllProjects} from "../../../Slice/getProjectAssistance/ProjectAssistanceSlice";

interface AddMemberModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    projectId?: string;
    showProjects?: boolean;
    onPermissionsUpdated: () => void;
    pageTeamManagement:boolean
}

const AddMemberModal: React.FC<AddMemberModalProps & PropsFromRedux> = ({
                                                                            isOpen,
                                                                            onRequestClose,
                                                                            projectId,
                                                                            projects,
                                                                            showProjects,
                                                                            updateProjectPermissions,
                                                                            pageTeamManagement,
                                                                            users,
                                                                            fetchAllProjects,
                                                                            onPermissionsUpdated
                                                                        }) => {
    const [role, setRole] = useState<string>('User');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>('');
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(projectId || null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
        };
    }, [isOpen]);


    useEffect(() => {
        if (pageTeamManagement) {
            fetchAllProjects({ page: 1, perPage: 1000,queryParams:'&with[]=title' });
        }
    }, [pageTeamManagement]);

    useEffect(() => {
        if (email && Array.isArray(users)) {
            const filteredSuggestions = users
                .map(user => user.email)
                .filter(userEmail => userEmail.includes(email) && userEmail !== email)
                .sort((a, b) => {
                    const startsWithA = a.startsWith(email);
                    const startsWithB = b.startsWith(email);
                    if (startsWithA && !startsWithB) return -1;
                    if (!startsWithA && startsWithB) return 1;
                    return 0;
                });
            setSuggestions(filteredSuggestions);
            setShowSuggestions(true);
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [email, users]);

    const handleInvite = () => {
        if (email && selectedProjectId) {
            const permissions = role === 'User' ? [PermissionEdit.READ] : [PermissionEdit.READ, PermissionEdit.UPDATE, PermissionEdit.DELETE, PermissionEdit.OWNER];
            updateProjectPermissions({
                projectId: selectedProjectId,
                email: email,
                permissions: permissions.map(permission => PermissionEdit[permission])
            }).then(() => {
                onPermissionsUpdated();
                onRequestClose();
            });
            setName('');
            setEmail('');
            setIsEmailValid(false);
            setEmailError('');
        }
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(false);
        setEmailError('');

        if (validateEmail(emailValue)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        setEmail(suggestion);
        setIsEmailValid(true);
        setEmailError('');
        setShowSuggestions(false);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setShowSuggestions(false);
            if (email && !isEmailValid) {
                setEmailError('Invalid email address');
            }
        }, 100);
    };

    const handleFocus = () => {
        if (email) {
            setShowSuggestions(true);
        }
    };

    const isInviteDisabled = showProjects ? !selectedProjectId || !role || !email || !isEmailValid : !email || !isEmailValid;

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal" overlayClassName="overlay">
            <div className="modal-header">
                <h2>Add new member</h2>
                <button onClick={onRequestClose} className="close-button">&times;</button>
            </div>
            <div className="modal-content">
                {showProjects && projects && (
                    <label>
                        Project group:
                        <select value={selectedProjectId ?? ''} onChange={(e) => setSelectedProjectId(e.target.value)}>
                            <option value="" disabled>Select project</option>
                            {projects.map(project => (
                                <option key={project.id} value={project.id}>{project.title}</option>
                            ))}
                        </select>
                    </label>
                )}
                <label>
                    Role:
                    <select value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="User">User</option>
                        <option value="Admin">Admin</option>
                    </select>
                </label>
                <label>
                    Name:
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </label>
                <label>
                    E-mail:
                    <div className="email-input-wrapper">
                        <input
                            type="text"
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                        />
                        {emailError && !isEmailValid && <span className="error">{emailError}</span>}
                        {showSuggestions && suggestions.length > 0 && (
                            <ul className="suggestions">
                                {suggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </label>
            </div>
            <div className="modal-footer-template">
                <button
                    onClick={handleInvite}
                    className={`invite-button ${isInviteDisabled ? 'disabled' : ''}`}
                    disabled={isInviteDisabled}
                >
                    Invite
                </button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    users: state.usersFromOwnProjects.users,
    projects: state.projectAssistance.allProjects,
});

const mapDispatchToProps = {
    updateProjectPermissions,
    fetchUsersFromOwnProjects,
    fetchAllProjects
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddMemberModal);

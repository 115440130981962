import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {get} from "../../api/APIWraper";
import {RootState} from "../../store";

export const fetchTokens = createAsyncThunk("tokens/fetchTokens", async () => {
    const response = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
    return response.data.tokens;
});

const tokenSlice = createSlice({
    name: "tokens",
    initialState: {
        tokens: 0,
    },
    reducers: {
        setTokens: (state, action: PayloadAction<number>) => {
            state.tokens = action.payload;
        },

        updateTokensFromWS: (state, action: PayloadAction<number>) => {
            state.tokens = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTokens.fulfilled, (state, action) => {
            state.tokens = action.payload;
        });
    },
});

export const { setTokens, updateTokensFromWS } = tokenSlice.actions;
export const selectTokens = (state: RootState) => state.tokens.tokens;
export default tokenSlice.reducer;
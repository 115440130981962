import { useEffect } from 'react';


declare global {
    interface Window {
        lhnJsSdkInit?: () => void;
        lhnJsSdk?: {
            setup?: {
                application_id: string;
                application_secret: string;
            };
            controls?: Array<{ type: string; id: string }>;
        };
    }
}

const LiveHelpNowChat = () => {
    useEffect(() => {
        window.lhnJsSdkInit = function () {
            window.lhnJsSdk = window.lhnJsSdk || {};
            window.lhnJsSdk.setup = {
                application_id: "25cb2181-be71-4c5a-81d9-bdc274e3befd",
                application_secret: "8loorouea+9aajgb5x/hxk0kjsjxmqbe58odpztpn6fkdzleom",
            };
            window.lhnJsSdk.controls = [
                {
                    type: "hoc",
                    id: "9f927324-17e1-4f39-8069-10046bc4f2be",
                },
            ];
        };

        const script = document.createElement("script");
        script.src = "https://developer.livehelpnow.net/js/sdk/lhn-jssdk-current.min.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default LiveHelpNowChat;

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect, ConnectedProps } from "react-redux";
import { User, Project } from "../../../types/ProjectTypes";
import { PermissionEdit } from "../../RoleComponent/EnumRole";
import { updateProjectPermissions } from "../../../Slice/updateProject/updateProjectSlice";
import './userInformationModalRole.css';
import { unwrapResult } from '@reduxjs/toolkit';

interface EditUserModalProps {
    isOpen: boolean;
    onClose: () => void;
    user: User | null;
    project: Project | null;
    onPermissionsUpdated: (updatedProject: Project) => void;
}

type CombineProps = EditUserModalProps & PropsFromRedux;

const ModalUserInformationRole: React.FC<CombineProps> = ({
                                                              isOpen,
                                                              onClose,
                                                              user,
                                                              project,
                                                              updateProjectPermissions,
                                                              onPermissionsUpdated
                                                          }) => {
    const [role, setRole] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        if (user) {
            const userPermissions = project?.permissions.find(permission => permission.user.email === user.email);
            const parsedPermissions = userPermissions
                ? userPermissions.level.filter((permission: any) => permission !== null) as PermissionEdit[]
                : [];
            const userRole = parsedPermissions.includes(PermissionEdit.OWNER) ? 'Admin' : 'User';

            setRole(userRole);
            setEmail(user.email);
        }
    }, [user, project]);

    const handleSave = async () => {
        if (user && project) {
            const permissions =
                role === 'User'
                    ? [PermissionEdit.READ]
                    : [PermissionEdit.READ, PermissionEdit.UPDATE, PermissionEdit.DELETE, PermissionEdit.OWNER];

            try {
                const result = await updateProjectPermissions({
                    projectId: String(project.id),
                    email: user.email,
                    permissions: permissions.map(permission => PermissionEdit[permission])
                });
                const updatedProject = unwrapResult(result);
                onPermissionsUpdated(updatedProject);
                onClose();
            } catch (error) {
                console.error("Failed to update project permissions: ", error);
            }
        }
    };

    if (!user || !project) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className='edit-user-modal' overlayClassName='overlay'>
            <div className='edit-user-modal-header'>
                <h2>{project.title}</h2>
                <button onClick={onClose} className="close-button">&times;</button>
            </div>
            <div className="edit-user-modal-content">
                <label>
                    Role:
                    <select value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="User">User</option>
                        <option value="Admin">Admin</option>
                    </select>
                </label>
                <label>
                    Name:
                    <input type="text" value={`${user.first_name || ''} ${user.last_name || ''}`} readOnly className="readonly-input" />
                </label>
                <label>
                    E-mail:
                    <input type="text" value={email} readOnly className="readonly-input" />
                </label>
            </div>
            <div className="edit-user-modal-footer">
                <button onClick={handleSave} className="save-button">Save</button>
            </div>
        </Modal>
    );
};

const mapDispatchToProps = {
    updateProjectPermissions,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModalUserInformationRole);

import React, { useEffect, useState } from 'react';
import './ShowMoreListStep2.css';
import './ModalShowMoreListStep2.css';
import { Template } from "../../types/TemplateType";
import parseTemplateToRequestObject from "../../Parsers/TemplateParse/templateToTemplateRequestParser";

interface ModalProps {
    closeModalShowMore: () => void;
    isModalOpenShowMore: boolean;
    template: Template;
}

const ModalShowMoreListStep2: React.FC<ModalProps> = ({ closeModalShowMore, isModalOpenShowMore, template }) => {
    const [expandedFragments, setExpandedFragments] = useState<Set<number>>(new Set());
    const [isTemplateExpanded, setIsTemplateExpanded] = useState<boolean>(true);

    useEffect(() => {
        if (isModalOpenShowMore) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
            window.addEventListener('keydown', handleKeyDown);
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpenShowMore]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeModalShowMore();
        }
    };

    const parserTemplate = parseTemplateToRequestObject(template);


    const toggleTemplateVisibility = () => {
        setIsTemplateExpanded(prevState => !prevState);
    };

    const toggleFragment = (index: number) => {
        setExpandedFragments(prevState => {
            const newSet = new Set(prevState);
            if (newSet.has(index)) {
                newSet.delete(index);
            } else {
                newSet.add(index);
            }
            return newSet;
        });
    };


    if (!isModalOpenShowMore || !template) {
        return null;
    }

    return (
        isModalOpenShowMore && (
            <div className="modal-overlay">
                <div className="modal-content-more-list">
                    <div className='modal-content-show-step-btn'>
                        <div>
                        <p className='modal-content-required'>Required information</p>
                        <p className='modal-content-text-questions'>Additional questions:</p>
                        </div>
                        <button className="modal-close" onClick={closeModalShowMore}>
                            &times;
                        </button>

                    </div>
                    <div className={`step-calc-fragment ${isTemplateExpanded ? 'expanded' : 'collapsed'}`} key={template.id}>
                        <h3 className="step-calc-fragment-title" onClick={toggleTemplateVisibility}>
                            {template.title}
                            <button className={`toggle-button ${isTemplateExpanded ? 'expanded' : 'collapsed'}`}>
                                {isTemplateExpanded ? '▲' : '▼'}
                            </button>
                        </h3>
                        <div className="step-calc-fragment-content">
                            {Object.entries(template.data || {})
                                .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select)
                                .map(([key, templateQuestion]) => (
                                    <div className="step-answer-block" key={`${template.id}-calc-${key}`}>
                                        <div className="step-question">{templateQuestion?.question || 'Default Question'}</div>
                                        <div className='step-calc-field-fragment'>
                                            {templateQuestion.select === 'select' && templateQuestion.answers ? (
                                                templateQuestion.answers.map((answer: any, idx: number) => (
                                                    <label key={idx} className="step-answer">
                                                        <input
                                                            type="radio"
                                                            name={`${template.id}-calc-${key}`}
                                                            value={answer.value}
                                                            checked={true}
                                                            readOnly
                                                        />
                                                        <span className="radio-custom"></span>
                                                        {answer.title}
                                                    </label>
                                                ))
                                            ) : (
                                                <input
                                                    className={`step-calc-field-fragment filled`}
                                                    type="text"
                                                    value={templateQuestion.value || ''}
                                                    readOnly
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className='step-calc-fragment-block'>
                        {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => {
                            const hiddenKeys = new Set<string>();

                            if (fragment?.data?.var_mapping) {
                                const mappings = fragment.data.var_mapping.split('&&').map((map: string) => map.trim());
                                mappings.forEach((mapping: string) => {
                                    const [left, right] = mapping.split('=').map((s: string) => s.trim());
                                    hiddenKeys.add(right);
                                });
                            }

                            const hasQuestions = Object.entries(fragment.data || {}).some(([key, fragmentData]: [string, any]) => {
                                return !hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select;
                            });

                            if (!hasQuestions) {
                                return null;
                            }

                            const isExpanded = expandedFragments.has(fragmentIndex);

                            return (
                                <div
                                    key={`fragment-${fragmentIndex}`}
                                    className={`step-calc-fragment fragment-${fragmentIndex} ${isExpanded ? 'expanded' : 'collapsed'}`}
                                >
                                    <h3 className="step-calc-fragment-title" onClick={() => toggleFragment(fragmentIndex)}>
                                        {fragment.title || `Fragment ${fragmentIndex + 1}`}
                                        <button className={`toggle-button ${isExpanded ? '' : 'collapsed'}`}>
                                            {isExpanded ? '▲' : '▼'}
                                        </button>
                                    </h3>
                                    <div className={`step-calc-fragment-content ${isExpanded ? 'expanded' : ''}`}>
                                        {Object.entries(fragment.data || {}).map(([key, fragmentData]: [string, any], index: number) => {
                                            if (!hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select) {
                                                return (
                                                    <div key={`${fragmentIndex}-${key}`} className="step-answer-block-modal">
                                                        <div className="step-question">{fragmentData.question}</div>
                                                        {fragmentData.select === 'select' ? (
                                                            fragmentData.answers ? (
                                                                fragmentData.answers.map((answer: any, idx: number) => (
                                                                    <label key={idx} className="step-answer">
                                                                        <input
                                                                            type="radio"
                                                                            name={`fragment-${fragmentIndex}-${key}`}
                                                                            value={answer.value}
                                                                            checked={fragmentData.value === answer.value}
                                                                            readOnly
                                                                        />
                                                                        <span className="radio-custom"></span>
                                                                        {answer.title}
                                                                    </label>
                                                                ))
                                                            ) : <p>No answers available</p>
                                                        ) : (
                                                            <div className='step-calc-field-fragment'>
                                                                <input
                                                                    type="text"
                                                                    value={fragmentData.value || ''}
                                                                    readOnly
                                                                    className={fragmentData.value ? 'filled' : ''}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    );
};

export default ModalShowMoreListStep2;

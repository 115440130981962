import { configureStore } from "@reduxjs/toolkit";
import projectByIdSlice from "./Slice/projectById/projectByIdSlice";
import ProjectAssistanceSlice from "./Slice/getProjectAssistance/ProjectAssistanceSlice";
import getAllTemplatesSlice from "./Slice/getAllTemplates/GetAllTemplatesSlice";
import templateByIdSlice from './Slice/getByIdTemplate/ByIdTemplateSlice';
import LocalTemplatesSlice from './Slice/LocalTemplate/LocalTemplateSlice';
import storeSlice from './Slice/getAllStores/fetchStores';
import updateProjectSlice from "./Slice/updateProject/updateProjectSlice";
import userFromOwnProjectSlice from "./Slice/userFromOwnProject/UserFromOwnProjectSlice";
import selectedItemsSlice from "./Slice/SelectedItems/SelectedItemsSlice";
import itemTypeSlice from "./Slice/ItemType/ItemTypeSlice";
import OrderHistorySlice from "./Slice/OrderHistory/OrderHistorySlice";
import userReducer from "./Slice/AuthSlice/MeSlice";
import authReducer from "./Slice/AuthSlice/AuthSlice";
import fragmentSlice from './Slice/ClickSlice/FragmentVisibityState';
import  websocketReducer from './Slice/WebSocket/WebSocketSlice'


const isDevToolsEnabled = process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS === 'true';

const store = configureStore({
    reducer: {
        projectById: projectByIdSlice,
        projectAssistance: ProjectAssistanceSlice,
        selectedItems: selectedItemsSlice,
        updateProject: updateProjectSlice,
        allTemplates: getAllTemplatesSlice,
        templateById: templateByIdSlice,
        localTemplate: LocalTemplatesSlice,
        allStores: storeSlice,
        usersFromOwnProjects: userFromOwnProjectSlice,
        itemTypes: itemTypeSlice,
        orderHistory:OrderHistorySlice,
        user: userReducer,
        auth: authReducer,
        fragmentVisible: fragmentSlice,
        websocket: websocketReducer,
    },
    devTools: isDevToolsEnabled
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

import './style.css';
import { unifiedColumnFieldMapping } from "../../utils/ColumnFieldSorting";
import React from 'react';

interface ListProps {
    columns?: string[],
    columnsWidthClasses?: string[],
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
    onSortChange?: (column: string) => void,
    isShow?: boolean,
    isShowCosts?: boolean,
    orderHistory?: boolean,
    orderHistoryColumns?: string[],
    orderHistoryWidthClasses?: string[]
}

const ElementList: React.FC<ListProps> = ({
                                              columns,
                                              columnsWidthClasses = [],
                                              sortBy,
                                              sortOrder,
                                              onSortChange,
                                              isShow = true,
                                              isShowCosts = false,
                                              orderHistory = false,
                                              orderHistoryColumns = [],
                                              orderHistoryWidthClasses = []
                                          }) => {
    const handleSort = (column: string) => {
        if (column !== 'Action' && column !== 'Members' && column !== 'Customer' && column !== 'Project' && column !== 'Product' && onSortChange) {
            onSortChange(column);
        }
    };

    const currentColumns = orderHistory && orderHistoryColumns.length > 0 ? orderHistoryColumns : columns;
    const currentWidthClasses = orderHistory && orderHistoryWidthClasses.length > 0 ? orderHistoryWidthClasses : columnsWidthClasses;
    const shouldShowCosts = isShowCosts;
    if(!currentColumns){
        return null
    }
    return (
        <div className='list-item'>
            <ul className='list-item-ul'>
                {currentColumns.map((item, index) => {
                    const isActive = unifiedColumnFieldMapping[item] === sortBy;
                    let indicatorClass = 'indicator-right';

                    if (isActive) {
                        indicatorClass = sortOrder === 'asc' ? 'indicator-up' : 'indicator-down';
                    }

                    const shouldShowIndicator = shouldShowCosts ? item === 'Project' : true;

                    return (
                        !shouldShowCosts ? (
                            isShow ? (
                                <li
                                    className={`list-item-li ${currentWidthClasses[index] || ''}`}
                                    key={index}
                                    onClick={() => handleSort(item)}
                                    style={{ cursor: (item !== 'Action' && item !== 'Members' && item !== 'Customer' && item !== 'Project' && item !== 'Product') ? 'pointer' : 'default' }}
                                >
                                    <p className='list-item-p'>
                                        {item}
                                        {shouldShowIndicator && item !== 'Action' && item !== 'Members' && item !== 'Customer' && item !== 'Project' && item !== 'Product' && (
                                            <span className={indicatorClass}></span>
                                        )}
                                    </p>
                                </li>
                            ) : (
                                <li
                                    className={`list-item-li ${currentWidthClasses[index] || ''}`}
                                    key={index}
                                >
                                    <p className='list-item-p'>
                                        {item}
                                    </p>
                                </li>
                            )
                        ) : (
                            <li
                                className={`list-item-li-costs `}
                                key={index}
                            >
                                <p className='list-item-p-costs'>{item}</p>
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
};

export default ElementList;

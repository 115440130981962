import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./store";
import Modal from "react-modal";
import {setVarGlobalColors} from "./VarGlobal/VarGlobalColor";
import {setVarGlobalFont} from "./VarGlobal/VarGlobalFont";

setVarGlobalColors();
setVarGlobalFont();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

Modal.setAppElement('#root');

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { TItem } from "../../types/TemplateType";
import "./Step2Styles/itemModal.css";
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemError,
    toastItemTrue
} from "../../components/Toast/AssisCreateToastNotification";
import { IconArrowBottom, IconArrowTop, IconReload, IconSearch } from "../../IconComponents/IconComponents";
import { Loader } from "../../components/Loader/Loader";
import PaginationInModal from "../../components/PaginationAssistance/paginationInModal";

interface ItemSelectionModalProps {
    isOpen: boolean,
    onClose: () => void,
    items: TItem[],
    loading: boolean,
    handleItemClick: (items: TItem[]) => void,
    selectedItems: { itemId: number; user_item_qty: number }[],
    onReload?: () => void,
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChange: (page: number) => void,
    onSearch?: (query: string, page: number) => void;
    totalSearchResults?: number,
    storeId: number
}

const ItemSelectionModal: React.FC<ItemSelectionModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   items,
                                                                   loading,
                                                                   handleItemClick,
                                                                   selectedItems,
                                                                   onReload,
                                                                   currentPage,
                                                                   totalItems,
                                                                   itemsPerPage,
                                                                   onPageChange,
                                                                   onSearch,
                                                                   totalSearchResults,
                                                               }) => {
    const [selectedItemsState, setSelectedItemsState] = useState<{ [id: number]: TItem }>({});
    const [userItemQuantities, setUserItemQuantities] = useState<{ [id: number]: number }>({});
    const [isError, setIsError] = useState(false);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [filteredItems, setFilteredItems] = useState<TItem[]>(items);
    const [deletedItems, setDeletedItems] = useState<{ [id: number]: boolean }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const [isFilled, setIsFilled] = useState<{ [id: number]: boolean }>({});
    const isUserAction = useRef(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            initializeState();
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen, items]);

    useEffect(() => {
        if (onSearch) {
            if (searchTimer) {
                clearTimeout(searchTimer);
            }
            if (searchTerm) {
                const timer = setTimeout(() => {
                    onSearch(searchTerm, currentPage);
                }, 500);
                setSearchTimer(timer);
            } else {
                onSearch('', currentPage);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        const totalQty = calculateTotalQty();
        const qty_item_type = items[0]?.qty_item_type || 0;

        if (totalQty === qty_item_type && isUserAction.current) {
            items.forEach(item => {
                if ((!userItemQuantities[item.id] || userItemQuantities[item.id] === 0) && selectedItemsState[item.id]) {
                    handleRemoveItemClick(item);
                }
            });
            isUserAction.current = false;
        }
    }, [userItemQuantities, items, selectedItemsState]);

    const resetState = () => {
        setSelectedItemsState({});
        setUserItemQuantities({});
        setIsError(false);
        setSearchTerm('');
        setDeletedItems({});
        setFilteredItems([]);
        setSortDirection('asc');
        setIsFilled({});
    };

    const initializeState = () => {
        const initialQuantities = selectedItems.reduce((acc: { [x: number]: number }, item: { itemId: number; user_item_qty: number; }) => {
            acc[item.itemId] = item.user_item_qty;
            return acc;
        }, {});

        setUserItemQuantities({ ...initialQuantities });

        const initialSelectedItems = items.reduce((acc: { [id: number]: TItem }, item) => {
            if (selectedItems.some(selected => selected.itemId === item.id) && !deletedItems[item.id]) {
                acc[item.id] = item;
            }
            return acc;
        }, {});

        setSelectedItemsState({ ...initialSelectedItems });
        setFilteredItems(items);

        const initialIsFilled: { [id: number]: boolean } = {};
        Object.keys(initialQuantities).forEach(id => {
            if (initialQuantities[Number(id)] > 0) {
                initialIsFilled[Number(id)] = true;
            }
        });
        setIsFilled(initialIsFilled);
    };

    const handleAddItemClick = (item: TItem) => {
        setSelectedItemsState(prevState => ({ ...prevState, [item.id]: item }));
        setDeletedItems(prevDeletedItems => {
            const newDeletedItems = { ...prevDeletedItems };
            delete newDeletedItems[item.id];
            return newDeletedItems;
        });

        if (items.length === 1 && item.qty_from_store > 0) {
            setUserItemQuantities(prevState => ({
                ...prevState,
                [item.id]: item.qty_item_type
            }));
            setIsFilled(prev => ({ ...prev, [item.id]: true }));
        }
        toastItemTrue(`${item.title} added`);
    };

    const handleFillOut = (item: TItem) => {
        const qty_item_type = items[0]?.qty_item_type || 0;
        const currentTotal = calculateTotalQty();
        const currentItemQty = userItemQuantities[item.id] || 0;
        const remainingQty = qty_item_type - (currentTotal - currentItemQty);

        isUserAction.current = true;

        if (isFilled[item.id]) {
            setUserItemQuantities(prevState => ({
                ...prevState,
                [item.id]: 0
            }));
            setIsFilled(prevState => ({
                ...prevState,
                [item.id]: false
            }));
            toastItemCheckboxFalse(`The ${item.title} field has been cleared`);
        } else {
            setUserItemQuantities(prevState => ({
                ...prevState,
                [item.id]: remainingQty
            }));
            setIsFilled(prevState => ({
                ...prevState,
                [item.id]: true
            }));
            toastItemTrue(`The ${item.title} field is populated with ${remainingQty}`);
        }
    };

    const handleRemoveItemClick = (item: TItem) => {
        setDeletedItems(prevDeletedItems => ({
            ...prevDeletedItems,
            [item.id]: true
        }));

        setSelectedItemsState(prevState => {
            const newSelectedItems = { ...prevState };
            delete newSelectedItems[item.id];
            return newSelectedItems;
        });

        setUserItemQuantities(prevQuantities => {
            const newQuantities = { ...prevQuantities };
            delete newQuantities[item.id];
            return newQuantities;
        });

        setIsFilled(prevFilled => {
            const newFilled = { ...prevFilled };
            delete newFilled[item.id];
            return newFilled;
        });

        toastItemCheckboxFalse(`${item.title} removed`);
    };

    const handleQtyChange = (id: number, value: number) => {
        const qty_item_type = items[0]?.qty_item_type || 0;
        const currentQty = userItemQuantities[id] || 0;
        const totalQtyExcludingCurrent = calculateTotalQty() - currentQty;
        const availableQty = qty_item_type - totalQtyExcludingCurrent;

        isUserAction.current = true;

        if (value <= availableQty) {
            setUserItemQuantities(prevState => ({ ...prevState, [id]: value }));
            setIsFilled(prev => ({ ...prev, [id]: value > 0 }));
        } else {
            toastItemError(`Total quantity cannot exceed ${qty_item_type}`);
        }
    };

    const calculateTotalQty = () => {
        return Object.keys(userItemQuantities).reduce((total, itemId) => {
            const id = Number(itemId);
            if (!deletedItems[id]) {
                return total + (userItemQuantities[id] || 0);
            }
            return total;
        }, 0);
    };

    const handleSave = () => {
        const updatedItems = Object.values(selectedItemsState).map(item => ({
            ...item,
            user_item_qty: userItemQuantities[item.id] || 0
        }));

        const totalQty = calculateTotalQty();
        const qty_item_type = items[0]?.qty_item_type || 0;

        if (updatedItems.length === 0) {
            handleItemClick(updatedItems);
            onClose();
            return;
        }

        if (totalQty !== qty_item_type) {
            setIsError(true);
            toastItemError(`The total quantity must be equal to ${qty_item_type}. You entered: ${totalQty}`);
            setTimeout(() => setIsError(false), 3000);
            return;
        }

        handleItemClick(updatedItems);
        onClose();
    };

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };

    const sortedItems = filteredItems.sort((a, b) => {
        return sortDirection === 'asc' ? a.price - b.price : b.price - a.price;
    });

    const totalQty = calculateTotalQty();
    const qty_item_type = items[0]?.qty_item_type || 0;
    const totalPrice = Object.values(selectedItemsState).reduce((total, item) => {
        const itemPrice = parseFloat(String(item.price));
        const itemQty = userItemQuantities[item.id] || 0;

        if (!isNaN(itemPrice) && !isNaN(itemQty) && !deletedItems[item.id]) {
            return total + itemPrice * itemQty;
        }
        return total;
    }, 0).toFixed(2);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                        <p className="item-selection-modal-info-select"><strong>{filteredItems.length}</strong> products available</p>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                    <div>
                        <p className='item-selection-modal-header-price-p'>Total products selected: <span
                            className='item-selection-modal-header-price'>R {totalPrice}</span></p>
                        <div className={`item-selection-modal-header-calc-qty ${isError ? 'item-selection-modal-error' : ''}`}>
                            <p>Calculated QTY: </p>
                            <p><strong>{totalQty}</strong></p>
                            <p> <strong>{qty_item_type}</strong></p>
                        </div>
                    </div>
                </div>
                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch />
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInput}
                    />
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        <div className='item-selection-modal-sort-button' onClick={onReload}>
                            <IconReload />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => setSortDirection('desc')}>
                            <IconArrowTop />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => setSortDirection('asc')}>
                            <IconArrowBottom />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <ul className="item-selection-modal-list">
                        {sortedItems.map((item: TItem) => {
                            const isSelected = !!selectedItemsState[item.id] && !deletedItems[item.id];
                            const initialQty = userItemQuantities[item.id] || 0;
                            const quantity = item.qty_from_store;

                            return (
                                <li key={item.id} className="item-selection-modal-list-item">
                                    <section className="item-selection-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            <img
                                                className="item-selection-modal-image"
                                                src={
                                                    item.vendor === 'cashbuild'
                                                        ? item.merchant_info?.image ||
                                                        (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                        : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                }
                                                alt="Item"
                                            />

                                            <section className="item-selection-modal-list-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm)
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-description">
                                                    {item.merchant_info?.description_short ||
                                                        item.merchant_info?.description ||
                                                        item.merchant_info?.meta_description}
                                                </p>
                                                <p className="item-selection-modal-item-price">R {item.price ? Number(item.price).toFixed(2) : '0.00'}</p>
                                            </section>
                                        </div>
                                        <div className='item-selection-modal-action-block'>
                                            <div className='item-selection-modal-action-block-img'>
                                                {item.vendor ? (
                                                    <p className='item-selection-modal-vendor-img'>{item.vendor}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                                )}
                                            </div>
                                            {quantity === 0 ? (
                                                <>
                                                    <div className='item-selection-modal-quantity-input'>
                                                        <button
                                                            disabled={!isSelected}
                                                            className="item-selection-modal-qty-button minus"
                                                            onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                        </button>
                                                        <input
                                                            type="number"
                                                            value={initialQty > 0 ? initialQty : ''}
                                                            className="item-selection-modal-qty-input-field"
                                                            disabled={true}
                                                        />
                                                        <button
                                                            disabled={!isSelected}
                                                            className="item-selection-modal-qty-button plus"
                                                            onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                        </button>
                                                    </div>
                                                    <button className="item-selection-modal-button out-of-stock">
                                                        Out of stock
                                                    </button>
                                                </>
                                            ) : (
                                                <div className='item-selection-modal-quantity-input'>
                                                    <button
                                                        disabled={!isSelected}
                                                        className="item-selection-modal-qty-button minus"
                                                        onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                    </button>
                                                    <input
                                                        type="number"
                                                        value={isSelected && initialQty > 0 ? initialQty : ''}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                        }}
                                                        className="item-selection-modal-qty-input-field"
                                                        disabled={!isSelected}
                                                        min={0}
                                                    />
                                                    <button
                                                        disabled={!isSelected}
                                                        className="item-selection-modal-qty-button plus"
                                                        onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                    </button>
                                                </div>
                                            )}
                                            {items.length > 1 && (
                                                <button
                                                    onClick={() => handleFillOut(item)}
                                                    disabled={!isSelected || (!isFilled[item.id] && totalQty === qty_item_type)}
                                                    className={`item-selection-modal-button ${isSelected ? 'add-item' : 'disabled-item'}`}
                                                    style={{
                                                        backgroundColor: (!isSelected || (!isFilled[item.id] && totalQty === qty_item_type)) ? '#ccc' : "",
                                                        border: (!isSelected || (!isFilled[item.id] && totalQty === qty_item_type)) ? 'none' : ""
                                                    }}
                                                >
                                                    {isFilled[item.id] ? 'Clear' : 'Fill out'}
                                                </button>
                                            )}


                                            {isSelected && quantity !== 0 ? (
                                                <button
                                                    onClick={() => handleRemoveItemClick(item)}
                                                    className="item-selection-modal-button remove-item">Remove product
                                                </button>
                                            ) : (
                                                quantity !== 0 && (
                                                    <button
                                                        onClick={() => handleAddItemClick(item)}
                                                        disabled={totalQty === qty_item_type}
                                                        style={{
                                                            backgroundColor: (totalQty === qty_item_type) ? '#ccc' : "",
                                                            border: (totalQty === qty_item_type) ? 'none' : ""
                                                        }}
                                                        className="item-selection-modal-button add-item">Add product
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button onClick={handleSave} className="item-selection-modal-save-button">Confirm selection</button>
                </div>
            </section>
        </Modal>
    );
};

export default ItemSelectionModal;

import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-modal';
import { TItem } from "../../../types/TemplateType";
import './ItemTypeBomModal.css';
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemTrue
} from "../../../components/Toast/AssisCreateToastNotification";
import { IconArrowBottom, IconArrowTop, IconReload, IconSearch } from "../../../IconComponents/IconComponents";
import { Loader } from "../../../components/Loader/Loader";
import PaginationInModal from "../../../components/PaginationAssistance/paginationInModal";
import { toast } from "react-toastify";

interface AllowedItem extends TItem {
    qty: number;
    source: 'add' | 'checkbox';
}

interface ItemsBomModalProps {
    isOpen: boolean;
    onClose: () => void;
    items: TItem[];
    loading: boolean;
    onReload?: () => void;
    currentPage: number;
    totalItems: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
    onSearch?: (query: string) => void;
    onSortChange?: (direction: 'asc' | 'desc') => void;
    sortDirection?: 'asc' | 'desc';
    totalSearchResults?: number;
    onSelectItems: (selectedItems: AllowedItem[]) => void;
    itemTypeQty: number;
    initialSelectedItems?: TItem[];
}

const ItemsBomModal: React.FC<ItemsBomModalProps> = ({
                                                         isOpen,
                                                         onClose,
                                                         items = [],
                                                         loading,
                                                         onReload,
                                                         currentPage,
                                                         totalItems,
                                                         itemsPerPage,
                                                         onPageChange,
                                                         onSearch,
                                                         onSortChange,
                                                         totalSearchResults,
                                                         onSelectItems,
                                                         itemTypeQty,
                                                         initialSelectedItems = []
                                                     }) => {
    const [allowedItems, setAllowedItems] = useState<AllowedItem[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    const [isDefaultAdded, setIsDefaultAdded] = useState<boolean>(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';

            const initialAllowed = initialSelectedItems.map(item => ({
                ...item,
                source: item.source === 'add' || item.source === 'checkbox' ? item.source : 'add' as 'add' | 'checkbox'
            }));
            setAllowedItems(initialAllowed);

            const hasDefault = initialAllowed.some(item => item.source === 'add');
            setIsDefaultAdded(hasDefault);
        } else {
            document.body.style.overflow = '';
            resetState();
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    const resetState = () => {
        setAllowedItems([]);
        setSearchTerm('');
    };

    const handleConfirmSelection = () => {
        if (!allowedItems.find(item => item.source === 'add')) {
            toast.error(`You must select a default product`);
            return;
        }

        const selectedItems = allowedItems.map(item => ({
            ...item,
            qty: itemTypeQty
        }));
        onSelectItems(selectedItems);
        onClose();
    };

    const handleAddItemClick = (item: TItem) => {
        setAllowedItems(prev => [...prev, { ...item, qty: 1, source: 'add' }]);
        setIsDefaultAdded(true);
        toastItemTrue(`${item.title} added`);
    };

    const handleRemoveItemClick = (item: TItem) => {
        const updatedItems = allowedItems.filter(allowedItem => allowedItem.id !== item.id);
        setAllowedItems(updatedItems);

        const hasDefault = updatedItems.some(allowedItem => allowedItem.source === 'add');
        setIsDefaultAdded(hasDefault);

        toastItemCheckboxFalse(`${item.title} removed`);

        if (updatedItems.length === 0) {
            onSelectItems([]);
        }
    };

    const handleCheckboxChange = (item: TItem, checked: boolean) => {
        if (!isDefaultAdded) {
            toast.error("You must add a default product before adding allowed products.");
            return;
        }

        if (checked) {
            const isAlreadyAdded = allowedItems.some(allowedItem => allowedItem.id === item.id);
            if (isAlreadyAdded) {
                toast.error("This item is already selected.");
                return;
            }
            setAllowedItems(prev => [...prev, { ...item, qty: 1, source: 'checkbox' }]);
            toastItemTrue(`${item.title} added allowed product`);
        } else {
            setAllowedItems(prev => prev.filter(allowedItem => allowedItem.id !== item.id));
            toastItemCheckboxFalse(`${item.title} removed allowed product`);
        }
    };

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchTerm(query);
        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        const timer = setTimeout(() => {
            if (onSearch) {
                onSearch(query);
            }
        }, 500);
        setSearchTimer(timer);
    };

    const highlightMatch = (text: string, match: string) => {
        if (!match) return text;
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="item-selection-modal-highlight">${matched}</span>`);
    };

    const calculateTotalPrice = () => {
        return allowedItems.reduce((total, item) => {
            const itemPrice = parseFloat(String(item.price));
            if (item.source === 'add' && !isNaN(itemPrice)) {
                return total + itemPrice * item.qty;
            }
            return total;
        }, 0).toFixed(2);
    };

    const totalPrice = calculateTotalPrice();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="item-selection-modal-content"
            overlayClassName="item-selection-modal-overlay"
            ariaHideApp={false}
        >
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                    </div>
                    <button className="item-selection-modal-close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                    <div>
                        <p className='item-selection-modal-header-price-p'>
                            Total products selected:  <span className='item-selection-modal-header-price'>R {totalPrice}</span>
                        </p>
                    </div>
                </div>
                <div className='item-selection-modal-search'>
                    <div className='item-selection-modal-search-icon'>
                        <IconSearch />
                    </div>
                    <input
                        className='item-selection-modal-search-input'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <div className='item-selection-modal-sort-icons'>
                    <div className='item-selection-modal-sort-buttons'>
                        <div className='item-selection-modal-sort-button' onClick={onReload}>
                            <IconReload />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => onSortChange && onSortChange('desc')}>
                            <IconArrowTop />
                        </div>
                        <div className='item-selection-modal-sort-button' onClick={() => onSortChange && onSortChange('asc')}>
                            <IconArrowBottom />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <ul className="item-selection-modal-list">
                        {items.map((item: TItem) => {
                            const allowedItem = allowedItems.find(ai => ai.id === item.id);
                            const isAllowed = !!allowedItem;
                            const isAddedViaAdd = allowedItem?.source === 'add';

                            return (
                                <li key={item.id} className="item-selection-modal-list-item">
                                    <section className="item-type-bom-modal-list-item-content">
                                        <div className="item-selection-modal-list-item-image">
                                            <img
                                                className="item-selection-modal-image"
                                                src={
                                                    item.vendor === 'cashbuild'
                                                        ? item.merchant_info?.image ||
                                                        (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                        : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                        '/img_vendor/item.png'
                                                }
                                                alt="Item"
                                            />
                                            <section className="item-selection-modal-list-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm)
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-description">
                                                    {item.merchant_info?.description_short ||
                                                        item.merchant_info?.description ||
                                                        item.merchant_info?.meta_description}
                                                </p>
                                                <p className="item-selection-modal-item-price">R {item.price ? Number(item.price).toFixed(2) : '0.00'}</p>
                                            </section>
                                        </div>

                                        <div className='item-type-bom-modal-action-block'>
                                            <div className='item-selection-modal-action-block-img'>
                                                {item.vendor ? (
                                                    <p className='item-selection-modal-vendor-img'>{item.vendor}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No vendors</p>
                                                )}
                                            </div>

                                            <button
                                                type="button"
                                                onClick={() => !isAddedViaAdd && handleCheckboxChange(item, !isAllowed)}
                                                disabled={!isDefaultAdded}
                                                className={`item-selection-modal-button ${!isDefaultAdded ? 'disabled-button' : isAllowed ? 'remove-item' : 'add-item'}`}
                                            >
                                                {isAddedViaAdd ? 'Selected automatically' : isAllowed ? 'Remove allowed' : 'Select allowed'}
                                            </button>

                                            <div className='item-selection-modal-action-buttons'>
                                                {isAllowed && isAddedViaAdd ? (
                                                    <button
                                                        onClick={() => handleRemoveItemClick(item)}
                                                        className="item-selection-modal-button remove-item"
                                                    >
                                                        Remove default
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() => handleAddItemClick(item)}
                                                        className="item-selection-modal-button add-item"
                                                        style={{
                                                            cursor: isDefaultAdded ? 'not-allowed' : 'pointer'
                                                        }}
                                                        disabled={isDefaultAdded}
                                                    >
                                                        {isDefaultAdded ? 'Only one default' : 'Add default'}
                                                    </button>
                                                )}
                                            </div>

                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults || 0 : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button
                        onClick={handleConfirmSelection}
                        className={`item-selection-modal-save-button ${!allowedItems.find(item => item.source === 'add') ? 'save-added-check' : ''}`}
                    >
                        Confirm selection
                    </button>
                </div>
            </section>
        </Modal>
    );
}

export default ItemsBomModal;

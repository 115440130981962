import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css';

const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const toggleVisibility = () => {
        const scrolled = window.scrollY;
        const threshold = 1.25 * window.innerHeight;
        if (scrolled >= threshold) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        isVisible ? (
            <button
                className={`scroll-to-top ${isVisible ? 'show' : ''}`}
                onClick={scrollToTop}
                aria-label="Top"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                    <path d="M12 4l-8 8h5v8h6v-8h5z"/>
                </svg>
            </button>
        ) : null
    );
};

export default ScrollToTopButton;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';

interface AuthState {
    isAuthenticated: boolean;
    hasBeenAuthenticated: boolean;
}

const initialState: AuthState = {
    isAuthenticated: !!(sessionStorage.getItem('Bearer') || localStorage.getItem('Bearer')) && !!localStorage.getItem('role'),
    hasBeenAuthenticated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setHasBeenAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.hasBeenAuthenticated = action.payload;
        },
        checkAuthentication: (state) => {
            const bearerToken = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
            const role = localStorage.getItem('role');
            state.isAuthenticated = !!bearerToken && !!role;
        },
    },
});

export const { setAuthenticated, checkAuthentication, setHasBeenAuthenticated } = authSlice.actions;

export const handleTokenRemoval = () => (dispatch: AppDispatch) => {
    sessionStorage.removeItem('Bearer');
    localStorage.removeItem('Bearer');
    localStorage.removeItem('role');
    dispatch(setAuthenticated(false));
    dispatch(setHasBeenAuthenticated(true));
};

export default authSlice.reducer;

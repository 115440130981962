import {
    useMatch,
    Link,
} from 'react-router-dom';
import './style.css'


interface ILink{
    to:string,
    children:string
}
function CustomNavLink({ to, children }:ILink) {
    let match = useMatch(to);
    return (
        <li className='list-wrapper-list'>
            <Link className={match ? 'list-rout active' : 'list-rout'} to={to}>{children}</Link>
        </li>
    );
}



function HeaderList():any{
    return(
            <div className='list-wrapper'>
                <CustomNavLink to='/projects'>Projects group</CustomNavLink>
                <CustomNavLink to='/team'>Team management</CustomNavLink>
                <CustomNavLink to='/order_history'>Order history</CustomNavLink>
            </div>
        )
}
export default HeaderList
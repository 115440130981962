import React from 'react';
import './stylePaginationModal.css';

interface PaginationProps {
    perPage: number;
    total?: number;
    paginate: (pageNumber: number) => void;
    currentPage: number;
}

const PaginationInModal: React.FC<PaginationProps> = ({ perPage, total, paginate, currentPage }) => {
    const totalPages = Math.ceil(total ? total : 1 / perPage);
    const maxPagesToShow = 3;
    const pageNumbers: number[] = [];
    let startPage: number, endPage: number;

    if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
    } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;

        if (currentPage <= maxPagesBeforeCurrentPage) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    return (
        <nav>
            <ul className='pagination-modal'>
                {currentPage > 1 && (
                    <li className='page-item-modal'>
                        <a onClick={() => paginate(currentPage - 1)} className='page-link-arrow-modal modal-prev'>
                            {'<'}
                        </a>
                    </li>
                )}
                {currentPage > 3 && (
                    <li className='page-item-modal'>
                        <a onClick={() => paginate(1)} className='page-link-modal'>1...</a>
                    </li>
                )}

                {pageNumbers.map(number => (
                    <li key={number} className={`page-item-modal ${number === currentPage ? 'active' : ''}`}>
                        <a onClick={() => paginate(number)} className='page-link-modal'>
                            {number}
                        </a>
                    </li>
                ))}
                {currentPage < totalPages && totalPages > maxPagesToShow && (
                    <li className='page-item-modal'>
                        <a onClick={() => paginate(currentPage + 1)} className='page-link-arrow-modal modal-next'>
                            {'>'}
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default PaginationInModal;

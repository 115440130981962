import React, {useEffect, useState} from 'react';
import './ShowMoreListStep2.css'
interface ShowMoreListProps {
    items: React.ReactNode[];
    initialVisibleCount: number;
    showAll: boolean;
}

const ShowMoreList: React.FC<ShowMoreListProps> = ({ items, initialVisibleCount, showAll }) => {
    const visibleItems = showAll ? items : items.slice(0, initialVisibleCount);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="show-more-list">
            {visibleItems.map((item, index) => (
                <div key={index} className="show-more-list-item">
                    {item}
                </div>
            ))}
        </div>
    );
};

export default ShowMoreList;

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Step2Bom.css';
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import { getProjectBom, postBomEditItems } from "../../api/APIWraper";
import 'react-toastify/dist/ReactToastify.css';
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import { BOMItem, BOMItemType, BOMProject } from "../../types/ProjectBOMInterface";
import { IconSettingsStep2Products } from "../../IconComponents/IconComponents";
import { toast } from "react-toastify";
import { notifyWarningOrderTemplate } from "../../components/Toast/AssisCreateToastNotification";
import SelectItemModal from "./ModalItemsBom";

const Step2Bom = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [projectBom, setProjectBom] = useState<BOMProject | null>(null);
    const [replace, setReplace] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<{ [key: string]: any }>({});
    const [selectedItemsStep3, setSelectedItemsStep3] = useState<{ [key: string]: BOMItemType }>({});
    const [buttonSelectDisabled, setButtonSelectDisabled] = useState<boolean>(false);
    const { link } = useParams<{ link: string }>();
    const [totalPrice, setTotalPrice] = useState<number>(0);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentUniqueKey, setCurrentUniqueKey] = useState<string | null>(null);
    const [allowedItemsForModal, setAllowedItemsForModal] = useState<any[]>([]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (projectBom) {
            const total = projectBom.data.item_types.reduce((acc, itemType) => {
                const itemPrice = parseFloat(itemType.item.price);
                const itemTotal = itemType.qty * itemPrice;
                return acc + itemTotal;
            }, 0);

            setTotalPrice(total);
        }
    }, [projectBom]);

    useEffect(() => {
        const storedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        const storedItemsMap = storedItems.reduce((acc: { [key: string]: BOMItemType }, item: BOMItemType) => {
            const uniqueKey = `${item.id}-${item.index}`;
            acc[uniqueKey] = item;
            return acc;
        }, {});
        setSelectedItemsStep3(storedItemsMap);
    }, []);

    useEffect(() => {
        if (link) {
            const fetchProject = async () => {
                try {
                    const response: BOMProject = await getProjectBom(link);
                    const itemTypesWithUniqueKey: (BOMItemType & { uniqueKey: string })[] = response.data.item_types.map((itemType, index) => ({
                        ...itemType,
                        uniqueKey: `${itemType.id}-${index}`
                    }));
                    setProjectBom({ ...response, data: { ...response.data, item_types: itemTypesWithUniqueKey } });
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, [link]);

    useEffect(() => {
        if (!replace) {
            setSelectedItems({});
        }
    }, [replace]);

    useEffect(() => {
        const storedSelectedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '{}');
        if (storedSelectedItems.length === 0) {
            setButtonSelectDisabled(false);
        } else {
            setButtonSelectDisabled(true);
        }
    }, [selectedItemsStep3, projectBom]);

    const navigateToHome = () => {
        clearLocalStorageExceptToken();
        navigate('/home');
    };

    const onToggle = () => {
        setReplace(prevState => !prevState);
        setIsModalOpen(false);
        setCurrentUniqueKey(null);
    };

    const handleSelectItem = useCallback((selectedItem: any) => {
        if (!currentUniqueKey) return;
        setSelectedItems(prev => ({
            ...prev,
            [currentUniqueKey]: selectedItem
        }));
        setIsModalOpen(false);
        setCurrentUniqueKey(null);
    }, [currentUniqueKey]);

    const handleSave = async () => {
        if (!projectBom) return;

        const changedItems = projectBom.data.item_types
            .map((itemType, index) => ({ itemType, index }))
            .filter(({ itemType, index }) => selectedItems[itemType.uniqueKey])
            .map(({ itemType, index }) => ({
                id: itemType.id,
                item: selectedItems[itemType.uniqueKey].id,
                index: index,
                qty_ordered: 0
            }));

        if (changedItems.length === 0) {
            toast.info('There are no changes to save.');
            return;
        }

        const body = {
            item_types: changedItems
        };

        try {
            const { bomProject, status } = await postBomEditItems(body, link);

            if (status === 200) {
                const itemTypesWithUniqueKey: (BOMItemType & { uniqueKey: string })[] = bomProject.data.item_types.map((itemType, index) => ({
                    ...itemType,
                    uniqueKey: `${itemType.id}-${index}`
                }));
                setReplace(false)

                setProjectBom({
                    ...projectBom,
                    data: {
                        ...projectBom.data,
                        ...bomProject,
                        item_types: itemTypesWithUniqueKey
                    }
                });
                setSelectedItems({});
                toast.success('You have successfully replaced the products');
            } else {
                toast.error('Failed to replace products');
            }
        } catch (error) {
            console.error("Error when saving changes:", error);
            toast.error('An error occurred while saving changes');
        }
    };

    const handleToggleSelect = (uniqueKey: string, itemType: BOMItemType, index: number) => {
        setSelectedItemsStep3(prevSelectedItems => {
            let updatedItemsArray;
            let updatedSelectedItems;

            if (prevSelectedItems[uniqueKey]) {
                const { [uniqueKey]: _, ...rest } = prevSelectedItems;
                updatedSelectedItems = rest;
                updatedItemsArray = Object.values(rest);
            } else {
                updatedSelectedItems = { ...prevSelectedItems, [uniqueKey]: { ...itemType, index } };
                updatedItemsArray = [...Object.values(prevSelectedItems), { ...itemType, index }];
            }

            localStorage.setItem('selectedItemTypes', JSON.stringify(updatedItemsArray));
            return updatedSelectedItems;
        });
    };

    if (!projectBom) {
        return null;
    }

    const handleOrderSelected = () => {
        const storedSelectedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '{}');
        if (storedSelectedItems.length === 0) {
            notifyWarningOrderTemplate('You must choose at least one product');
            return;
        }
        navigate(`/create/step3Bom`, { state: { link: link } });
    };

    const handleOrderProject = () => {
        setSelectedItemsStep3(prevSelectedItems => {
            const storedItems: BOMItemType[] = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');

            const storedItemsMap = storedItems.reduce((acc: { [key: string]: BOMItemType }, item) => {
                const uniqueKey = `${item.id}-${item.index}`;
                acc[uniqueKey] = item;
                return acc;
            }, {});

            const newItems = projectBom.data.item_types.filter((itemType, index) => {
                const uniqueKey = `${itemType.id}-${index}`;
                return !storedItemsMap[uniqueKey];
            });

            const updatedItemsArray = [
                ...storedItems,
                ...newItems.map((itemType, index) => ({ ...itemType, index }))
            ];

            localStorage.setItem('selectedItemTypes', JSON.stringify(updatedItemsArray));

            return {
                ...prevSelectedItems,
                ...newItems.reduce((acc, item, index) => {
                    const uniqueKey = `${item.id}-${index}`;
                    acc[uniqueKey] = { ...item, index };
                    return acc;
                }, {} as { [key: string]: BOMItemType }),
            };
        });
        setTimeout(() => {
            navigate(`/create/step3Bom`, { state: { link: link } });
        }, 100);
    };

    const openModal = (uniqueKey: string, allowedItems: any[]) => {
        setCurrentUniqueKey(uniqueKey);
        setAllowedItemsForModal(allowedItems);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentUniqueKey(null);
        setAllowedItemsForModal([]);
    };

    return (
        <div className='step2'>
            <div>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            { to: '/', label: 'Cashbuild' },
                            { to: '', label: '>' },
                            { to: '/', label: 'Home' },
                            { to: '', label: '>' },
                            { to: '/home', label: 'Project assistance' },
                            { to: '', label: '>' },
                            { to: '', label: 'Create new project space' }
                        ]}
                    />
                ) : (
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToHome} className='template-link-assistance'>Project group assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                )}
                <div className='step-info'>
                    <div className='step-info-block'>
                        <h3>Step 2</h3>
                        <span>/</span>
                        <p className='step-info-block-name'>Specify parameters of building</p>
                    </div>
                </div>
                <section className='step2-bom-container'>
                    <div className='step2-bom-cost-summary'>
                        <p className='cost-summary-bom'>Cost summary</p>
                    </div>

                    <div className='step2-content-block'>
                        <div className='step2-bom-bom-project-title'>
                            <p className='step2-bom-title'>Bill of material's project title</p>
                            <div onClick={onToggle} className='material-setting-block'>
                                <div><IconSettingsStep2Products /></div>
                                <p>Select Products</p>
                            </div>
                        </div>

                        <div className='step2-bom-header-info'>
                            <p className='step2-bom-item-title-checkbox-info'>Product</p>
                            <p className='step2-bom-qty-ordered-info'>QTY ordered</p>
                            <p className='step2-bom-calculated-qty-info'>Calculated QTY</p>
                            <p className='step2-bom-price-info'>Unit price</p>
                            <p className='step2-bom-total-info'>Total</p>
                            <p className='step2-bom-total-orderd-info'>Total ordered</p>
                        </div>
                        {!replace ? (

                            <div>
                                {projectBom.data.item_types.map((itemType, index) => {
                                    const uniqueKey = `${itemType.id}-${index}`;
                                    const isFixed = itemType.allowed_items.length === 1;
                                    const displayItem: BOMItem = isFixed
                                        ? itemType.item
                                        : (selectedItems[uniqueKey] || itemType.item);
                                    const qty_ordered = selectedItems[uniqueKey] ? 0 : (itemType.item.qty_ordered || 0);

                                    return (
                                        <div key={uniqueKey} className='step2-bom-items'>
                                            <div className='step2-bom-item-title-checkbox'>
                                                <input
                                                    type='checkbox'
                                                    className='step2-bom-input-checkbox'
                                                    checked={!!selectedItemsStep3[uniqueKey]}
                                                    onChange={() => handleToggleSelect(uniqueKey, itemType, index)}
                                                />
                                                <p className='step2-bom-item-title'>{displayItem.title}</p>
                                            </div>
                                            <p className='step2-bom-calculated-qty'>{qty_ordered}</p>
                                            <p className='step2-bom-qty-ordered'>{itemType.qty}</p>
                                            <p className='step2-bom-price'>R {parseFloat(displayItem.price).toFixed(2)}</p>
                                            <p className='step2-bom-total'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                            <p className='step2-bom-total-orderd'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                        </div>
                                    )
                                })}
                            </div>

                        ) : (
                            <div>

                                {projectBom.data.item_types.map((itemType, index) => {
                                    const uniqueKey = `${itemType.id}-${index}`;
                                    const isFixed = itemType.allowed_items.length === 1;
                                    const displayItem = isFixed
                                        ? itemType.item
                                        : (selectedItems[uniqueKey] || itemType.item);
                                    const qty_ordered = selectedItems[uniqueKey] ? 0 : (itemType.item.qty_ordered || 0);

                                    return (
                                        <div key={uniqueKey} className='step2-bom-items-container'>
                                            <div className='step2-bom-items'>
                                                <div
                                                    className='step2-bom-item-title-replace'
                                                    onClick={() => {
                                                        if (!isFixed && itemType.allowed_items.length > 1) {
                                                            openModal(uniqueKey, itemType.allowed_items);
                                                        }
                                                    }}
                                                >
                                                    <p
                                                        title={itemType.allowed_items.length === 1 ? 'No replacement products available' : ''}
                                                        className='step2-bom-item-title'>{displayItem.title}</p>
                                                    {!isFixed && itemType.allowed_items.length > 1 && (
                                                        <span
                                                            className={`arrow-right`}></span>
                                                    )}
                                                </div>
                                                <p className='step2-bom-calculated-qty'>{qty_ordered}</p>
                                                <p className='step2-bom-qty-ordered'>{itemType.qty}</p>
                                                <p className='step2-bom-price'>R {parseFloat(displayItem.price).toFixed(2)}</p>
                                                <p className='step2-bom-total'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                                <p className='step2-bom-total-orderd'>R {(parseFloat(displayItem.price) * itemType.qty).toFixed(2)}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                    </div>

                </section>
                {replace && (
                    <div className='save-button-step2bom-block'>
                        <button onClick={handleSave} className='save-button-step2bom'>Save</button>
                    </div>
                )}
                <div className='step2-bom-total-price-section'>
                    <div className='step2-bom-total-price-block'>
                        <p className='step2-bom-total-price-total'>Total:</p>
                        <p className='step2-bom-total-price-price'>R {totalPrice.toFixed(2)}</p>
                    </div>
                </div>

            </div>

            <div className='step2-page-bottom-btn'>
                <div className='step2-page-bottom-btn-block'>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleOrderSelected}
                            style={{
                                backgroundColor: !buttonSelectDisabled ? '#cccccc' : '',
                                cursor: !buttonSelectDisabled ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Order selected
                        </button>
                    </div>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleOrderProject}
                        >
                            Order Project
                        </button>

                    </div>
                    <div className='step2-page-btn'>
                        <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                    </div>
                </div>
            </div>

            <SelectItemModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                items={allowedItemsForModal}
                onSelect={handleSelectItem}
            />
        </div>
    );
}

export default Step2Bom;

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './Step3SocketModal.css';
import { TItem } from "../../../types/TemplateType";
import { connect, ConnectedProps } from "react-redux";
import io, { Socket } from "socket.io-client";
import {
    clearMessages,
    connectionError,
    messageReceived,
    websocketConnect,
    websocketDisconnect
} from "../../../Slice/WebSocket/WebSocketSlice";
import { RootState } from "../../../store";
import {socket} from "../../../App";

Modal.setAppElement('#root');

interface SelectItemModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

const SOCKET_SERVER_URL = process.env.REACT_APP_BASE_SOCKET_LINK;

const Step3SocketModal: React.FC<SelectItemModalProps & PropsFromRedux> = ({
                                                                               isOpen,
                                                                               onRequestClose,
                                                                               websocketConnect,
                                                                               websocketDisconnect,
                                                                               messageReceived,
                                                                               connectionError,
                                                                               websocketMessages,
                                                                               clearMessages
                                                                           }) => {
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);
    const [selectedVendor, setSelectedVendor] = useState<VendorKey | null>(null);
    const userId = localStorage.getItem('userId');
    const ItemVendors = JSON.parse(localStorage.getItem('ItemVendors') || '[]');

    useEffect(() => {
        websocketConnect();

        socket.on('connect', () => {
            console.log('conect')
        });

        socket.on('disconnect', () => {
            websocketDisconnect();
        });

        ItemVendors.forEach((vendor: string) => {
            socket.on(`${vendor} ${userId}`, (data: any) => {
                console.log(data)
                messageReceived(data);
            });
        });

        socket.on('connect_error', (err: any) => {
            connectionError(err.message);
        });

        return () => {
            ItemVendors.forEach((vendor: string) => {
                socket.off(`${vendor} ${userId}`);
            });
            socket.disconnect();
            websocketDisconnect();
        };
    }, [websocketConnect, websocketDisconnect, messageReceived, connectionError, userId]);


    type VendorKey = "builderscoza" | "leroymerlincoza" | "homedepotca" | "homedepotusa" | "houzzusa" | "lowesusa";

    const vendorData: Record<VendorKey, { displayName: string; url: string }> = {
        builderscoza: { displayName: "Builders", url: "https://www.builders.co.za/" },
        leroymerlincoza: { displayName: "Leroy Merlin", url: "https://leroymerlin.co.za/" },
        homedepotca: { displayName: "Home Depot CA", url: "https://www.homedepot.ca" },
        homedepotusa: { displayName: "Home Depot USA", url: "https://www.homedepot.com" },
        houzzusa: { displayName: "Houzz USA", url: "https://www.houzz.com" },
        lowesusa: { displayName: "Lowes USA", url: "https://www.lowes.com/" }
    };

    const allItems: TItem[] = websocketMessages.flatMap(msg => msg.data.map(entry => entry.item));

    const itemStatusMap = websocketMessages.flatMap(msg =>
        msg.data.map(entry => ({
            id: entry.item.id,
            vendor: entry.item.vendor,
            success: !!entry.message
        }))
    );

    const groupedItems = allItems.reduce((acc, item) => {
        if (item.vendor) {
            if (!acc[item.vendor]) {
                acc[item.vendor] = [];
            }
            acc[item.vendor].push(item);
        }
        return acc;
    }, {} as Record<string, TItem[]>);

    const vendors = Object.keys(groupedItems) as VendorKey[];

    const [selectedVendors, setSelectedVendors] = useState<VendorKey[]>([vendors[0]]);

    const handleVendorToggle = (vendor: VendorKey) => {
        setSelectedVendors((prev) =>
            prev.includes(vendor) ? prev.filter((v) => v !== vendor) : [...prev, vendor]
        );
    };

    const handleGoToStoreClick = (vendor: VendorKey) => {
        setSelectedVendor(vendor);
        setConfirmationModalIsOpen(true);
    };

    const handleConfirmGoToStore = () => {
        if (selectedVendor) {
            const url = vendorData[selectedVendor].url;
            window.open(url, "_blank");

            localStorage.setItem(`visited_${selectedVendor}`, "true");

            setConfirmationModalIsOpen(false);

            const allVisited = vendors.every(vendor => localStorage.getItem(`visited_${vendor}`) === "true");
            if (allVisited) {
                clearMessages();
                onRequestClose();
            }
        }
    };

    const hasVisitedVendor = (vendor: VendorKey) => {
        return localStorage.getItem(`visited_${vendor}`) === "true";
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Select Allowed Item"
                className="step3-socket-modal"
                overlayClassName="step3-socket-modal-overlay"
            >
                <div className="step3-socket-modal-header">
                    <h2>Please, check your products</h2>
                    <button onClick={onRequestClose} className="step3-socket-modal-close-button">X</button>
                </div>

                <div className='step3-socket-modal-header-content-text'>
                    <div className='step3-socket-modal-vendor-buttons'>
                        {vendors.map((vendor) => (
                            <button
                                key={vendor}
                                title={`Sorted by ${vendorData[vendor].displayName}`}
                                className={`step3-socket-modal-vendor-button ${selectedVendors.includes(vendor) ? 'selected' : ''}`}
                                onClick={() => handleVendorToggle(vendor)}
                            >
                                {vendorData[vendor].displayName}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="step3-socket-modal-content">
                    {vendors
                        .filter(vendor => selectedVendors.includes(vendor))
                        .map((vendor) => (
                            <div key={vendor}>
                                <div className='step3-socket-modal-store-header'>
                                    <h3 className='step3-socket-modal-vendor'>{vendorData[vendor].displayName}</h3>
                                    {!hasVisitedVendor(vendor) && (
                                        <button onClick={() => handleGoToStoreClick(vendor)}
                                                className='step3-socket-modal-store-btn'>Go to store
                                        </button>
                                    )}
                                </div>
                                {groupedItems[vendor].map((item: TItem) => {
                                    const itemStatus = itemStatusMap.find(status => status.id === item.id);
                                    const isAvailable = itemStatus ? itemStatus.success : false;

                                    return (
                                        <div
                                            key={item.id}
                                            className='step3-socket-modal-item'
                                        >
                                            <div className='step3-socket-modal-item-block'>
                                                <img
                                                    className="step3-socket-modal-item-img"
                                                    src={
                                                        item.vendor === 'cashbuild'
                                                            ? item.merchant_info?.image ||
                                                            (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                            '/img_vendor/item.png'
                                                            : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                                            '/img_vendor/item.png'
                                                    }
                                                    alt="Item"
                                                />
                                                <p className='step3-socket-modal-item-title'>{item.title}</p>
                                            </div>
                                            <p className='step3-socket-modal-item-price'>R {Number(item.price).toFixed(2)}</p>
                                            <div className='step3-socket-modal-qty-block'>
                                                {isAvailable ? (
                                                    <p className='step3-socket-modal-item-qty'>R {item.qty ? item.qty : 0}</p>
                                                ) : (
                                                    <p className='step3-socket-modal-item-unavailable'>Out of Stock</p>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                </div>
            </Modal>

            <Modal
                isOpen={confirmationModalIsOpen}
                onRequestClose={() => setConfirmationModalIsOpen(false)}
                contentLabel="Confirmation"
                className="step3-confirmation-modal"
                overlayClassName="step3-confirmation-modal-overlay"
            >
                <div className="step3-confirmation-modal-content">
                    <p>
                        Are you sure you want to go to the{" "}
                        <span className="vendor-name">{selectedVendor && vendorData[selectedVendor].displayName}</span>{" "}
                        store now? Your cart will be formed only once, if you go there now you will not be able to return there again later.
                    </p>
                    <div className="step3-confirmation-modal-buttons">
                        <button onClick={() => setConfirmationModalIsOpen(false)} className="cancel-button">
                            Cancel
                        </button>
                        <button onClick={handleConfirmGoToStore} className="continue-button">
                            Continue
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    isConnected: state.websocket.isConnected,
    websocketMessages: state.websocket.messages,
    websocketError: state.websocket.error,
});

const mapDispatchToProps = {
    websocketConnect,
    websocketDisconnect,
    messageReceived,
    connectionError,
    clearMessages
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step3SocketModal);

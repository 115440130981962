import './ImageUpload.css';
import { IconRemoveImage } from "../../IconComponents/IconComponents";
import { TMedia } from "../../types/TMedia";
import { BOMProject } from "../../types/ProjectBOMInterface";
import React, { ChangeEvent } from 'react';

interface ImageUploadProps {
    imageFile: File | null;
    setImageFile: React.Dispatch<React.SetStateAction<File | null>>;
    imagePreview: TMedia | string | null;
    setImagePreview: React.Dispatch<React.SetStateAction<TMedia | string | null>>;
    disabled?: boolean;
    getProject?: BOMProject | null;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
                                                     setImageFile,
                                                     imagePreview,
                                                     setImagePreview,
                                                     disabled,
                                                     getProject
                                                 }) => {
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);

        if (files.length > 0) {
            const file = files[0];

            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));

            setTimeout(() => {
                event.target.value = '';
            }, 100);
        }
    };


    const removeImage = () => {
        setImageFile(null);
        setImagePreview(null);
    };

    const existingImages = getProject?.galleries?.flatMap(gallery => gallery.medias) || [];

    return (
        <section className="image-upload-container">
            {existingImages.length > 0 && !imagePreview ? (
                <div className="existing-image-preview">
                    {existingImages.map((image, index) => (
                        <div key={index} className="image-preview-container">
                            <img
                                src={image.url}
                                alt={`Project image ${index + 1}`}
                                className="image-preview"
                            />
                            {!disabled && (
                                <button
                                    className="remove-image-button"
                                    onClick={removeImage}
                                >
                                    <IconRemoveImage />
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            ) : imagePreview ? (
                <div className="image-preview-container single-image">
                    <img
                        src={typeof imagePreview === "string" ? imagePreview : imagePreview.url}
                        alt={`Preview`}
                        className="image-preview"
                    />
                    {!disabled && (
                        <button
                            className="remove-image-button"
                            onClick={removeImage}
                        >
                            <IconRemoveImage />
                        </button>
                    )}
                </div>
            ) : (
                <div className="custom-modal-content">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="custom-modal-input"
                        id="imageUpload"
                        disabled={disabled}
                    />
                    <label htmlFor="imageUpload" className="file-upload-container">
                        Add project image
                    </label>
                </div>
            )}
        </section>
    );
};

export default ImageUpload;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProjectById, updateProject as updateProjectAPI, updateProject } from '../../api/APIWraper';
import { Project } from "../../types/ProjectTypes";
import { Template } from "../../types/TemplateType";

interface ProjectByIdState {
    templates: Template[];
    oldProject: Project | null;
    project: Project | null;
    isFetching: boolean;
    error: string | null;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc' | null;
}

const initialState: ProjectByIdState = {
    templates: [],
    project: null,
    oldProject: null,
    isFetching: false,
    error: null,
    sortBy: null,
    sortOrder: null,
};

export const fetchProjectById = createAsyncThunk<{ project: Project }, { projectId: string, sortBy?: string; sortOrder?: 'asc' | 'desc' }>(
    'projectById/fetchProjectById',
    async ({ projectId, sortBy, sortOrder }) => {
        const response = await getProjectById(projectId, sortBy, sortOrder);
        return { project: response };
    }
);

export const updateProjectTitle = createAsyncThunk<Project, { projectId: number, title?: string, target_date?: string }>(
    'projectById/updateProjectTitle',
    async ({ projectId, title, target_date }) => {
        return await updateProject(projectId, { title, target_date });
    }
);

export const updatedProject = createAsyncThunk<Project, { projectId: number, projectData: any }>(
    'projectById/updateProject',
    async ({ projectId, projectData }) => {
        const response = await updateProjectAPI(projectId, projectData);
        return response.data;
    }
);

const projectByIdSlice = createSlice({
    name: 'projectById',
    initialState,
    reducers: {
        setProjectTitle(state, action: PayloadAction<{ projectId: number, title?: string, target_date?: string }>) {
            if (state.project && state.project.id === action.payload.projectId) {
                if (action.payload.title) state.project.title = action.payload.title;
                if (action.payload.target_date) state.project.target_date = action.payload.target_date;
            }
        },
        setOldProject(state, action: PayloadAction<Project>) {
            state.oldProject = action.payload;
        },
        clearProject(state) {
            state.project = null;
            state.templates = [];
            state.oldProject = null;
            state.isFetching = false;
            state.error = null;
            state.sortBy = null;
            state.sortOrder = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectById.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchProjectById.fulfilled, (state, action: PayloadAction<{ project: Project }>) => {
                state.isFetching = false;
                state.project = action.payload.project;
                if (!state.oldProject) {
                    state.oldProject = action.payload.project;
                }
            })
            .addCase(fetchProjectById.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateProjectTitle.fulfilled, (state, action: PayloadAction<Project>) => {
                if (state.project && state.project.id === action.payload.id) {
                    state.project.title = action.payload.title;
                    state.project.target_date = action.payload.target_date;
                }
            })
            .addCase(updatedProject.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(updatedProject.fulfilled, (state, action: PayloadAction<Project>) => {
                state.project = action.payload;
                state.isFetching = false;
            })
            .addCase(updatedProject.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export const { setProjectTitle, setOldProject, clearProject } = projectByIdSlice.actions;
export default projectByIdSlice.reducer;

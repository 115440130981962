import React, { useState } from 'react';
import './ReviewSlider.css';

interface Review {
    id: number;
    name: string;
    avatar: string;
    rating: number;
    comment: string;
    date: string;
    dateTime: string;
}

interface ReviewSliderProps {
    variant?: 'compact' | 'detailed';
}

const reviews: Review[] = Array.from({ length: 20 }, (_, i) => ({
    id: i,
    name: `User ${i + 1}`,
    avatar: `https://i.pravatar.cc/150?img=${i + 1}`,
    rating: Math.floor(Math.random() * 6),
    comment: `Lorem ipsum dolor sit amet, consectLorem ipsum  elit. Morbi orta. 1Lorem ipsum dolor sit amet, consectetur adirbi ac nisl a eros semper porta. ${i + 1}`,
    dateTime: new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }),
    date: new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }),
}));

const ReviewSlider: React.FC<ReviewSliderProps> = ({ variant = 'detailed' }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [hoveredCardId, setHoveredCardId] = useState<number | null>(null);
    const reviewsPerPage = variant === 'compact' ? 2 : 3;
    const totalPages = Math.ceil(reviews.length / reviewsPerPage);

    const handleDotClick = (index: number) => {
        setCurrentPage(index);
        setHoveredCardId(null);
    };

    const startIndex = currentPage * reviewsPerPage;
    const currentReviews = reviews.slice(startIndex, startIndex + reviewsPerPage);

    return (
        variant === 'detailed' ? (
            <div className="review-slider">
                <div className="reviews-container">
                    {currentReviews.map((review) => (
                        <div
                            key={review.id}
                            className={`review-card 
                            ${hoveredCardId === review.id ? 'hovered' : hoveredCardId !== null ? 'dimmed' : ''}
                        `}
                            onMouseEnter={() => setHoveredCardId(review.id)}
                            onMouseLeave={() => setHoveredCardId(null)}
                        >
                            <div className="review-card-content">
                                <div className="user-info">
                                    <img src={review.avatar} alt={review.name} className="avatar" />
                                    <div className="user-details">
                                        <h4>{review.name}</h4>
                                        <div className="rating">
                                            {'★'.repeat(review.rating)}
                                            {'☆'.repeat(5 - review.rating)}
                                        </div>
                                    </div>
                                </div>
                                <div className={`comment ${hoveredCardId === review.id ? 'expanded' : ''}`}>{review.comment}</div>
                                <div className="review-date">
                                    <p>{review.dateTime}</p>
                                    <p>{review.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`pagination-reviews ${hoveredCardId !== null ? 'moved-down' : ''}`}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentPage ? 'active-compact' : ''}`}

                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
        ) : (

            <div className="review-slider-compact">
                <p className='compact-opport'>Explore our opportunities</p>
                <p className='compact-reviews'>Client's reviews</p>
                <div className="reviews-container">
                    {currentReviews.map((review) => (
                        <div
                            key={review.id}
                            className={`review-card-compact 
                            ${hoveredCardId === review.id ? 'hovered' : hoveredCardId !== null ? 'dimmed' : ''}
                        `}
                            onMouseEnter={() => setHoveredCardId(review.id)}
                            onMouseLeave={() => setHoveredCardId(null)}
                        >
                            <div className="review-card-content">
                                <div className="user-info">
                                    <img src={review.avatar} alt={review.name} className="avatar"/>
                                    <div className="user-details">
                                        <h4>{review.name}</h4>
                                        <div className="rating">
                                            {'★'.repeat(review.rating)}
                                            {'☆'.repeat(5 - review.rating)}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`comment ${hoveredCardId === review.id ? 'expanded' : ''}`}>{review.comment}</div>
                                <div className="review-date">
                                    <p>{review.dateTime}</p>
                                    <p>{review.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className= "pagination-reviews-compact">
                    {Array.from({length: totalPages}, (_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentPage ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>

        )

    );
};

export default ReviewSlider;
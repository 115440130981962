import { Routes, Route } from "react-router-dom";
import Login from "./LoginPage";
import RegistrationPage from "./RegistrationPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import NewPasswordPage from "./NewPasswordPage";

export function AuthPage() {
    return (
        <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<RegistrationPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset_password" element={<NewPasswordPage />} />
        </Routes>
    );
}

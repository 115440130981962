import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import '../Step3Bom/Step3Bom.css';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import 'react-toastify/dist/ReactToastify.css';
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {IconEyesStep3} from "../../IconComponents/IconComponents";
import {addOrderedQtyToFragmentsArchitect} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {Fragment, ItemType, TItem} from "../../types/TemplateType";
import {getCreatedProjectByUrn} from "../ArchitectPage/architectRequests";
import {ArchitectProject} from "../../types/ArhitectProject";
import StepTotalsBlockArchitector from "../../components/TotalsBlockArchitector/StepTotalsBlockArchitector";

const Step3Architect = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const [projectArchitect, setProjectArchitect] = useState<ArchitectProject | null>(null);


    const {urn} = useParams<{ urn: string }>();

    const [visited, setVisited] = useState<{ [key: number]: boolean }>({});
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
    const [fragments, setFragments] = useState<Fragment[]>([])
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        if (urn) {
            const fetchProject = async () => {
                try {
                    const response = await getCreatedProjectByUrn(urn);
                    setProjectArchitect(response.data)
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, [urn]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItemsArchitect') || '{}');
        setVisited(storedVisitedItems);
    }, []);

    useEffect(() => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragmentArchitect') || '[]')
        setFragments(storedSelectedFragments)
        if (storedSelectedFragments.length === 0){
            toast.warning("You must select the fragments in Step2");
            setTimeout(() => {
                navigate(`/architect-page/step2/${urn}`);
            }, 2000);
        }
    }, []);


    const calculateFragmentTotal = (fragment: Fragment): number => {
        const itemTypes = fragment.data.item_types ;
        return itemTypes.reduce((total, itemType) => {
            const itemsTotal = itemType.items.reduce((subTotal, item) => {
                const qty = quantities[`${fragment.id}-${itemType.id}-${item.id}`] || 0;
                return subTotal + qty * parseFloat(String(item.price));
            }, 0);
            return total + itemsTotal;
        }, 0);
    };

    if(!projectArchitect) return null



    const navigateToHome = () => {
        clearLocalStorageExceptToken();
        navigate('/auth/login');
    };


    const renderVendorBlock = (item: TItem, visited: boolean, index: number) => {
        if (!item.vendor) {
            return <p className='step3-bom-item-no-vendor'>No Vendor</p>;
        }
        if (!visited) {
            return (
                <div
                    onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                    className='step3-bom-items-container-vendor'>
                    <p className='step3-bom-items-container-vendor-purchase'>
                        <span className='step3-vendor-eyes'>
                            <IconEyesStep3/>
                        </span>
                        <span className='step3-vendor-p'>
                            Purchase from
                        </span>
                    </p>
                    <p className='step3-vendor-img'>
                        {item.vendor}
                    </p>
                </div>
            );
        } else {
            return (
                <div className='step3-bom-items-container-vendor-visited'>
                    <p
                        className='step3-vendor-img'
                        onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                    >
                        {item.vendor}
                    </p>
                    <span
                        onClick={() => handleLogoClick(item.merchant_info?.link, item.id, index)}
                        className='step3-vendor-eyes'
                    >
                        <IconEyesStep3/>
                    </span>
                    <span className='step3-vendor-p-visited'>Visited</span>
                </div>
            );
        }
    };

    const handleLogoClick = (link: string | undefined, itemId: number, index: number) => {
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItemsArchitect') || '{}');
        if (!storedVisitedItems[itemId]) {
            storedVisitedItems[itemId] = true;
            localStorage.setItem('visitedItemsArchitect', JSON.stringify(storedVisitedItems));
            setVisited(prev => ({
                ...prev,
                [itemId]: true
            }));
        }
        if (link) {
            window.open(link, '_blank', 'noopener');
        }
    };

    const handleQtyChange = (key: string, value: number) => {
        setQuantities(prev => ({
            ...prev,
            [key]: value
        }));
    };
    const handleSave = async () => {
        const processFragments = (fragments: any[], originalFragments: any[]) => {
            return fragments.flatMap((fragment) => {
                const fragmentIndex = originalFragments.findIndex(f => f.id === fragment.id);
                return fragment.data.item_types.flatMap((itemType:ItemType) => {
                    const itemTypeIndex = fragment.data.item_types.findIndex((it: { id: number; }) => it.id === itemType.id);

                    return itemType.items
                        .filter(item => quantities[`${fragment.id}-${itemType.id}-${item.id}`] > 0)
                        .map(item => ({
                            fragment_id: fragment.id,
                            fragment_index: fragmentIndex,
                            item_type_id: itemType.id,
                            item_type_index: itemTypeIndex,
                            item_id: item.id,
                            ordered_qty: (item.ordered_qty || 0) + (quantities[`${fragment.id}-${itemType.id}-${item.id}`] || 0)
                        }));
                });
            });
        };

        const normalFragments = processFragments(
            fragments.filter(fragment => fragment.current_index !== undefined),
            projectArchitect.data.fragments
        );

        const customFragments = processFragments(
            fragments.filter(fragment => fragment.data.current_index !== undefined),
            projectArchitect.data.customFragments
        );
        const allItems = [...normalFragments, ...customFragments];
        const hasValidItems = allItems.some(item => item.ordered_qty > 0);
        if (!hasValidItems) {
            toast.error('You need to enter at least one quantity before proceeding.');
            return;
        }
        const body: any = {};

        if (normalFragments.length > 0) {
            body.fragments = normalFragments;
        }
        if (customFragments.length > 0) {
            body.customFragments = customFragments;
        }
        try {
            const response = await addOrderedQtyToFragmentsArchitect(projectArchitect?.id, body);
            if (response.status === 200) {
                localStorage.removeItem('selectedFragmentArchitect');
                navigate(`/architect-page/step2/${urn}`);
            } else {
                toast.error('Error sending request, please try again later');
            }
        } catch (error) {
            toast.error('Error sending request, please try again later');
            console.error("Error sending request:", error);
        }
    };



    if (!fragments) {
        return null
    }

    const fragmentTotal = fragments.reduce(
        (acc, fragment) => acc + calculateFragmentTotal(fragment),
        0
    );

    const renderHeaderList = () => (
        <div className='step3-bom-header-info'>
            <p className='step3-bom-item-title-info'>Material</p>
            <p className='step3-bom-qty-order-info'>QTY To order</p>
            <p className='step3-bom-qty-ordered-info'>QTY ordered</p>
            <p className='step3-bom-price-info'>Unit price</p>
            <p className='step3-bom-total-value-info'>Total Value</p>
            <p className='step3-bom-total-current-order-info'>Current Order</p>
            <p className='step3-bom-total-orderd-vendor-info'>Vendor</p>
        </div>
    );
    if (!fragments && !projectArchitect) {
        return null
    }

    const renderFragments = () => {
        return (
            <>
                <p className='step2-bom-name-container'>List of fragments</p>
                <div className='step2-content-block'>

                    {fragments.map((fragment, fragmentIndex) => (
                        <div key={fragmentIndex}>
                            <div className='step2-bom-bom-project-title'>
                                <p className='step2-bom-title'>{fragment.title}</p>

                            </div>
                            {renderHeaderList()}

                            {(fragment.data.item_types).map((itemType: ItemType, itemTypeIndex: number) => (
                                <div key={`${fragmentIndex}-${itemTypeIndex}`}>

                                    {itemType.items.map((item, itemIndex) => {
                                        const isVisited = visited[item.id] || false;
                                        const uniqueKey = `${fragment.id}-${itemType.id}-${item.id}`
                                        return (
                                            <div key={`${fragmentIndex}-${itemTypeIndex}-${itemIndex}`}
                                                 className='step3-bom-items'>
                                                <p className='step3-bom-item-title'>{item.title}</p>
                                                <p className='step3-bom-qty-ordered'>{item.ordered_qty || 0}/{item.qty || 0} ({item.unit || 'each'})</p>
                                                <div className='step3-bom-item-input'>
                                                    <input
                                                        type="number"
                                                        className="step2-other-width-input"
                                                        value={quantities[uniqueKey] || ''}
                                                        onChange={(e) => handleQtyChange(uniqueKey, Number(e.target.value))}
                                                        min={0}
                                                    />
                                                    <span
                                                        className='packing-value-steps'>psc</span>
                                                </div>
                                                <p className='step3-bom-price'>R {parseFloat(String(item.price)).toFixed(2)}</p>
                                                <p className='step3-bom-total'>R {(parseFloat(String(item.price)) * (item.qty || 0)).toFixed(2)}</p>
                                                <p className='step3-bom-total-ordered'>R {(parseFloat(String(item.price)) * (quantities[uniqueKey] || 0)).toFixed(2)}</p>
                                                {renderVendorBlock(item, isVisited, itemIndex)}
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                            <div className='step3-bottom-total-block-price'>
                                <p>Total:</p>
                                {isSmallScreen && (<span
                                    className="dashed-line"></span>)}
                                <span>R {calculateFragmentTotal(fragment).toFixed(2)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    return (
        <div className='step2'>
            <div>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/home', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToHome} className='template-link-assistance'>Project group
                                assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                )}
                <div className='step-info'>
                    <div className='step-info-block'>
                        <h3>Step 3</h3>
                        <span>/</span>
                        <p className='step-info-block-name'>Order view</p>
                    </div>
                </div>
                <section className='step2-bom-container'>
                    <div className='step2-bom-cost-summary'>
                        <p className='cost-summary-bom'>Cost summary</p>
                    </div>
                    {fragments.length > 0 && (
                        renderFragments()
                    )}
                </section>

                <StepTotalsBlockArchitector
                    project={projectArchitect}
                    fragmentTotal={fragmentTotal}
                    isSmallScreen={isSmallScreen}
                    isVisible={false}
                />
            </div>
            <div className='step2-page-bottom-btn'>
                <div className='step2-page-bottom-btn-block'>
                    <div className='step2-page-btn'>
                        <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                    </div>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleSave}
                        >
                            Proceed to Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step3Architect;

import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-modal';
import './style.css';
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProject as createProjectAction } from "../../../Slice/createNewProject/createProjectSlice";
import { setProject as setProjectAction } from "../../../Slice/updateProject/updateProjectSlice";
import { clearTemplates } from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import { Project } from "../../../types/ProjectTypes";
import { Template } from "../../../types/TemplateType";
import ImageUpload from "../../ImageUpload/ImageUpload";
import { TMedia } from "../../../types/TMedia";
import { PostFormData } from "../../../api/ApiWrapperImage";
import { toast } from "react-toastify";

const BASE_URL_CUSTOMER = process.env.REACT_APP_BASE_URL_CUSTOMER;

declare global {
    interface Window {
        google: any;
    }
}

interface CreateProjectModalProps extends PropsFromRedux {
    isOpen: boolean;
    onRequestClose: () => void;
    onProjectCreated?: (project: Project) => void;
    template?: Template | null;
}

const G_KEY = process.env.REACT_APP_BASE_GOOGLE_KEY;
const G_COUNTRY = process.env.REACT_APP_BASE_GOOGLE_LOCATION_COUNTRY;

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
                                                                   isOpen,
                                                                   onRequestClose,
                                                                   createProject,
                                                                   setProject,
                                                                   onProjectCreated = () => { },
                                                                   template
                                                               }) => {
    const initialTargetDate = (() => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    })();

    const [name, setName] = useState<string>('');
    const [targetDate, setTargetDate] = useState<string>(initialTargetDate);

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<TMedia | string | null>(null);

    const [latitude, setLatitude] = useState<string>('');
    const [longitude, setLongitude] = useState<string>('');
    const [showHint, setShowHint] = useState<boolean>(false);
    const [city, setCity] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [dontFindAddress, setDontFindAddress] = useState<boolean>(false);
    const [fullAddress, setFullAddress] = useState<string>('');

    const geocoderRef = useRef<google.maps.Geocoder | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const markerRef = useRef<google.maps.Marker | null>(null);
    const cityAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const streetAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const [, setCityLocation] = useState<google.maps.LatLng | null>(null);

    const navigate = useNavigate();

    const resetState = () => {
        setName('');
        setTargetDate(initialTargetDate);
        setImageFile(null);
        setImagePreview(null);
        setLatitude('');
        setLongitude('');
        setShowHint(false);
        setCity('');
        setStreet('');
        setDontFindAddress(false);
        setFullAddress('');

        if (cityAutocompleteRef.current) {
            window.google.maps.event.clearInstanceListeners(cityAutocompleteRef.current);
            cityAutocompleteRef.current = null;
        }

        if (streetAutocompleteRef.current) {
            window.google.maps.event.clearInstanceListeners(streetAutocompleteRef.current);
            streetAutocompleteRef.current = null;
        }

        if (markerRef.current) {
            markerRef.current.setMap(null);
            markerRef.current = null;
        }

        if (mapRef.current) {
            window.google.maps.event.clearInstanceListeners(mapRef.current);
            mapRef.current = null;
            const mapDiv = document.getElementById('map-modal');
            if (mapDiv) {
                mapDiv.innerHTML = '';
            }
        }

        geocoderRef.current = null;
    };

    useEffect(() => {
        if (isOpen) {
            const initializeGoogleMaps = () => {
                if (window.google) {
                    if (!geocoderRef.current) {
                        geocoderRef.current = new window.google.maps.Geocoder();
                    }

                    if (!dontFindAddress) {
                        const initializeAutocomplete = () => {
                            const cityInput = document.getElementById('city') as HTMLInputElement;
                            const streetInput = document.getElementById('street') as HTMLInputElement;

                            if (cityInput && !cityAutocompleteRef.current) {
                                const cityAutoCompleteInstance = new window.google.maps.places.Autocomplete(cityInput, {
                                    types: ['(cities)'],
                                    componentRestrictions: { country: `${G_COUNTRY}` },
                                });
                                cityAutocompleteRef.current = cityAutoCompleteInstance;

                                cityAutoCompleteInstance.addListener('place_changed', () => {
                                    const place = cityAutoCompleteInstance.getPlace();
                                    if (place.geometry) {
                                        const location = place.geometry.location;
                                        setCity(place.formatted_address || place.name);
                                        setLatitude(String(location.lat()));
                                        setLongitude(String(location.lng()));
                                        setStreet('');
                                        setFullAddress(place.formatted_address || place.name);
                                        setCityLocation(location);

                                        if (mapRef.current) {
                                            mapRef.current.setCenter(location);
                                            mapRef.current.setZoom(12);
                                        }

                                        if (markerRef.current) {
                                            markerRef.current.setPosition(location);
                                        } else {
                                            const newMarker = new window.google.maps.Marker({
                                                position: location,
                                                map: mapRef.current,
                                            });
                                            markerRef.current = newMarker;
                                        }
                                    }
                                });
                            }

                            if (streetInput && !streetAutocompleteRef.current) {
                                const streetAutoCompleteInstance = new window.google.maps.places.Autocomplete(streetInput, {
                                    types: ['address'],
                                    componentRestrictions: { country: `${G_COUNTRY}` },
                                });
                                streetAutocompleteRef.current = streetAutoCompleteInstance;

                                streetAutoCompleteInstance.addListener('place_changed', () => {
                                    const place = streetAutoCompleteInstance.getPlace();
                                    if (place.geometry) {
                                        const location = place.geometry.location;
                                        setStreet(place.formatted_address || place.name);
                                        setLatitude(String(location.lat()));
                                        setLongitude(String(location.lng()));
                                        setFullAddress(place.formatted_address || place.name);

                                        if (mapRef.current) {
                                            mapRef.current.setCenter(location);
                                            mapRef.current.setZoom(15);
                                        }

                                        if (markerRef.current) {
                                            markerRef.current.setPosition(location);
                                        } else {
                                            const newMarker = new window.google.maps.Marker({
                                                position: location,
                                                map: mapRef.current,
                                            });
                                            markerRef.current = newMarker;
                                        }
                                    }
                                });
                            }
                        };

                        initializeAutocomplete();
                    }
                }
            };

            const loadGoogleMapsScript = () => {
                if (window.google) {
                    initializeGoogleMaps();
                } else {
                    const existingScript = document.getElementById('googleMaps');
                    if (!existingScript) {
                        const script = document.createElement('script');
                        script.src = `https://maps.googleapis.com/maps/api/js?key=${G_KEY}&libraries=places`;
                        script.id = 'googleMaps';
                        script.async = true;
                        script.defer = true;
                        script.onload = () => {
                            initializeGoogleMaps();
                        };
                        script.onerror = () => {
                            toast.error('Error loading Google Maps script.');
                        };
                        document.body.appendChild(script);
                    } else {
                        existingScript.onload = () => {
                            initializeGoogleMaps();
                        };
                    }
                }
            };

            loadGoogleMapsScript();
        } else {
            resetState();
        }
    }, [isOpen, dontFindAddress]);

    useEffect(() => {
        if (isOpen && dontFindAddress) {
            const initializeMap = () => {
                if (window.google && !mapRef.current) {
                    const initialLocation = new window.google.maps.LatLng(-30.5595, 22.9375);
                    const mapOptions = {
                        zoom: 6,
                        center: initialLocation,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        clickableIcons: false
                    };
                    const mapInstance = new window.google.maps.Map(document.getElementById('map-modal') as HTMLElement, mapOptions);
                    mapRef.current = mapInstance;

                    if (!geocoderRef.current) {
                        geocoderRef.current = new window.google.maps.Geocoder();
                    }

                    mapInstance.addListener('click', (e: google.maps.MapMouseEvent) => {
                        if (e.latLng) {
                            const lat = e.latLng.lat();
                            const lng = e.latLng.lng();
                            setLatitude(String(lat));
                            setLongitude(String(lng));

                            if (markerRef.current) {
                                markerRef.current.setMap(null);
                            }

                            const newMarker = new window.google.maps.Marker({
                                position: e.latLng,
                                map: mapInstance,
                            });

                            markerRef.current = newMarker;

                            if (geocoderRef.current) {
                                geocoderRef.current.geocode({ location: { lat, lng } }, (results, status) => {
                                    if (status === 'OK' && results && results.length > 0) {
                                        setFullAddress(results[0].formatted_address);
                                    } else {
                                        toast.error('Could not determine the address using the selected coordinates.', {
                                            autoClose: 5000
                                        });
                                        setFullAddress('');
                                    }
                                });
                            } else {
                                console.error('Geocoder is not initialized');
                            }
                        }
                    });
                }
            };

            initializeMap();
        }
    }, [isOpen, dontFindAddress]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onRequestClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
            window.addEventListener('keydown', handleKeyDown);
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    const clearTemplateDataFromLocalStorage = () => {
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('selectedTemplate-')) {
                localStorage.removeItem(key);
            }
        });
    };

    const uploadProjectImage = async (projectId: number, imageFile: File) => {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("projectId", projectId.toString());

        try {
            const response = await PostFormData(
                BASE_URL_CUSTOMER || "",
                `/projects/${projectId}/media`,
                formData
            );

            if (response.status !== 201) {
                throw new Error("Failed to upload image");
            }
            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleCreateProject = async () => {
        if (name && (dontFindAddress ? (latitude && longitude) : (city && street))) {
            const projectPayload = {
                title: name,
                target_date: targetDate,
                address: {
                    full_address: dontFindAddress ? fullAddress : `${street}, ${city}, South Africa`,
                    latitude: latitude,
                    longitude: longitude
                }
            };
            try {
                const project = await createProject(projectPayload);

                if (project.payload && isProject(project.payload)) {
                    setProject(project.payload);
                    clearTemplates();
                    clearTemplateDataFromLocalStorage();
                    setName('');
                    setTargetDate(initialTargetDate);
                    toast.success(`You have successfully created the "${project.payload.title}" project space`);
                    onRequestClose();

                    if (imageFile) {
                        try {
                            await uploadProjectImage(project.payload.id, imageFile);
                        } catch (error) {
                            toast.error("Failed to upload project image.", {
                                autoClose: 5000
                            });
                        }
                    }

                    if (template) {
                        onProjectCreated(project.payload);
                    } else {
                        navigate(`/assistance/create/${project.payload.id}/from/create`);
                    }

                }
            } catch (error) {
                toast.error("Failed to create project, try again later.", {
                    autoClose: 5000
                });
            }
        } else {
            toast.warn("Please fill in all required fields.", {
                autoClose: 3000
            });
        }
    };

    const isProject = (obj: any): obj is Project => {
        return obj && typeof obj === 'object' && 'id' in obj && 'title' in obj && 'created_at' in obj && 'updated_at' in obj;
    };

    const handleFocus = () => {
        setShowHint(true);
        setTimeout(() => {
            setShowHint(false)
        }, 5000)
    };

    const handleBlur = () => {
        setShowHint(false);
    };

    const handleDontFind = () => {
        setDontFindAddress(prevState => !prevState);
        if (!dontFindAddress) {
            toast.info('Please select a location on the map to determine your coordinates.', {
                autoClose: 6000
            });

            if (cityAutocompleteRef.current) {
                window.google.maps.event.clearInstanceListeners(cityAutocompleteRef.current);
                cityAutocompleteRef.current = null;
            }

            if (streetAutocompleteRef.current) {
                window.google.maps.event.clearInstanceListeners(streetAutocompleteRef.current);
                streetAutocompleteRef.current = null;
            }
        } else {
            setLatitude('');
            setLongitude('');
            setFullAddress('');

            if (markerRef.current) {
                markerRef.current.setMap(null);
                markerRef.current = null;
            }
            if (mapRef.current) {
                mapRef.current.setCenter(new window.google.maps.LatLng(-30.5595, 22.9375));
                mapRef.current.setZoom(6);
                window.google.maps.event.clearInstanceListeners(mapRef.current);
                mapRef.current = null;
                const mapDiv = document.getElementById('map-modal');
                if (mapDiv) {
                    mapDiv.innerHTML = '';
                }
            }
        }
    };

    const handleNumericLatitude = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9.-]/g, '');
        setLatitude(numericValue);
    };

    const handleNumericLongitude = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9.-]/g, '');
        setLongitude(numericValue);
    };

    const geocodeAddress = () => {
        if (geocoderRef.current && city && street) {
            const fullAddress = `${street}, ${city}, South Africa`;
            geocoderRef.current.geocode({ address: fullAddress }, (results, status) => {
                if (status === 'OK' && results && results.length > 0) {
                    const location = results[0].geometry.location;
                    setLatitude(String(location.lat()));
                    setLongitude(String(location.lng()));
                    setFullAddress(results[0].formatted_address);

                    if (mapRef.current) {
                        mapRef.current.setCenter(location);
                        mapRef.current.setZoom(15);
                    }

                    if (markerRef.current) {
                        markerRef.current.setPosition(location);
                    } else {
                        const newMarker = new window.google.maps.Marker({
                            position: location,
                            map: mapRef.current,
                        });
                        markerRef.current = newMarker;
                    }
                } else {
                    toast.error('Unable to determine coordinates for the entered address. Please try again.', {
                        autoClose: 5000
                    });
                }
            });
        } else {
            console.error('Geocoder is not initialized or missing data for geocoding');
        }
    };

    const isButtonDisabled = dontFindAddress
        ? !name || !latitude || !longitude
        : !name || !city || !street;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal-create-project"
            overlayClassName="overlay"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <h2>Save the Project Space</h2>
                <button onClick={onRequestClose} className="close-button">&times;</button>
            </div>
            <div className="template-user-info-image">
                <ImageUpload
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    disabled={false}
                    getProject={null}
                />
            </div>
            <div className="modal-content">
                <section>
                    <label>Project Space Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter project name"
                        className="project-name-input-modal"
                    />
                </section>
                <section>
                    <label>Target Completion Date</label>
                    <input
                        type="date"
                        min={initialTargetDate}
                        value={targetDate}
                        onChange={(e) => setTargetDate(e.target.value)}
                        className="project-date-input-modal"
                    />
                </section>
                <section>
                    <div className='project-prompt-modal'>
                        Your Address
                        {showHint && (
                            <small className="address-hint">
                                Please enter city and street in the respective fields.
                            </small>
                        )}
                    </div>
                    <div className="step-template-dropdown-container-modal">
                        <p
                            onClick={handleDontFind}
                            className='project-prompt-modal-didn-find'
                            style={showHint ? { top: '-60px' } : { top: '-30px' }}
                        >
                            {!dontFindAddress ? "Didn't find your address?" : "Found the address?"}
                        </p>
                        {!dontFindAddress ? (
                            <div className='project-prompt-modal-address'>
                                <div className="address-input-group">
                                    <input
                                        id="city"
                                        type="text"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        placeholder="Enter city"
                                        className="project-address-input-modal"
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className="address-input-group">
                                    <input
                                        id="street"
                                        type="text"
                                        value={street}
                                        placeholder="Enter street"
                                        onChange={(e) => setStreet(e.target.value)}
                                        onBlur={() => {
                                            handleBlur();
                                            geocodeAddress();
                                        }}
                                        className="project-address-input-modal"
                                        onFocus={handleFocus}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='project-prompt-modal-address'>
                                <div id="map-modal"
                                     style={{
                                         width: '100%',
                                         height: '400px',
                                         marginBottom: '10px',
                                         display: dontFindAddress ? 'block' : 'none'
                                     }}></div>
                                <div className="address-input-group">
                                    <input
                                        type="text"
                                        value={latitude}
                                        onChange={handleNumericLatitude}
                                        placeholder="Latitude"
                                        className="project-address-input-modal"
                                        readOnly={false}
                                    />
                                </div>
                                <div className="address-input-group">
                                    <input
                                        type="text"
                                        value={longitude}
                                        onChange={handleNumericLongitude}
                                        placeholder="Longitude"
                                        className="project-address-input-modal"
                                        readOnly={false}
                                    />
                                </div>
                                {dontFindAddress && fullAddress && (
                                    <div className="address-input-group">
                                        <label>Nearest address:</label>
                                        <input
                                            type="text"
                                            value={fullAddress}
                                            className="project-address-input-modal"
                                            readOnly
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <div className="modal-footer-create">
                <button
                    onClick={handleCreateProject}
                    className="create-button"
                    disabled={isButtonDisabled}
                >
                    Create Project Space
                </button>
            </div>
        </Modal>
    )
};

const mapDispatchToProps = {
    createProject: createProjectAction,
    setProject: setProjectAction,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateProjectModal);

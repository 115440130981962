function GetPermission(level?: number[]): string {
    if (level && level.length === 1 && level[0] === 0) {
        return 'User';
    } else if (level && level.length > 0) {
        return 'Admin';
    }
    return 'No permissions';
}

export default GetPermission;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-project-bom-header{
    display: flex;
    justify-content: space-between;
    background-color: var(--basic-table-shadow-color);
    border-radius: 5px;
    color: var(--basic-text-title-color);
    font-family: 'Roboto', sans-serif;
    padding: 0 200px 0 25px;
    margin-top: 55px;
}

.all-bom-project-data-section-project {
    display: flex;
    background-color: var(--basic-table-background-color);
    border-radius: 5px;
    box-shadow: 0 0 4px 2px var(--basic-table-shadow-color);
    transition: box-shadow 0.3s ease;
    color: var(--basic-text-description-color);
}
.all-bom-project-data-section-block-project{
    display: flex;
    width: 100%;
    flex-direction: row;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
    padding: 10px 0 10px 25px;
}
.all-bom-project-content-title{
    width: 375px;
}
.all-bom-project-content-data{
    width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/Page/ProjectsAllBOM/ProjectsAllBom.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,iDAAiD;IACjD,kBAAkB;IAClB,oCAAoC;IACpC,iCAAiC;IACjC,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,qDAAqD;IACrD,kBAAkB;IAClB,uDAAuD;IACvD,gCAAgC;IAChC,0CAA0C;AAC9C;AACA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,gCAAgC;IAChC,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB","sourcesContent":[".all-project-bom-header{\n    display: flex;\n    justify-content: space-between;\n    background-color: var(--basic-table-shadow-color);\n    border-radius: 5px;\n    color: var(--basic-text-title-color);\n    font-family: 'Roboto', sans-serif;\n    padding: 0 200px 0 25px;\n    margin-top: 55px;\n}\n\n.all-bom-project-data-section-project {\n    display: flex;\n    background-color: var(--basic-table-background-color);\n    border-radius: 5px;\n    box-shadow: 0 0 4px 2px var(--basic-table-shadow-color);\n    transition: box-shadow 0.3s ease;\n    color: var(--basic-text-description-color);\n}\n.all-bom-project-data-section-block-project{\n    display: flex;\n    width: 100%;\n    flex-direction: row;\n    border-radius: 5px;\n    transition: box-shadow 0.3s ease;\n    padding: 10px 0 10px 25px;\n}\n.all-bom-project-content-title{\n    width: 375px;\n}\n.all-bom-project-content-data{\n    width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

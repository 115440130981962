import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    updateProject as updateProjectAPI,
    getProjectById as getProjectByIdAPI,
    updatePermissions, deleteUserProjectsPermissions
} from "../../api/APIWraper";
import { Project } from "../../types/ProjectTypes";

interface ProjectsState {
    currentProject: Project | null;
    isFetching: boolean;
    error: string | null;
    permissionsUpdated: boolean;
    updateStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: ProjectsState = {
    currentProject: null,
    isFetching: false,
    error: null,
    permissionsUpdated: false,
    updateStatus: 'idle',
};

export const updateProject = createAsyncThunk<Project, { projectId: number, projectData: any }>(
    'projects/updateProject',
    async ({ projectId, projectData }) => {
        const response = await updateProjectAPI(projectId, projectData);
        return response.data;
    }
);

export const fetchProjectById = createAsyncThunk<Project, string>(
    'projects/fetchProjectById',
    async (projectId) => {
        return await getProjectByIdAPI(projectId);
    }
);

export const updateProjectPermissions = createAsyncThunk<Project, { projectId: string, email: string, permissions: string[] }>(
    'projectById/updateProjectPermissions',
    async ({ projectId, email, permissions }) => {
        const response = await updatePermissions(projectId, { email, permissions });
        return response.data;
    }
);

export const deleteUserPermissions = createAsyncThunk(
    'updateProject/deleteUserPermissions',
    async ({ userId, projects_id }: { userId: number, projects_id: number[] }) => {
        await deleteUserProjectsPermissions(userId, { projects_id, permissions: [] });
        return { userId, projects_id };
    }
);

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProject(state, action: PayloadAction<Project>) {
            state.currentProject = action.payload;
        },
        clearProject(state) {
            state.currentProject = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectById.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchProjectById.fulfilled, (state, action: PayloadAction<Project>) => {
                state.isFetching = false;
                state.currentProject = action.payload;
                state.permissionsUpdated = false;
            })
            .addCase(fetchProjectById.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateProject.pending, (state) => {
                state.isFetching = true;
                state.updateStatus = 'pending';
            })
            .addCase(updateProject.fulfilled, (state, action: PayloadAction<Project>) => {
                state.currentProject = action.payload;
                state.isFetching = false;
                state.updateStatus = 'succeeded';
            })
            .addCase(updateProject.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
                state.updateStatus = 'failed';
            })
            .addCase(updateProjectPermissions.pending, (state) => {
                state.isFetching = true;
                state.updateStatus = 'pending';
            })
            .addCase(updateProjectPermissions.fulfilled, (state, action: PayloadAction<Project>) => {
                state.isFetching = false;
                state.currentProject = action.payload;
                state.updateStatus = 'succeeded';
            })
            .addCase(updateProjectPermissions.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteUserPermissions.pending, (state) => {
                state.isFetching = true;
                state.updateStatus = 'pending';
            })
            .addCase(deleteUserPermissions.fulfilled, (state, action: PayloadAction<{ userId: number, projects_id: number[] }>) => {
                state.isFetching = false;
                state.permissionsUpdated = true;
                state.updateStatus = 'succeeded';
                if (state.currentProject && action.payload.projects_id.length > 0) {
                    state.currentProject = {
                        ...state.currentProject,
                        permissions: state.currentProject.permissions.filter(
                            permission => !(action.payload.projects_id.includes(permission.project_id) && permission.user_id === action.payload.userId)
                        )
                    };
                }
            })
            .addCase(deleteUserPermissions.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
                state.updateStatus = 'failed';
            });

    },
});

export const { setProject, clearProject } = projectsSlice.actions;

export default projectsSlice.reducer;

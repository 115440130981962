import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './mobileNavigation.css';

interface NavLink {
    to: string;
    label: string;
}

interface MobileNavigationProps {
    links: NavLink[];
    children?: React.ReactNode;
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({ links, children }) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 920);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 920);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isMobile && (
                <nav className="mobile-navigation">
                    <ul className="mobile-nav-list">
                        {links.map((link, index) => (
                            <li key={index} className="mobile-nav-item">
                                <Link className="mobile-nav-link" to={link.to}>
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
            {isMobile && children}
        </>
    );
};

export default MobileNavigation;

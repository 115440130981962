// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home{
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    margin: 0 clamp(0px, 10vw, 180px);
    flex: 1 1;
}

@media (max-width: 960px) {
    .content-wrapper {
        margin: 0 clamp(0px, 10vw, 180px);
    }
}

@media (max-width: 920px) {
    .content-wrapper {
        overflow: hidden;
        margin: 0;
    }
}
@media (max-width: 700px) {
    .content-wrapper {
        overflow: hidden;

    }
}
`, "",{"version":3,"sources":["webpack://./src/Page/Home/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iCAAiC;IACjC,SAAO;AACX;;AAEA;IACI;QACI,iCAAiC;IACrC;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,SAAS;IACb;AACJ;AACA;IACI;QACI,gBAAgB;;IAEpB;AACJ","sourcesContent":[".home{\n    position: relative;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.content-wrapper {\n    margin: 0 clamp(0px, 10vw, 180px);\n    flex: 1;\n}\n\n@media (max-width: 960px) {\n    .content-wrapper {\n        margin: 0 clamp(0px, 10vw, 180px);\n    }\n}\n\n@media (max-width: 920px) {\n    .content-wrapper {\n        overflow: hidden;\n        margin: 0;\n    }\n}\n@media (max-width: 700px) {\n    .content-wrapper {\n        overflow: hidden;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from 'react';
import './Accordion.css';
import {IconArrowDownLanding, IconArrowUpLanding} from "../../IconComponents/IconComponents";

interface FAQSectionProps {
    question: string;
    answer: string;
}

const AccordionItem: React.FC<FAQSectionProps> = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
            <div className="accordion-header" onClick={toggleAccordion}>
                <p>{question}</p>
                <button className="accordion-toggle">
                    {isOpen ? <IconArrowUpLanding/> : <IconArrowDownLanding/>}
                </button>
            </div>
            {isOpen && <div className="accordion-body"><p>{answer}</p></div>}
        </div>
    );
};

interface FAQSectionPropsForm {
    formRef?: React.RefObject<HTMLDivElement>;
}

const FAQSection: React.FC<FAQSectionPropsForm> = ({formRef}) => {
    const questionsAnswers = [
        {
            question: 'What is a Bill of Materials (BoM) or Bill of Quantities (BoQ), and why is it important?',
            answer: 'A BoM / BoQ is crucial for effective project planning and budgeting. Here’s why:\n' +
                '1. Cost Management: By listing all necessary materials and quantities, a BoM / BoQ helps estimate the total project cost, allowing you to budget accurately and avoid unexpected expenses.\n' +
                '2. Efficiency and Time-Saving: A BoM / BoQ ensures that you have everything you need before starting the project, reducing delays caused by missing materials.\n' +
                '3. Reduced Waste: With accurate quantities, a BoM / BoQ minimizes material waste and over-purchasing, which can save money and reduce environmental impact.\n' +
                '4. Streamlined Procurement: A BoM / BoQ provides a clear list for ordering materials, making it easier to source everything from one or more suppliers.'
        },
        {
            question: 'What information do I need to provide GoBuild360 to create an accurate BoM / BoQ?',
            answer: 'To create an accurate Bill of Materials (BoM / BoQ) or Bill of Quantities (BoQ) with GoBuild360, you’ll need to provide a few key pieces of information about your project. Here’s what you’ll need to get started:\n' +
                '1. Project Type: Specify the type of project (e.g., building a wall, tiling a floor, painting a room, renovating a room or a bathroom, building a new house). This helps GoBuild360 apply the correct template for your needs.\n' +
                '2. Measurements: Accurate dimensions are essential. For example:\n' +
                'Length, Width, and Height: For walls, floors, or entire rooms. Area: If applicable, like the square meters/footage for flooring or painting.\n' +
                '3. Preferred Materials and Finishes: If you have a preference for specific materials (e.g., tile type, paint finish, or lumber type), you can specify these by selecting the desired products from the product list supplied by GoBuild360. \n' +
                '4. Additional Requirements: Any other details or specifications, such as selecting product choices, specific brands, or quality levels, can also help in tailoring the BoM / BoQ to match your preferences.\n' +
                'This detailed BoM / BoQ helps you plan and budget\n' +
                'effectively, giving you confidence in your project preparation.'
        },
        {
            question: 'How accurate is the BoM / BoQ in estimating total project costs?',
            answer: 'The accuracy of the GoBuild360 Bill of Materials (BoM) / Bill of Quantities (BoQ) in estimating total project costs with GoBuild360 is high. Our calculation library was developed by qualified and currently active quantity surveyors in each country, ensuring that measurements align with local building standards and practices. The library’s calculations account for realistic material quantities and include allowances for product wastage based on actual usage in construction projects, tailored to each product type. This level of precision gives users confidence in the BoM / BoQ’s accuracy for budgeting, reducing surprises from material shortages or unexpected expenses, and helping projects stay on track and within budget.'
        },
        {
            question: 'Can I save or export the BoM / BoQ for future projects or reference?',
            answer: 'Yes, you can save or export the BoM / BoQ for future projects or reference. GoBuild360 allows you to easily generate and export a PDF file of your project’s BoM / BoQ, which you can download, print, or save for later use. This PDF export provides a convenient, detailed record of your project’s materials, quantities, costs, and other specifications. It’s ideal for sharing with contractors, using as a reference for similar future projects, or keeping on hand for budgeting and planning purposes.'
        },
    ];
    const scrollToForm = () => {
        formRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };
    return (
        <section className='landing-section-question'>
            <div className='landing-block-question'>
                <p className='upper-text-bold'>Do You Have Any Questions?</p>
                <p className='landing-video-section-text-info'>
                    We will be happy to answer all your questions: from using the functionality and calculating
                    materials to projects cost and consulting on the best suppliers. If you have not found the question
                    you are interested in, you can always write to our support service and we will answer you within 48
                    hours!
                </p>
                <button
                    onClick={scrollToForm}
                    className='upper-contact-us'>Contact Us
                </button>
            </div>
            <div className='accordion-container'>
                {questionsAnswers.map((item, index) => (
                    <AccordionItem key={index} question={item.question} answer={item.answer}/>
                ))}
            </div>
        </section>
    );
};

export default FAQSection;

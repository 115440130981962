import React, {useEffect, useState} from 'react';
import './style.css';
import '../../components/Style List View/style.css';
import {useNavigate, useParams} from "react-router-dom";
import ElementList from "../../components/ElementList/ElementList";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store";
import {fetchProjectById} from "../../Slice/projectById/projectByIdSlice";
import ProjectCostsDetailsHeader from "../../components/HeaderProjectCosts/ProjectCostsDetails";
import ModalCalculateCosts from "../../components/ProjectCostsModalCalculate/ModalCalculateCosts";
import DeleteModal from "../../components/Modals/ModalDelete/ModalDelete";
import {Category} from "../../types/ProjectTypes";
import {updateProject} from "../../Slice/updateProject/updateProjectSlice";
import {updateLocalTemplate} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {transformTemplate} from "../../utils/TransformTemplate";
import {
    IconCalculation,
    IconRemove,
    IconSettings,
    IconTriangleDown,
    IconTriangleUp
} from "../../IconComponents/IconComponents";
import {Template} from "../../types/TemplateType";
import templateToTemplateRequestParser from "../../Parsers/TemplateParse/templateToTemplateRequestParser";
import {Loader} from "../../components/Loader/Loader";
import {ReceiptPDF} from "../../components/PDF/PDF";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {unifiedColumnFieldMapping} from "../../utils/ColumnFieldSorting";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";

const ProjectCosts: React.FC<PropsFromRedux> = ({
                                                    project,
                                                    isFetching,
                                                    error,
                                                    fetchProjectById,
                                                    updateProject,
                                                    updateLocalTemplate,
                                                    isAuthenticated
                                                }) => {
    const {projectId} = useParams<{ projectId: string }>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number | null>(null);
    const [templateToDeleteIndex, setTemplateToDeleteIndex] = useState<number | null>(null);
    const [localTemplates, setLocalTemplates] = useState<Template[]>([]);
    const navigate = useNavigate();
    const [showMoreCategories, setShowMoreCategories] = useState<{ [key: number]: boolean }>({});
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const TEMPLATE_PER_PAGE = 10;
    const [paginationState, setPaginationState] = useState<{
        currentPage: number;
    }>({
        currentPage: 1,
    });

    const paginate = (pageNumber: number) => {
        setPaginationState(prevState => ({
            ...prevState,
            currentPage: pageNumber
        }));
    };

    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);

    const handleSortChange = (column: string) => {
        const field = unifiedColumnFieldMapping[column];
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    const toggleShowMoreCategories = (index: number) => {
        setShowMoreCategories(prevState => ({...prevState, [index]: !prevState[index]}));
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            if (projectId) {
                fetchProjectById({
                    projectId: projectId,
                    sortBy: sortBy ?? undefined,
                    sortOrder: sortOrder ?? undefined
                });
            }
        } else {
            navigate('/home')
        }
    }, [projectId, sortBy, sortOrder, fetchProjectById, isAuthenticated]);

    useEffect(() => {
        if (project?.data && project.data.templates) {
            setLocalTemplates(project.data.templates);
        }
        return () => {
            setLocalTemplates([]);
        };
    }, [project]);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
            window.addEventListener('keydown', handleKeyDown);
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsModalOpen(false);
        }
    };

    const calculateTemplateTotalCost = (template: Template): number => {
        let total = 0;
        const currentTemplate = templateToTemplateRequestParser(template);
        if (currentTemplate.data && currentTemplate.data.fragments) {
            currentTemplate.data.fragments.forEach((fragment) => {
                if (fragment.data && Array.isArray(fragment.data.item_types)) {
                    fragment.data.item_types.forEach((itemType) => {
                        if (itemType.item && itemType.item.price != null) {
                            const itemPrice = parseFloat(String(itemType?.item?.price)) || 0;
                            const itemQty = itemType?.item?.qty || 0;
                            total += itemQty * itemPrice;
                        }
                    });
                }
            });
        }
        return total;
    };

    if (isFetching) return <Loader/>;
    if (error) return <p>Error: {error}</p>;

    const handleSave = () => {
        setIsModalOpen(false);
        if (projectId != null) {
            fetchProjectById({projectId: projectId});
        }
    };

    const addTemplatesButton = () => {
        setTimeout(() => {
            navigate(`/assistance/create/${projectId}/from/projectCosts`, {state: {from: 'projectCosts'}});
        }, 1000);
    };

    const handleCalculateClick = (index: number) => {
        const actualIndex = (paginationState.currentPage - 1) * TEMPLATE_PER_PAGE + index;
        const template = project?.data?.templates?.[actualIndex];
        const localTemplate = localTemplates[actualIndex];
        if (template?.counted || localTemplate.counted) {
            setTimeout(() => {
                navigate(`/assistance/step2/template/${actualIndex}/project/${projectId}`);
            }, 1000);
        } else if (template) {
            updateLocalTemplate(template);
            localStorage.setItem(`selectedTemplate-${template.id}`, JSON.stringify(template));
            setTimeout(() => {
                navigate(`/assistance/step1/template/${template.id}/${index}/project/${projectId}`);
            }, 1000);
        }
    };


    const openModal = (index: number) => {
        const actualIndex = (paginationState.currentPage - 1) * TEMPLATE_PER_PAGE + index;
        setSelectedTemplateIndex(actualIndex);
        setIsModalOpen(true);
    };

    const handleDeleteTemplate = (index: number) => {
        const actualIndex = (paginationState.currentPage - 1) * TEMPLATE_PER_PAGE + index;
        setTemplateToDeleteIndex(actualIndex);
    };

    const confirmDeleteTemplate = () => {
        if (templateToDeleteIndex !== null) {
            const updatedTemplates = localTemplates.filter((_, index) => index !== templateToDeleteIndex);
            const transformedTemplates = updatedTemplates.map(transformTemplate);
            if (project) {
                updateProject({
                    projectId: project.id,
                    projectData: {
                        data: {
                            templates: transformedTemplates
                        }
                    }
                });
            }
            setLocalTemplates(updatedTemplates);
            setTemplateToDeleteIndex(null);

            const newTotalPages = Math.ceil(updatedTemplates.length / TEMPLATE_PER_PAGE);
            setPaginationState(prevState => ({
                ...prevState,
                currentPage: Math.min(prevState.currentPage, newTotalPages) || 1
            }));
        }
    };

    const handleTemplateUpdate = (updatedTemplates: Template[]) => {
        setLocalTemplates(updatedTemplates);
    };

    const columnsWidthClasses = ['width-title', 'width-template-categories',
        "width-project-options", "width-project-total-cost", "table-content-data-section-field-actions-3btn"];

    const totalProjects = localTemplates.length;
    const indexOfLastTemplate = paginationState.currentPage * TEMPLATE_PER_PAGE;
    const indexOfFirstTemplate = indexOfLastTemplate - TEMPLATE_PER_PAGE;
    const currentTemplates = localTemplates.slice(indexOfFirstTemplate, indexOfLastTemplate);

    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Project page'}
                    ]}
                />
            )}

            <div>
                {project && (
                    <ProjectCostsDetailsHeader
                        isSmallScreen={isSmallScreen}
                        key={project.id}/>
                )}
                {!isSmallScreen && (
                    <ElementList
                        columns={['Building Project', 'Category', 'Total costs', 'Action']}
                        columnsWidthClasses={columnsWidthClasses}
                        sortBy={sortBy ?? undefined}
                        sortOrder={sortOrder ?? undefined}
                        onSortChange={handleSortChange}
                        isShowCosts={true}
                    />
                )}
                <div className='wrapper'>
                    <ul className="table-content-data-section-container-costs">
                        {currentTemplates.map((template, index) => {
                            const actualIndex = indexOfFirstTemplate + index;
                            const totalTemplateCost = calculateTemplateTotalCost(template);
                            const categories = template?.categories?.map(cat => cat.category).join(", ");
                            const countedClass = template.counted ? 'counted-template' : '';
                            const showAllCategories = showMoreCategories[actualIndex];

                            if (!isSmallScreen) {
                                return (
                                    <li key={actualIndex}>
                                        <section className={`table-content-data-section-costs ${countedClass}`}>
                                            <p className="project-table-content-data-section-field-center-costs width-title">{template.title}</p>
                                            <div
                                                className="table-content-data-section-field-costs width-template-categories">
                                                <section className="table-content-data-section-field-list-header-costs">
                                                    <p className="table-content-data-section-field-list-header-main-costs">{categories}</p>
                                                    {template?.categories && template?.categories?.length > 1 && (
                                                        <button onClick={() => toggleShowMoreCategories(actualIndex)}>
                                                            {showAllCategories ? <IconTriangleUp/> :
                                                                <IconTriangleDown/>}
                                                        </button>
                                                    )}
                                                </section>

                                                {showAllCategories && (
                                                    <div className="more-categories">
                                                        {template?.categories && template?.categories?.length && template.categories.slice(1).map((category: Category) => (
                                                            <p key={category.id} className="additional-category">
                                                                {category.category}
                                                            </p>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <p className='width-costs-total-cost'>
                                                {totalTemplateCost ? `R ${totalTemplateCost.toFixed(2)}` : 'no products'}
                                            </p>
                                            <div className="table-content-data-section-field-actions-3btn-costs">
                                                <div className='project-btn-view-costs'>
                                                    <button onClick={() => handleCalculateClick(index)}
                                                            className='btn-view-project-costs'>
                                                        <span title={template.counted ? 'Go to Step 2' : 'Go to Step 1'}
                                                              className='project-btn-view-costs-span'>Calculate</span>
                                                    </button>
                                                </div>
                                                <div className='action-block-costs'>
                                                    <button onClick={() => openModal(index)}
                                                            className='costs-action-btn'>
                                                        <div title='Questions and answers' className='setting-costs'>
                                                            <IconSettings/></div>
                                                    </button>
                                                    <button onClick={() => handleDeleteTemplate(index)}
                                                            className='costs-btn-costs'>
                                                        <div title='Remove template' className='delete-costs'>
                                                            <IconRemove/></div>
                                                    </button>
                                                </div>
                                            </div>
                                        </section>
                                    </li>
                                );
                            }
                            return (
                                <li key={actualIndex}>
                                    <section className={`table-content-data-section-costs ${countedClass}`}>
                                        <div className="costs-content">
                                            <p className="costs-title">
                                                {template.title}
                                                <span
                                                    className='costs-mobile-categ'> {categories && ` (${categories})`}</span>
                                            </p>
                                            <p className="costs-total">
                                                Total
                                                costs: {totalTemplateCost ? `R ${totalTemplateCost.toFixed(2)}` : 'no products'}
                                            </p>
                                        </div>

                                        <div className="costs-actions">
                                            <div className='icons-wrapper'>
                                                <button onClick={() => handleCalculateClick(index)}
                                                        className='costs-btn-costs'>
                                                    <div className='calc-costs'><IconCalculation/></div>
                                                </button>
                                                <button onClick={() => openModal(index)} className='costs-action-btn'>
                                                    <div className='setting-costs'><IconSettings/></div>
                                                </button>
                                                <button onClick={() => handleDeleteTemplate(index)}
                                                        className='costs-btn-costs'>
                                                    <div className='delete-costs'><IconRemove/></div>
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                        {isModalOpen && project && selectedTemplateIndex !== null && (
                            <ModalCalculateCosts
                                templateIndex={selectedTemplateIndex}
                                isOpen={isModalOpen}
                                onSave={handleSave}
                                onClose={() => setIsModalOpen(false)}
                                onTemplateUpdate={handleTemplateUpdate}
                            />
                        )}
                    </ul>
                    <li className='add-template-block'>
                        <button onClick={addTemplatesButton} className='add-template-btn'>Add Project</button>
                        <ReceiptPDF id={project?.id}/>
                    </li>
                </div>
            </div>
            <PaginationAssistance
                projectsPerPage={TEMPLATE_PER_PAGE}
                totalProjects={totalProjects}
                paginate={paginate}
                currentPage={paginationState.currentPage}
            />

            {templateToDeleteIndex !== null && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setTemplateToDeleteIndex(null)}
                    onConfirm={confirmDeleteTemplate}
                    onCancel={() => setTemplateToDeleteIndex(null)}
                    message={`Are you sure you want to delete the Project?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.projectById.isFetching,
    error: state.projectById.error,
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = {
    fetchProjectById,
    updateProject,
    updateLocalTemplate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectCosts);

const BASE_URL_CONTRACTOR = process.env.REACT_APP_BASE_URL_CONTRACTOR;
const BASE_URL_CUSTOMER = process.env.REACT_APP_BASE_URL_CUSTOMER;

if (!BASE_URL_CONTRACTOR) {
    throw new Error('REACT_APP_BASE_URL_CONTRACTOR is not defined in the environment variables.');
}
if (!BASE_URL_CUSTOMER) {
    throw new Error('REACT_APP_BASE_URL_CUSTOMER is not defined in the environment variables.');
}

function isValidISO88591String(str: string): boolean {
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 255) {
            return false;
        }
    }
    return true;
}

function ensureAppUrl(baseUrl: string, url: string = ''): string {
    if (!url) {
        throw new Error("URL is required");
    }
    return url.startsWith(baseUrl) ? url : `${baseUrl}${url}`;
}

const abortControllers: { [key: string]: AbortController } = {};

interface HttpRequestConfig extends RequestInit {}

function getRequestKey(url: string, config: RequestInit): string {
    const baseUrl = url.split('?')[0];
    return `${baseUrl}_${JSON.stringify(config)}`;
}

async function HttpFormData(
    baseUrl: string,
    url: string,
    config: HttpRequestConfig,
    IsAllowMultiRequest: boolean = true,
    abortUrls: string[] = []
): Promise<any> {
    const authToken = localStorage.getItem("Bearer");
    const headers: HeadersInit = {};

    if (authToken) {
        if (!isValidISO88591String(authToken)) {
            throw new Error("Bearer token contains non ISO-8859-1 code point");
        }
        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const requestKey = getRequestKey(url, config);

    if (!IsAllowMultiRequest && abortControllers[requestKey]) {
        abortControllers[requestKey].abort();
    }

    if (!IsAllowMultiRequest) {
        abortUrls.forEach((abortUrl) => {
            const abortRequestKey = getRequestKey(abortUrl, config);
            if (abortControllers[abortRequestKey]) {
                abortControllers[abortRequestKey].abort();
            }
        });
    }

    const controller = new AbortController();
    abortControllers[requestKey] = controller;

    config = {
        ...config,
        headers: {
            ...headers,
            ...config.headers
        },
        signal: controller.signal,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer"
    };

    const request = new Request(ensureAppUrl(baseUrl, url), config);

    try {
        const response = await fetch(request);
        if (response.status === 401 && authToken) {
            console.error('Unauthorized')
        }
        const data = await response.json();
        return { data: data, status: response.status };
    } catch (error) {
        if (error instanceof Error) {
            if (error.name !== 'AbortError') {
                throw error;
            }
        } else {
            console.error('Unexpected error', error);
        }
    }
}

export async function PostFormData(
    baseUrl: string,
    url: string,
    body: FormData,
    config?: HttpRequestConfig,
    IsAllowMultiRequest: boolean = true,
    abortUrls: string[] = []
): Promise<any> {
    const init: HttpRequestConfig = {
        method: "POST",
        body: body,
        ...config
    };

    return await HttpFormData(baseUrl, url, init, IsAllowMultiRequest, abortUrls);
}

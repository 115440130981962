import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedItemsState {
    selectedFragments: number;
    selectedLabor: number;
    selectedTools: number;

    selectedFragmentsTotal: number;
    selectedLaborTotal: number;
    selectedToolsTotal: number;

}

const initialState: SelectedItemsState = {
    selectedFragments: 0,
    selectedLabor: 0,
    selectedTools:0,
    selectedFragmentsTotal: 0,
    selectedLaborTotal: 0,
    selectedToolsTotal:0,

};

const selectedItemsSlice = createSlice({
    name: 'selectedItems',
    initialState,
    reducers: {
        setSelectedItems(state, action: PayloadAction<number> ) {
            state.selectedFragments = action.payload;
        },
        setSelectedLabor(state, action: PayloadAction<number>) {
            state.selectedLabor = action.payload;
        },
        setSelectedTools(state, action: PayloadAction<number>) {
            state.selectedTools = action.payload;
        },
        setSelectedItemsTotal(state, action: PayloadAction<number> ) {
            state.selectedFragmentsTotal = action.payload;
        },
        setSelectedLaborTotal(state, action: PayloadAction<number>) {
            state.selectedLaborTotal = action.payload;
        },
        setSelectedToolsTotal(state, action: PayloadAction<number>) {
            state.selectedToolsTotal = action.payload;
        },
        removeSelectedItems(state, action: PayloadAction<string>) {
            state.selectedFragments = 0
            localStorage.removeItem('selectedFragments');
        },
        removeSelectedLabor(state) {
            state.selectedLabor = 0;
            localStorage.removeItem('LaborItemTypes');
        },
        removeSelectedTools(state) {
            state.selectedTools = 0;
            localStorage.removeItem('ToolsItemTypes');
        },
        clearSelectedItems(state) {
            state.selectedFragments = 0;
            localStorage.removeItem('selectedFragments');
        },
        clearSelectedLabor(state) {
            state.selectedLabor = 0;
            localStorage.removeItem('LaborItemTypes');
        },
        clearSelectedTools(state) {
            state.selectedTools = 0;
            localStorage.removeItem('ToolsItemTypes');
        },
    },
});

export const {
    setSelectedItems,
    setSelectedLabor,
    removeSelectedItems,
    removeSelectedLabor,
    clearSelectedItems,
    clearSelectedLabor,
    setSelectedTools,
    removeSelectedTools,
    clearSelectedTools,
    setSelectedItemsTotal,
    setSelectedToolsTotal,
    setSelectedLaborTotal
} = selectedItemsSlice.actions;

export default selectedItemsSlice.reducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllTemplates } from '../../api/APIWraper';
import { Template } from '../../types/TemplateType';

interface AllTemplatesState {
    templates: Template[];
    totalTemplate: number;
    isFetching: boolean;
    error: string | null;
}

const initialState: AllTemplatesState = {
    templates: [],
    totalTemplate: 0,
    isFetching: false,
    error: null,
};

export const fetchAllTemplates = createAsyncThunk(
    'templates/fetchAllTemplates',
    async ({ page, perPage,queryParams }: { page: number; perPage: number;queryParams?:string }) => {
        const response = await getAllTemplates(page, perPage,queryParams);
        return {
            templates: response.templates,
            totalTemplate: response.pagination?.total || 0,
        };
    }
);

const allTemplatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTemplates.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchAllTemplates.fulfilled, (state, action: PayloadAction<{ templates: Template[]; totalTemplate: number }>) => {
                state.isFetching = false;
                state.templates = action.payload.templates;
                state.totalTemplate = action.payload.totalTemplate;
            })
            .addCase(fetchAllTemplates.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default allTemplatesSlice.reducer;

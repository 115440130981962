import React from 'react';
import '../AboutUs/AboutUs.css'
import CountInfoSection from "../../../components/LandingPageComponent/CountInfoSection";
import InfoBlockBlog from "../../../components/LandingPageComponent/InfoBlockBlog";
import ReviewSlider from "../../../components/LandingPageComponent/ReviewsBlock";



function AboutUs() {

    return (
        <main className='landing-page'>
            <div className='landing-middle-info-nav'>
                <p>About Us</p>
            </div>

            <section className='landing-video-section-about'>
                <div className='upper-block-video-about'>
                    <iframe
                        width="1560"
                        height="500"
                        src="https://www.youtube.com/embed/tO01J-M3g0U"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>

                </div>
            </section>

            <ReviewSlider variant={'compact'}/>
            <CountInfoSection iconVisible={false}/>


            <div className='landing-middle-info-nav'>
                <p>Our Advantages</p>
            </div>
            <InfoBlockBlog/>


            <section className='about-us-section-partners'>
            <div className='about-us-partners-block'>
                    <div className='about-us-info-partners-text'>
                        <p className='about-us-info-block-text-bold'>Our Partners</p>
                        <p className='about-us-info-block-text-partners'>Lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet lorem ipsum sit dalor amet. Lorem ipsum sit dalor amet. </p>
                        <p><img src='/img_landing/autodesk.png' alt='autodesc'/></p>
                    </div>

                    <div className='about-us-info-block-second'></div>
            </div>
                <img className='about-us-image' src='/img_landing/partners.png' alt='partners'/>
            </section>
        </main>
    );
}

export default AboutUs;





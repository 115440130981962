import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TFragmentRequestObject, TItemTypeRequestObject, TTemplateRequestObject} from "../../types/TemplateType";
import {itemTypeAll} from "../../api/APIWraper";


interface ItemTypeState {
    relatedItemTypes: any[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ItemTypeState = {
    relatedItemTypes: [],
    status: 'idle',
    error: null
};

export const fetchItemTypes = createAsyncThunk(
    'itemTypes/fetchItemTypes',
    async (template: TTemplateRequestObject) => {
        const itemTypeIdsSet = new Set<number>();
        template.data.fragments.forEach((fragment: TFragmentRequestObject) => {
            fragment.data.item_types?.forEach((itemType: TItemTypeRequestObject) => {
                itemTypeIdsSet.add(itemType.id);
            });
        });

        const uniqueItemTypeIds = Array.from(itemTypeIdsSet);
        if (uniqueItemTypeIds.length > 0) {
            return await itemTypeAll({ item_type_ids: uniqueItemTypeIds });
        } else {
            throw new Error("No item type ids found");
        }
    }
);

const itemTypesSlice = createSlice({
    name: 'itemTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchItemTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchItemTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.relatedItemTypes = action.payload;
            })
            .addCase(fetchItemTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch item types';
            });
    },
});

export default itemTypesSlice.reducer;
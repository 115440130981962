import React from 'react';
import Modal from 'react-modal';
import './SelectItemModal.css';
import {BOMItem} from "../../types/ProjectBOMInterface";

Modal.setAppElement('#root');

interface SelectItemModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    items: any[];
    onSelect: (selectedItem: any) => void;
}

const SelectItemModal: React.FC<SelectItemModalProps> = ({ isOpen, onRequestClose, items, onSelect }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Select Allowed Item"
            className="step2-bom-modal"
            overlayClassName="step2-bom-modal-overlay"
        >
            <div className="step2-bom-modal-header">
                <h2>Select an Allowed Product</h2>
                <button onClick={onRequestClose} className="step2-bom-modal-close-button">X</button>
            </div>
            <div className='step2-bom-modal-header-content-text'>
                <div className='step2-bom-modal-header-content-text-block'>
                    <p>Title</p>
                    <p>Price</p>
                </div>
            </div>
            <div className="step2-bom-modal-content">
                {items.map((item:BOMItem) => (
                    <div
                        key={item.id}
                        className='step2-bom-modal-item'
                        onClick={() => onSelect(item)}
                    >
                        <p className='step2-bom-modal-item-title'>{item.title}</p>
                        <p className='step2-bom-modal-item-price'>R {Number(item.price).toFixed(2)}</p>
                        <img
                            className="step2-bom-modal-item-img"
                            src={
                                item.vendor === 'cashbuild'
                                    ? item.merchant_info?.image ||
                                    (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                    '/img_vendor/item.png'
                                    : (item.galleries && item.galleries.length > 0 && item.galleries[0].medias.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url) ||
                                    '/img_vendor/item.png'
                            }
                            alt="Item"
                        />
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default SelectItemModal;

import React, { useState } from 'react';
import './stylePaginationAssistance.css';

interface PaginationProps {
    projectsPerPage: number;
    totalProjects: number;
    paginate: (pageNumber: number) => void;
    currentPage: number;
}

const PaginationAssistance: React.FC<PaginationProps> = ({ projectsPerPage, totalProjects, paginate, currentPage }) => {
    const [inputPage, setInputPage] = useState('');

    const totalPages = Math.ceil(totalProjects / projectsPerPage);
    const maxPagesToShow = 3;
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
    } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }


    const handleNextPageClick = () => {
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    };

    return (
        <nav>
            <ul className='pagination'>
                <li className={`page-item ${currentPage === 1 ? 'disabled-pagination' : ''}`}>
                    <a onClick={() => currentPage > 1 && paginate(currentPage - 1)} className='page-link-arrow prev'
                       aria-disabled={currentPage === 1}>Prev</a>
                </li>
                {pageNumbers[0] > 1 && (
                    <>
                        <li className='page-item'>
                            <a onClick={() => paginate(1)} className='page-link'>1...</a>
                        </li>
                    </>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <a onClick={() => paginate(number)} className='page-link'>
                            {number}
                        </a>
                    </li>
                ))}
                {pageNumbers[pageNumbers.length - 1] < totalPages && (
                    <>
                        {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && (
                            <li className='page-item'>
                                <a onClick={() => paginate(totalPages)} className='page-link'>...{totalPages}</a>
                            </li>
                        )}
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled-pagination' : ''}`}>
                    <a onClick={handleNextPageClick}
                       className='page-link-arrow next'
                       aria-disabled={currentPage === totalPages}>
                         Next
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default PaginationAssistance;

import React from 'react';
import '../Functionality/Functionality.css'
import FAQSection from "../../../components/LandingPageComponent/FAQSection";

import SupportForm from "../../../components/LandingPageComponent/SupportForm";


function Support() {

    return (
        <main className='landing-page'>
            <div className='landing-middle-info-support'>
                <p>Support</p>
                <p>Do you have any problem? </p>
                <p>Please submit a support request by filling out the form below.</p>
            </div>
            <SupportForm/>
            <div className='landing-page-support'>
            <FAQSection/>
            </div>
        </main>
    );
}

export default Support;

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UserRoleEnum } from "../Enums/UserRoleEnum";
import {get} from "../api/APIWraper";

interface UserRoleProps {
    redirectPath?: string;
    allowedRoles: UserRoleEnum[];
    children: React.ReactNode;
}

const UserRoleRouteMiddleware: React.FC<UserRoleProps> = ({ redirectPath = '/auth/login', allowedRoles, children }) => {
    const [isAuthorized, setIsAuthorized] = useState(true);
    const [showNavigate, setShowNavigate] = useState(false);
    const [role, setRole] = useState<UserRoleEnum | null>(null);

    useEffect(() => {
        const fetchServerRole = async () => {
            try {
                const responseMe = await get(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/my_profile_info`);
                return responseMe.data.role.role as UserRoleEnum;
            } catch (error) {
                console.error("Error when receiving a role from the server", error);
                return null;
            }
        };
        let lastRole = localStorage.getItem('role') as UserRoleEnum | null;

        const checkRoleChange = async () => {
            const currentRole = localStorage.getItem('role') as UserRoleEnum | null;
            if (currentRole !== lastRole) {
                lastRole = currentRole;

                const serverRole = await fetchServerRole();
                if (serverRole && serverRole !== currentRole) {
                    localStorage.setItem('role', serverRole);
                    setRole(serverRole);
                    setIsAuthorized(false);
                } else {
                    setRole(currentRole);
                }
            }
        };
        const interval = setInterval(checkRoleChange, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const checkRoleAndToken = () => {
            const token = localStorage.getItem('Bearer') || sessionStorage.getItem('Bearer');
            if (!token) {
                setIsAuthorized(false);
                return;
            }

            if (!role || !(role in UserRoleEnum)) {
                setIsAuthorized(false);
                return;
            }

            if (!allowedRoles.includes(role)) {
                setIsAuthorized(false);
                toast.warning('Access to this page is only available with a paid subscription.');
                setTimeout(() => {
                    setShowNavigate(true);
                }, 500);
                return;
            }

            setIsAuthorized(true);
        };

        checkRoleAndToken();
    }, [role, allowedRoles]);

    if (!isAuthorized && showNavigate) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default UserRoleRouteMiddleware;

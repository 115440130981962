import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import '../Step2Bom/Step2Bom.css';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import 'react-toastify/dist/ReactToastify.css';
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {BOMItemType} from "../../types/ProjectBOMInterface";
import {notifyWarningOrderTemplate} from "../../components/Toast/AssisCreateToastNotification";
import FragmentsComponent from "./Components/FragmentComponent";
import {Fragment, ItemType} from "../../types/TemplateType";
import {getCreatedProjectByUrn} from "../ArchitectPage/architectRequests";
import {ArchitectProject} from "../../types/ArhitectProject";
import StepTotalsBlockArchitector from "../../components/TotalsBlockArchitector/StepTotalsBlockArchitector";


const Step2Architect = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [projectArchitect, setProjectArchitect] = useState<ArchitectProject | null>(null);
    const [replace, setReplace] = useState<boolean>(false);
    const [replaceFragment, setReplaceFragment] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<{ [key: string]: any }>({});
    const [selectedItemsStep3, setSelectedItemsStep3] = useState<{ [key: string]: BOMItemType }>({});
    const [buttonSelectDisabled, setButtonSelectDisabled] = useState<boolean>(false);
    const [buttonOrderProjectDisabled, setButtonOrderProjectDisabled] = useState<boolean>(false);

    const {urn} = useParams<{ urn: string }>();

    const [fragmentTotal, setFragmentTotal] = useState(0);

    const [selectedFragmentsStep3, setSelectedFragmentsStep3] = useState<Fragment[]>([]);


    const handleSelectedFragmentsChange = useCallback((fragments: any[]) => {
        return setSelectedFragmentsStep3(fragments);
    }, [setSelectedFragmentsStep3]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (selectedFragmentsStep3.length !== 0) {
            setButtonSelectDisabled(true);
        } else {
            setButtonSelectDisabled(false);
        }
    }, [selectedItemsStep3, projectArchitect, selectedFragmentsStep3]);

    useEffect(() => {
        if (projectArchitect?.data) {
            const { fragments, customFragments } = projectArchitect.data;

            const hasItems = (fragmentList: any) => {
                if (!fragmentList || !fragmentList.length) return false;

                return fragmentList.some((fragment: { data: { item_types?: any[] } }) =>
                    fragment.data.item_types?.some(itemType => itemType.items && itemType.items.length > 0)
                );
            };

            if (hasItems(fragments) || hasItems(customFragments)) {
                setButtonOrderProjectDisabled(false);
            } else {
                setButtonOrderProjectDisabled(true);
            }
        }
    }, [projectArchitect]);





    useEffect(() => {
        const calculateFragmentTotal = () => {
            const selectedFragments = JSON.parse(localStorage.getItem('selectedFragmentArchitect') || '[]');
            if (selectedFragments.length === 0) {
                return 0;
            }

            return selectedFragments.reduce((total: any, fragment: { data: { item_types: ItemType[]; }; }) => {
                const itemTypes = fragment.data?.item_types || [];

                return total + itemTypes.reduce((typeTotal, itemType) => {
                    const items = itemType.items || [];

                    return typeTotal + items.reduce((itemTotal, item) => {
                        const qty = item.qty || 0;
                        const price = parseFloat(String(item.price)) || 0;
                        return itemTotal + qty * price;
                    }, 0);
                }, 0);
            }, 0);
        };

        const fragmentTotal = calculateFragmentTotal();

        setFragmentTotal(fragmentTotal);
    }, [selectedItemsStep3,handleSelectedFragmentsChange,selectedFragmentsStep3]);



    useEffect(() => {
        const storedItems = JSON.parse(localStorage.getItem('selectedItemTypes') || '[]');
        const storedItemsMap = storedItems.reduce((acc: { [key: string]: BOMItemType }, item: BOMItemType) => {
            const uniqueKey = `${item.id}-${item.index}`;
            acc[uniqueKey] = item;
            return acc;
        }, {});
        setSelectedItemsStep3(storedItemsMap);
    }, []);

    useEffect(() => {
        if (urn) {
            const fetchProject = async () => {
                try {
                    const response = await getCreatedProjectByUrn(urn);

                    setProjectArchitect(response.data);
                } catch (error) {
                    console.error("Error fetching project BOM:", error);
                }
            };

            fetchProject();
        }
    }, [urn]);

    useEffect(() => {
        if (!replace) {
            setSelectedItems({});
        }
    }, [replace]);


    const navigateToHome = () => {
        clearLocalStorageExceptToken();
        navigate('/auth/login');
    };

    const onToggle = (who: string) => {
        if (who === 'Fragment') {
            setReplaceFragment(prevState => !prevState);
        }
    };

    if (!projectArchitect) {
        return null;
    }

    const handleOrderSelected = () => {
        const storeSelectedItemsFragment = JSON.parse(localStorage.getItem('selectedFragmentArchitect') || '[]')
        if ( storeSelectedItemsFragment.length !== 0) {
            navigate(`/architect-page/step3/${urn}`);
        } else {
            notifyWarningOrderTemplate('You must choose at least one product');
            return;
        }
    };


    const handleOrderProject = () => {
        const storageKey = 'selectedFragmentArchitect';
        const storedFragments: Fragment[] = JSON.parse(localStorage.getItem(storageKey) || '[]');

        const storedFragmentsMap = new Map(storedFragments.map(fragment => [fragment.id, fragment]));

        const fragments = Array.isArray(projectArchitect?.data?.fragments) ? projectArchitect.data.fragments : [];
        const customFragments = Array.isArray(projectArchitect?.data?.customFragments) ? projectArchitect.data.customFragments : [];

        const processFragments = (fragments: any[]): Fragment[] => {
            return fragments
                .map(fragment => {
                    if (!fragment?.data?.item_types) return null; // Проверяем, есть ли item_types
                    const filteredItemTypes = fragment.data.item_types.filter((itemType: { items?: any[] }) =>
                        Array.isArray(itemType.items) && itemType.items.length > 0
                    );

                    return filteredItemTypes.length > 0
                        ? { ...fragment, data: { ...fragment.data, item_types: filteredItemTypes } }
                        : null;
                })
                .filter((fragment): fragment is Fragment => fragment !== null && !storedFragmentsMap.has(fragment.id));
        };

        const newFragments = processFragments([...fragments, ...customFragments]);

        if (newFragments.length > 0) {
            const updatedFragmentsArray = [...storedFragments, ...newFragments];
            localStorage.setItem(storageKey, JSON.stringify(updatedFragmentsArray));
            handleSelectedFragmentsChange(updatedFragmentsArray);
        }

        setTimeout(() => {
            navigate(`/architect-page/step3/${urn}`);
        }, 100);
    };



    return (
        <div className='step2'>
            <div>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            {to: '/', label: 'Cashbuild'},
                            {to: '', label: '>'},
                            {to: '/', label: 'Home'},
                            {to: '', label: '>'},
                            {to: '/home', label: 'Project assistance'},
                            {to: '', label: '>'},
                            {to: '', label: 'Create new project space'}
                        ]}
                    />
                ) : (
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToHome} className='template-link-assistance'>Project group
                                assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                )}
                <div className='step-info'>
                    <div className='step-info-block'>
                        <h3>Step 2</h3>
                        <span>/</span>
                        <p className='step-info-block-name'>Specify parameters of building</p>
                    </div>
                </div>
                <section className='step2-bom-container'>
                    <div className='step2-bom-cost-summary'>
                        <p className='cost-summary-bom'>Cost summary</p>
                    </div>
                        <>
                            <p className='step2-bom-name-container'>List of fragments</p>
                            <div>
                                <FragmentsComponent
                                    onToggle={onToggle}
                                    projectArchitect={projectArchitect}
                                    replace={replaceFragment}
                                    selectedItemsStep3={selectedItemsStep3}
                                    selectedItems={selectedItems}
                                    setProjectBom={setProjectArchitect}
                                    onSelectedFragmentsChange={handleSelectedFragmentsChange}
                                />
                            </div>
                        </>
                </section>
            </div>

            <StepTotalsBlockArchitector
                project={projectArchitect}
                fragmentTotal={fragmentTotal}
                isSmallScreen={isSmallScreen}
                isVisible={true}
            />

            <div className='step2-page-bottom-btn'>
                <div className='step2-page-bottom-btn-block'>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleOrderSelected}
                            style={{
                                backgroundColor: !buttonSelectDisabled ? '#cccccc' : '',
                                cursor: !buttonSelectDisabled ? 'not-allowed' : 'pointer'
                            }}
                        >
                            Order selected
                        </button>
                    </div>
                    <div className='step2-page-btn'>
                        <button
                            className='step2-page-bottom-block-btn'
                            onClick={handleOrderProject}
                            style={{
                                backgroundColor: buttonOrderProjectDisabled ? '#cccccc' : '',
                                cursor:buttonOrderProjectDisabled ? 'not-allowed' : 'pointer'
                            }}
                            disabled={buttonOrderProjectDisabled}
                        >
                            Order Project
                        </button>

                    </div>
                    <div className='step2-page-btn'>
                        <button className='step2-page-bottom-block-btn'>Save & Request finance</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step2Architect;

import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store';
import { clearSelectedItems, setSelectedTools } from '../../../Slice/SelectedItems/SelectedItemsSlice';
import '../Step2Styles/projectUpdateItems.css';
import IToolsItemType from "../../../Interfaces/ProjectInterfaces/IToolsItemType";
import { getVendorLogo, Vendor } from "../../../Enums/VendorEnum";
import { ItemType, TItem } from "../../../types/TemplateType";
import { IconArrowLightStpe2 } from "../../../IconComponents/IconComponents";
import {
    notifyInfoOrderTemplate,
    notifySuccessOrderTemplate
} from "../../../components/Toast/AssisCreateToastNotification";

interface ToolItemTypeListProps {
    toolsItemTypes: IToolsItemType[];
    viewModeTools: 'Recommended' | 'Minimum';
}

const ToolsItemTypeList: React.FC<ToolItemTypeListProps & PropsFromRedux> = ({
                                                                                 toolsItemTypes,
                                                                                 setSelectedTools,
                                                                                 viewModeTools
                                                                             }) => {
    const [selectedItems, setSelectedItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});
    const [isButtonDisabledOrderFragment, setIsButtonDisabledOrderFragment] = useState(false);

    const generateItemTypeKey = (itemType: TItem, idx: number) => {
        return `${itemType.id}-${idx}-${itemType.clone_id}-${itemType.title}`;
    };
    const generateToolKey = (itemType: IToolsItemType) => {
        return `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
    };

    const generatePayload = (itemType: IToolsItemType, itemIds?: number[]): any => ({
        template_index: itemType.template_index,
        fragment_index: itemType.fragment_index,
        item_type_index: itemType.item_type_index,
        template_id: itemType.template_id,
        fragment_id: itemType.fragment_id,
        item_type_id: itemType.item_type.id,
        item_ids: itemIds || (itemType.item_type.items?.map(i => i.id) || []),
    });

    const toggleVisibility = (itemTypeKey: string) => {
        setVisibilityMap((prevMap) => ({
            ...prevMap,
            [itemTypeKey]: !prevMap[itemTypeKey],
        }));
    };

    const isVisible = (itemTypeKey: string) => {
        return visibilityMap[itemTypeKey] || false;
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const hasItemsInFragments = toolsItemTypes.some((itemType) => itemType.item_type.items && itemType.item_type.items.length > 0);
            setIsButtonDisabledOrderFragment(!hasItemsInFragments);
        };
        checkIfButtonShouldBeDisabled();
    }, [toolsItemTypes]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setSelectedItemsState({});
        setTotalPrice(0);

        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        Object.keys(storedSelectedFragments).forEach(fragmentKey => {
            if (storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                delete storedSelectedFragments[fragmentKey].ToolsItemTypes;
            }
        });

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
    }, [viewModeTools]);

    useEffect(() => {
        syncSelectionsWithLocalStorage(toolsItemTypes);
    }, [toolsItemTypes, viewModeTools]);

    const handleOrderFragment = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        let updatedSelections = { ...selectedItems };

        const isAllSelected = mergedItemTypes.every(itemType => {
            const toolKey = generateToolKey(itemType);
            const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
            return storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey];
        });

        if (isButtonDisabledOrderFragment) {
            notifyInfoOrderTemplate('You must choose a tool');
            return;
        } else if (isAllSelected) {
            mergedItemTypes.forEach(itemType => {
                const toolKey = generateToolKey(itemType);
                const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;

                if (storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey]) {
                    delete storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey];
                }

                itemType.item_type.items?.forEach(toolItem => {
                    const itemKey = `${toolKey}-${toolItem.id}`;
                    updatedSelections[itemKey] = false;
                });
            });

            notifyInfoOrderTemplate('Tools removed from cart');
        } else {
            mergedItemTypes.forEach(itemType => {
                const toolKey = generateToolKey(itemType);
                const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;

                if (!storedSelectedFragments[fragmentKey]) {
                    storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                }
                if (!storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                    storedSelectedFragments[fragmentKey].ToolsItemTypes = {};
                }

                if (!storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey]) {
                    storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey] = {
                        ...itemType.item_type,
                        items: itemType.item_type.items?.map(item => ({
                            ...item,
                            payload: generatePayload(itemType),
                        })),
                        payload: generatePayload(itemType),
                    };
                }

                itemType.item_type.items?.forEach(toolItem => {
                    const itemKey = `${toolKey}-${toolItem.id}`;
                    updatedSelections[itemKey] = true;
                });
            });

            notifySuccessOrderTemplate('Tools added to cart');
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));

        setSelectedItemsState(updatedSelections);
        setSelectedTools(Date.now());

        const calculatedPrice = calculateTotalPrice(mergedItemTypes, updatedSelections, viewModeTools);
        setTotalPrice(calculatedPrice);
    };


    const mergeItemTypes = (items: IToolsItemType[]) => {
        const mergedItemsMap: { [key: number]: IToolsItemType } = {};

        items.forEach(item => {
            const itemTypeId = item.item_type.id;

            if (mergedItemsMap[itemTypeId]) {
                const existingItemType = mergedItemsMap[itemTypeId];

                item.item_type.items?.forEach(toolItem => {
                    const existingToolItem = existingItemType.item_type.items?.find(ti => ti.id === toolItem.id);

                    if (existingToolItem) {
                        existingToolItem.qty = viewModeTools === 'Minimum'
                            ? Math.max(existingToolItem.qty, toolItem.qty)
                            : existingToolItem.qty + toolItem.qty;

                        existingToolItem.ordered_qty = viewModeTools === 'Minimum'
                            ? Math.max(existingToolItem.ordered_qty ?? 0, toolItem.ordered_qty ?? 0)
                            : (existingToolItem.ordered_qty ?? 0) + (toolItem.ordered_qty ?? 0);
                    } else {
                        existingItemType.item_type.items?.push({ ...toolItem });
                    }
                });
            } else {
                mergedItemsMap[itemTypeId] = JSON.parse(JSON.stringify(item));
            }
        });

        return Object.values(mergedItemsMap);
    };

    const syncSelectionsWithLocalStorage = (items: IToolsItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const updatedToolSelections: { [key: string]: boolean } = {};

        items.forEach(item => {
            const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
            const toolKey = `${item.item_type.id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;

            if (storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey]) {
                item.item_type.items?.forEach(toolItem => {
                    const itemKey = `${toolKey}-${toolItem.id}`;
                    if (storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey].items.some((i: any) => i.id === toolItem.id)) {
                        updatedToolSelections[itemKey] = true;
                    }
                });
            }
        });

        setSelectedItemsState(updatedToolSelections);
    };

    const saveToLocalStorage = (item: IToolsItemType, toolItem: any, toolsItemTypes: IToolsItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        toolsItemTypes.forEach((originalItem) => {
            if (originalItem?.item_type?.id === item?.item_type?.id) {
                originalItem?.item_type?.items?.forEach((originalToolItem) => {
                    if (
                        originalToolItem?.id === toolItem?.id &&
                        (viewModeTools === 'Recommended' ||
                            (viewModeTools === 'Minimum' &&
                                originalToolItem?.qty === toolItem?.qty))
                    ) {
                        const fragmentKey = `${originalItem?.fragment_id}-${originalItem?.fragment_index}-${originalItem?.template_index}`;

                        if (!storedSelectedFragments[fragmentKey]) {
                            storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                        }

                        const toolKey = `${originalItem?.item_type?.id}-${originalItem?.clone_id || originalItem?.item_type_index}-${originalItem?.fragment_index}`;

                        if (!storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                            storedSelectedFragments[fragmentKey].ToolsItemTypes = {};
                        }

                        if (!storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey]) {
                            storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey] = {
                                id: originalItem?.item_type?.id,
                                qty: originalItem?.qty || 0,
                                tool: true,
                                items: [],
                                labor: originalItem?.item_type?.labor || false,
                                title: originalItem?.item_type?.title || '',
                                percentage: originalItem?.item_type?.percentage || 0,
                                ordered_qty: 0,
                                originalIndex: originalItem?.item_type_index,
                                clone_id: originalItem?.clone_id || originalItem?.item_type_index,
                                payload: generatePayload(originalItem, [originalToolItem?.id])
                            };
                        }

                        const selectedToolType = storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey];

                        selectedToolType?.items?.push({
                            id: originalToolItem?.id,
                            qty: originalToolItem?.qty || 0,
                            unit: originalToolItem?.unit || '',
                            qty_from_store:originalToolItem.qty_from_store ? originalToolItem.qty_from_store : 0,
                            price: originalToolItem?.price || 0,
                            title: originalToolItem?.title || '',
                            ordered_qty: originalToolItem?.ordered_qty || 0,
                            created_at: originalToolItem?.created_at || '',
                            updated_at: originalToolItem?.updated_at || '',
                            payload: generatePayload(originalItem, [originalToolItem?.id])
                        });

                        selectedToolType.ordered_qty += originalToolItem?.ordered_qty || 0;
                        selectedToolType.payload.item_ids.push(originalToolItem?.id);
                    }
                });
            }
        });

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
    };

    const removeFromLocalStorage = (item: IToolsItemType, toolItem: any, toolsItemTypes: IToolsItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        toolsItemTypes.forEach((originalItem) => {
            if (originalItem.item_type.id === item.item_type.id) {
                originalItem.item_type.items?.forEach((originalToolItem) => {
                    if (originalToolItem.id === toolItem.id) {
                        const fragmentKey = `${originalItem.fragment_id}-${originalItem.fragment_index}-${originalItem.template_index}`;
                        const toolKey = `${originalItem.item_type_id}-${originalItem.clone_id || originalItem.item_type_index}-${originalItem.fragment_index}`;

                        const selectedToolType = storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey];

                        if (selectedToolType) {
                            selectedToolType.items = selectedToolType.items.filter((i: any) => i.id !== originalToolItem.id);

                            if (selectedToolType.items.length === 0) {
                                delete storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey];
                            }
                            if (!storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                                storedSelectedFragments[fragmentKey].ToolsItemTypes = {};
                            }

                            if (!storedSelectedFragments[fragmentKey].items) {
                                storedSelectedFragments[fragmentKey].items = {};
                            }

                            if (!storedSelectedFragments[fragmentKey].LaborItemTypes) {
                                storedSelectedFragments[fragmentKey].LaborItemTypes = {};
                            }
                        }
                    }
                });
            }
        });

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
    };

    const handleCheckboxChange = (toolKey: string, item: IToolsItemType, toolItem: any) => {
        const isSelected = !selectedItems[toolKey];
        const updatedSelections = { ...selectedItems, [toolKey]: isSelected };
        setSelectedItemsState(updatedSelections);

        if (isSelected) {
            saveToLocalStorage(item, toolItem, toolsItemTypes);
        } else {
            removeFromLocalStorage(item, toolItem, toolsItemTypes);
        }

        setSelectedTools(Date.now());
    };

    const calculateTotalPrice = (items: IToolsItemType[], selected: { [key: string]: boolean }, viewModeTools: 'Recommended' | 'Minimum') => {
        let totalPrice = 0;

        items.forEach(item => {
            const toolKey = `${item.item_type.id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;

            item.item_type.items?.forEach(toolItem => {
                const itemKey = `${toolKey}-${toolItem.id}`;
                if (selected[itemKey]) {
                    const price = parseFloat(String(toolItem.price)) || 0;
                    let qty = toolItem.qty;
                    if (viewModeTools === 'Minimum') {
                        qty = toolItem.qty;
                    } else {
                        qty = items.reduce((sum, currentItem) => {
                            const combinedToolItem = currentItem.item_type.items?.find(i => i.id === toolItem.id);
                            return sum + (combinedToolItem ? combinedToolItem.qty : 0);
                        }, 0);
                    }
                    const difTotalQty = qty - toolItem.ordered_qty;
                    const priceTotal = difTotalQty > 0 ? price * difTotalQty : 0;
                    totalPrice += priceTotal;
                }
            });
        });

        return totalPrice;
    };
    const mergedItemTypes = mergeItemTypes(toolsItemTypes);

    useEffect(() => {
        const calculatedPrice = calculateTotalPrice(mergedItemTypes, selectedItems, viewModeTools);
        setTotalPrice(calculatedPrice);
        setSelectedTools(Date.now());
    }, [selectedItems, mergedItemTypes, setSelectedTools, viewModeTools]);

    return (
        <div>
            {mergedItemTypes.map((itemType, index) => {
                const toolKey = generateToolKey(itemType);
                const title = itemType.item_type.title;

                const totalQty = viewModeTools === 'Recommended'
                    ? toolsItemTypes
                        .filter((it) => it.item_type.id === itemType.item_type.id)
                        .reduce((sum, it) => sum + it.qty, 0)
                    : Math.max(
                        ...toolsItemTypes
                            .filter((it) => it.item_type.id === itemType.item_type.id)
                            .map((it) => it.qty)
                    );

                return (
                    <div key={toolKey}>
                        <p className="item-type-list-label">{title}</p>
                        {itemType.item_type.items && itemType.item_type.items.length > 0 ? (
                            itemType.item_type.items.map((toolItem, toolIndex) => {
                                const checkTotalOrderedQty = !isNaN(toolItem.ordered_qty) ? Math.max(toolItem.ordered_qty || 0, 0) : 0;
                                const checkTotalQty = !isNaN(toolItem.qty) ? Math.max(toolItem.qty || 0, 0) : 0;
                                const price = parseFloat(String(toolItem.price)) || 0;
                                const totalPriceOrdered = price * checkTotalOrderedQty;
                                const totalPriceRemaining = price * (checkTotalQty - checkTotalOrderedQty);
                                const checkTotalPriceRemaining = Math.max(totalPriceRemaining, 0);
                                const unit = toolItem.unit || '';
                                const toolKeyVisible = generateItemTypeKey(toolItem, toolIndex);
                                return (
                                    !isSmallScreen ? (
                                        <div key={`${toolKey}-${toolItem.id}`} className='step2-item-type-list'>
                                            <div className="step2-material-width-list">
                                                <div className="step2-item-checkbox-block">
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-${toolKey}-${toolItem.id}`}
                                                        checked={selectedItems?.[`${toolKey}-${toolItem.id}`] || false}
                                                        onChange={() => handleCheckboxChange(`${toolKey}-${toolItem.id}`, itemType, toolItem)}
                                                    />
                                                    <label
                                                        htmlFor={`checkbox-${toolKey}-${toolItem.id}`}>{toolItem.title}</label>
                                                </div>
                                            </div>
                                            <p className="step2-other-width-list">{checkTotalOrderedQty} ({unit})</p>
                                            <p className="step2-other-width-list">{checkTotalQty} ({unit})</p>
                                            <p className="step2-other-width-list">R {price.toFixed(2)}</p>
                                            <p className="step2-other-width-list">R {totalPriceOrdered.toFixed(2)}</p>
                                            <p className="step2-other-width-list-total">R {checkTotalPriceRemaining.toFixed(2)}</p>
                                            <div className='step2-other-width-vendor-list'>
                                                {itemType.item_type.items[0].vendor ? (
                                                    <p className='step3-vendor-img'>{itemType.item_type.items[0].vendor}</p>
                                                ) : (
                                                    <p className='item-selection-modal-no-vendor'>No
                                                        Vendor</p>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={`${toolKey}-${toolItem.id}`} className='step2-item-type-list'>
                                            <div className="step2-material-width-list">
                                                <div
                                                    className={`step2-item-checkbox-block ${isVisible(toolKeyVisible) ? 'visible' : 'hidden'}`}>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-${toolKey}-${toolItem.id}`}
                                                        checked={selectedItems?.[`${toolKey}-${toolItem.id}`] || false}
                                                        onChange={() => handleCheckboxChange(`${toolKey}-${toolItem.id}`, itemType, toolItem)}
                                                    />
                                                    <label htmlFor={`checkbox-${toolKey}-${toolItem.id}`}>{toolItem.title}</label>
                                                    <div onClick={() => toggleVisibility(toolKeyVisible)}
                                                         className={isVisible(toolKeyVisible) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                                        <IconArrowLightStpe2 />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`step2-content-list ${isVisible(toolKeyVisible) ? 'visible' : 'hidden'}`}>
                                                <p className="step2-other-width-list">
                                                    <span>Calculated QTY:</span>
                                                    {isSmallScreen && <span className='dashed-line'></span>}
                                                    {checkTotalOrderedQty} ({unit})
                                                </p>
                                                <p className="step2-other-width-list">
                                                    <span>QTY ordered:</span>
                                                    {isSmallScreen && <span className='dashed-line'></span>}
                                                    {checkTotalQty} ({unit})
                                                </p>
                                                <p className="step2-other-width-list">
                                                    <span>Unit price:</span>
                                                    {isSmallScreen && <span className='dashed-line'></span>}
                                                    R {price.toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list">
                                                    <span>Total:</span>
                                                    {isSmallScreen && <span className='dashed-line'></span>}
                                                    R {totalPriceOrdered.toFixed(2)}
                                                </p>
                                                <p className="step2-other-width-list-total">
                                                    <span>Total remaining:</span>
                                                    {isSmallScreen && <span className='dashed-line'></span>}
                                                    R {checkTotalPriceRemaining.toFixed(2)}
                                                </p>
                                                <div className='step2-other-width-vendor-list'>
                                                    {itemType.item_type.items[0].vendor ? (
                                                        <p className='step3-vendor-img'>{itemType.item_type.items[0].vendor}</p>
                                                    ) : (
                                                        <p className='item-selection-modal-no-vendor'>No
                                                            Vendor</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                );
                            })
                        ) : (
                            (!isSmallScreen ? (
                                <div key={toolKey}>
                                    <div className="step2-item-type-list ">
                                        <div className='step2-material-width-list'>
                                            <label style={{color: "#828282"}}>{title}</label>
                                        </div>
                                        <p className="step2-other-width-list">0</p>
                                        <p className="step2-other-width-list">{totalQty}</p>
                                        <p className="step2-other-width-list">R 0.00</p>
                                        <p className="step2-other-width-list">R 0.00</p>
                                        <p className="step2-other-width-list-total">R 0.00</p>
                                        <div className='step2-other-width-vendor-list'></div>
                                    </div>
                                </div>
                            ) : (
                                <div key={toolKey} className={`step2-item-type-list`}>
                                    <div className={`step2-item-checkbox-block ${isVisible(toolKey) ? 'visible' : 'hidden'}`}>
                                        <label style={{ color: "#828282" }}>{title}</label>
                                        <div onClick={() => toggleVisibility(toolKey)}
                                             className={isVisible(toolKey) ? 'icon-arrow-light-step2-up' : 'icon-arrow-light-step2-down'}>
                                            <IconArrowLightStpe2 />
                                        </div>
                                    </div>

                                    <div className={`step2-content-list ${isVisible(toolKey) ? 'visible' : 'hidden'}`}>
                                        <p className="step2-other-width-list">
                                            <span>Calculated QTY:</span>
                                            <span className="dashed-line"></span>0
                                        </p>
                                        <p className="step2-other-width-list">
                                            <span>QTY ordered:</span>
                                            <span className="dashed-line"></span>{totalQty}
                                        </p>
                                        <p className="step2-other-width-list">
                                            <span>Unit price:</span>
                                            <span className="dashed-line"></span>R 0.00
                                        </p>
                                        <p className="step2-other-width-list">
                                            <span>Total:</span>
                                            <span className="dashed-line"></span>R 0.00
                                        </p>
                                        <p className="step2-other-width-list-total total-ordred-bold">
                                            <span>Total ordered:</span>
                                            <span className="dashed-line"></span>R 0.00
                                        </p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                );
            })}

            <div className='step2-bottom-block'>
                {!isSmallScreen ? (
                    <>
                        <p>Total:</p>
                        {totalPrice ? (
                            <span> R {totalPrice.toFixed(2)}</span>
                        ) : (
                            <span> R 0</span>
                        )}
                        <button className='step2-bottom-block-btn-cart-list'
                                onClick={handleOrderFragment}
                                style={{
                                    backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                    cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                                }}
                        >Add products to cart
                        </button>
                    </>
                ) : (
                    <>
                        <div className='step2-bottom-block-mobile'>
                            <p>Total: </p>
                            <span className="dashed-line"></span>
                            {totalPrice ? (
                                <span className='mobile-total-price-red'> R {totalPrice.toFixed(2)}</span>
                            ) : (
                                <span className='mobile-total-price-red'> R 0</span>
                            )}
                        </div>
                        <button className='step2-bottom-block-btn-cart-list'
                                onClick={handleOrderFragment}
                                style={{
                                    backgroundColor: isButtonDisabledOrderFragment ? '#cccccc' : '',
                                    cursor: isButtonDisabledOrderFragment ? 'not-allowed' : 'pointer'
                                }}
                        >Add products to cart
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    oldProject: state.projectById.oldProject,
});

const mapDispatchToProps = {
    setSelectedTools,
    clearSelectedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToolsItemTypeList);

import { Helmet } from "react-helmet";
import { useEffect, useState, ReactNode } from "react";
import { get } from "../api/APIWraper";
import { useLocation } from "react-router-dom";

interface SeoWrapperProps {
    children: ReactNode;
}
const BASE_URL = process.env.REACT_APP_BASE_URL;
const SeoWrapper = ({ children }: SeoWrapperProps) => {
    const location = useLocation();
    const path = location.pathname;
    const [title, setTitle] = useState<string>('Bill of Material');
    const [description, setDescription] = useState<string>(
        'Create projects for construction, get access to the best building materials and templates. Projects can be accessed by everyone, including unauthorized users, with a paid subscription.'
    );
    const [keywords, setKeywords] = useState<string>(
        'construction, construction projects, construction materials, bricks, cement, water, paint, project templates, project access, paid project subscription, projects for construction'    );

    useEffect(() => {
        const fetchSeoData = async () => {
            try {
                const response = await get(`${BASE_URL}seo?page_url=${path}&with[]=seo_title&with[]=meta_description&with[]=meta_keywords`);
                if (response.data.data[0].seo_title) setTitle(response.data.data[0].seo_title);
                if (response.data.data[0].meta_description) setDescription(response.data.data[0].meta_description);
                if (response.data.data[0].meta_keywords) setKeywords(response.data.data[0].meta_keywords);
            } catch (error) {
                console.error(`Error fetching SEO data for ${path}:`, error);
            }
        };

        fetchSeoData();
    }, [path]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
            {children}
        </>
    );
};

export default SeoWrapper;

import {Project} from "../types/ProjectTypes";
import {CreateBomPayload, CreateProjectPayload} from "../types/ApiTypes";
import {BOMProject} from "../types/ProjectBOMInterface";

const BASE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER
const BASE_URL_CONTRACTOR = process.env.REACT_APP_BASE_URL_CONTRACTOR
const BASE_LINK_BOM = process.env.REACT_APP_BASE_LINK_BOM


export async function http(url: string, config: {}): Promise<any> {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';

    const bearerToken = rememberMe
        ? localStorage.getItem('Bearer')
        : sessionStorage.getItem('Bearer');

    config = {
        ...config,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
            'Access-Control-Allow-Origin': `${BASE_URL}`,
            'Origin': `${BASE_URL}`,
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrer: 'no-referrer',
    };

    const request = new Request(url, config);

    const response = await fetch(request);
    const data = await response.json();

    return { data: data, status: response.status };
}



export async function post(url: string, body?: {}, config?: {}): Promise<any> {
    const init = {
        method: "POST",
        body: JSON.stringify(body),
        ...config
    }
    return await http(url, init)
}


export async function get(url: string, config?: {}) {
    const init = {
        method: "GET",
        ...config
    }
    return await http(url, init)
}

export async function Delete(url: string, config?: {}): Promise<any> {
    const init = {
        method: "DELETE",
        ...config
    }
    return await http(url, init)
}
export async function patch(url: string, body: {}, config?: {}): Promise<any> {
    const init = {
        method: "PATCH",
        body: JSON.stringify(body),
        ...config
    }
    return await http(url, init)
}


/*
* Projects Api
*/

export async function getAllProjectsAssistance(
    page: number = 1,
    perPage: number = 11,
    sortBy?: string,
    sortOrder?: string,
    queryParams?: string
) {
    let url = `${BASE_URL}/projects?page=${page}&perPage=${perPage}`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    if (queryParams) {
        url += queryParams;
    }

    const response = await get(url);
    return {
        allProjects: response.data.data,
        total: response.data.pagination.total
    };
}


export const postCreateBom = async (payload: CreateBomPayload): Promise<{ status: number; data: any }> => {
    const url = `${BASE_URL_CONTRACTOR}/bom_projects`;
    return await post(url, payload);
};

export const patchCreateBom = async (payload: CreateBomPayload, projectId:number): Promise<{ status: number; data: any }> => {
    const url = `${BASE_URL_CONTRACTOR}/bom_projects/${projectId}`;
    return await patch(url, payload);
};


export const postBomEditItems = async (
    payload: { item_types: { item: number | any; index: number; id: number,qty_ordered?:number }[] },
    link: string | undefined
): Promise<{ bomProject: BOMProject; status: number }> => {
    const url = `${BASE_LINK_BOM}bom_projects/${link}/edit_items`;
    const response = await post(url, payload);

    return {
        bomProject: response.data,
        status: response.status
    };
};



export async function getProjectBom(link:string)
{
    let url = `${BASE_LINK_BOM}bom_projects/${link}`
    const response = await get(url)
    return response.data
}

export async function getProjectBomById(projectId: string | number)
{
    let url = `${BASE_URL_CONTRACTOR}/bom_projects/${projectId}`
    const response = await get(url)
    return response.data
}

export async function deleteProjectBomById(projectId:number)
{
    const url = `${BASE_URL_CONTRACTOR}/bom_projects/${projectId}`
    return await Delete(url)
}

export async function getAllProjectsBom(
    page: number = 1,
    perPage: number = 11,
    sortBy?: string,
    sortOrder?: string,
    queryParams?: string
) {
    let url = `${BASE_URL_CONTRACTOR}/bom_projects?page=${page}&perPage=${perPage}`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    if (queryParams) {
        url += queryParams;
    }

    const response = await get(url);
    return {
        allProjectsBom: response.data.data,
        total: response.data.pagination.total
    };
}

export async function getLastProjectsBom(page: number = 1, perPage: number = 11, sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL_CONTRACTOR}/bom_projects?page=${page}&perPage=${perPage}&with[]=title&with[]=created_at`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        projectsBom: response.data.data,
        pagination: response.data.pagination,
        status: response.status
    };
}





export async function getLastProjectsAssistance(page: number = 1, perPage: number = 11, sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/projects?page=${page}&perPage=${perPage}&with[]=title&with[]=created_at`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        projects: response.data.data,
        pagination: response.data.pagination
    };
}

export async function getAllProjectsAssistanceTemplateModal(page: number = 1, perPage: number = 11,queryParams?:string) {
    let url = `${BASE_URL}/projects?page=${page}&perPage=${perPage}${queryParams}`;

    const response = await get(url);
    return {
        projects: response.data.data,
        pagination: response.data.pagination
    };
}



export async function getProjectById(projectId:string,sortBy?: string, sortOrder?: string)
{
    let url = `${BASE_URL}/projects/${projectId}`
    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url)
    return response.data
}

export async function postSendEmail(body:{}){
    const url = `${BASE_URL}/mailing`
    return await post(url, body)
}

export const postCreateProject = async (payload: CreateProjectPayload): Promise<{ data: Project }> => {
    const url = `${BASE_URL}/projects`;
    return await post(url, payload);
};

export async function updateProject(projectId: number, body: {}){
    const url =`${BASE_URL}/projects/${projectId}`
    return await patch(url,body)
}
export async function deleteProjectById(projectId:number)
{
    const url = `${BASE_URL}/projects/${projectId}`
    return await Delete(url)
}

export async function getAllTemplates(page: number = 1, perPage: number = 8, queryParams?: string) {
    let url = `${BASE_URL}/templates?page=${page}&perPage=${perPage}`;

    if (queryParams) {
        url += queryParams;
    }

    const response = await get(url);
    return {
        templates: response.data.data,
        pagination: response.data.pagination
    };
}


export async function getTemplateById(templateId: number, queryParams?: string) {
    let url = `${BASE_URL}/templates/${templateId}`;

    if (queryParams) {
        url += queryParams;
    }

    const response = await get(url);
    return response.data;
}


export async function getAllStores()
{
    const url = `${BASE_URL}/stores?page=1&perPage=100`;
    const response = await get(url)
    return response.data
}

export async function addTemplatesToProjects(projectId:number,body:{}){
    const url =`${BASE_URL}/templates/${projectId}/add_to_projects`
    return await patch(url,body)
}
export async function updatePermissions(projectId: string, body: { email: string; permissions: string[] }) {
    const url = `${BASE_URL}/projects/${projectId}/permissions`;
    return await post(url, body);
}

export async function getUsersFromOwnProjects(page: number, perPage: number,sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/users_from_own_projects?page=${page}&perPage=${perPage}`;
    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        users: response.data,
        total:response.data.pagination
    };
}

export async function deleteUserProjectsPermissions(user_id: number, body: {projects_id:number[]; permissions: string[] })
{
    const url = `${BASE_URL}/users/${user_id}/permissions`;
    return await patch(url,body)
}

export async function itemTypeAll( body: {item_type_ids:number[]}) {
    const url = `${BASE_URL}/item_types_all`;
    const response = await post(url, body);
    return response.data.data
}

export async function getOrderHistory(page: number = 1, perPage: number = 10,sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/order_history?page=${page}&perPage=${perPage}`;
    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        orders: response.data.data,
        totalOrders: response.data.pagination.total,
    };
}

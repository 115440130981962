import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Fragment } from "../../types/TemplateType";
import { Project } from "../../types/ProjectTypes";
import './step2HeaderList.css';
import "../../Page/Step2/Step2Styles/step2.css";
import HeaderList from "./HeaderList";
import ProgressBarFragment from "../ProgressBar/ProgressBarFragment";
import SwitcherTools from "../Switcher/SwitcherTools";
import {IconArrowDownStep2, IconSettingsStep2, IconSettingsStep2Products} from "../../IconComponents/IconComponents";
import { RootState } from "../../store";
import {
    toggleFragmentVisibility,
    toggleLaborVisibility,
    toggleToolVisibility
} from "../../Slice/ClickSlice/FragmentVisibityState";


interface MaterialNameOwnProps {
    fragmentIndex?: number;
    fragment?: Fragment;
    project: Project;
    onToggle?: () => void;
    showSettingsButton?: boolean;
    visibleStep3?:boolean;
    isLabor?: boolean;
    isTool?: boolean;
    isToolStep2?: boolean;
    handleSwitchChangeTools?: (value: 'Recommended' | 'Minimum') => void;
}

type MaterialNameProps = MaterialNameOwnProps & PropsFromRedux;

const MaterialName: React.FC<MaterialNameProps> = ({
                                                       fragment,
                                                       onToggle,
                                                       fragmentIndex,
                                                       showSettingsButton,
                                                       isLabor,
                                                       isTool,
                                                       isToolStep2,
                                                       handleSwitchChangeTools,
                                                       isVisible,
                                                       visibleStep3,
                                                       toggleFragmentVisibility,
                                                       toggleLaborVisibility,
                                                       toggleToolVisibility,
                                                       isLaborVisible,
                                                       isToolVisible
                                                   }) => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    const handleToggleVisibility = () => {
        if (fragment && fragmentIndex !== undefined) {
            toggleFragmentVisibility(fragment.id, fragmentIndex);
        }
    };

    const handleToggleLaborVisibility = () => {
        toggleLaborVisibility();
    };

    const handleToggleToolVisibility = () => {
        toggleToolVisibility();
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const visibleStyle = isVisible ? 'material-setting-block-arrow-up' : 'material-setting-block-arrow-down';
    const laborVisibleStyle = isLaborVisible ? 'material-setting-block-arrow-up' : 'material-setting-block-arrow-down';
    const toolVisibleStyle = isToolVisible ? 'material-setting-block-arrow-up' : 'material-setting-block-arrow-down';

    return (
        <>
            <div className='new-step2-material-list'>
                <div className='new-step2-material-list-name'>
                    {isLabor && (
                        <div>
                        <p className='project-item-update-material-list-name-name'>
                            Labor required for task (optional)
                        </p>
                        <small className='labor-small-text'>Indicative labor costs for each section, not purchasable.</small>
                        </div>
                    )}
                    {isTool && (
                        <p className='project-item-update-material-list-name-name'>
                            Tools required for task (optional)
                        </p>
                    )}
                    {isToolStep2 && (
                        <>
                            <p className='project-item-update-material-list-name-name'>
                                Tools required for task (optional)
                            </p>
                            {isToolVisible && (
                                <SwitcherTools
                                    options={['Recommended', 'Minimum']}
                                    onChange={handleSwitchChangeTools || (() => {
                                    })}
                                />
                            )}
                        </>
                    )}

                    {fragment && <p className='new-step2-material-list-name-name'>{fragment.title}</p>}
                    {fragment && (
                        <div className='new-step2-progress'>
                            <ProgressBarFragment fragment={fragment} />
                        </div>
                    )}
                </div>
                <div>
                    {isSmallScreen ? (
                        <div className='material-setting-block-mobile'>
                        <div
                                    className={isLabor ? laborVisibleStyle : (isToolStep2 || isTool) ? toolVisibleStyle : visibleStyle}
                                    onClick={isLabor ? handleToggleLaborVisibility : (isToolStep2 || isTool) ? handleToggleToolVisibility : handleToggleVisibility}
                                >
                                    <IconArrowDownStep2 />
                                </div>
                                {(isLabor ? isLaborVisible : isToolStep2 ? isToolVisible : isVisible) && (
                                    <div onClick={onToggle}>
                                        {showSettingsButton && (<IconSettingsStep2 />)}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                {showSettingsButton && (
                                    <div className='material-setting-block' onClick={onToggle}>
                                        <div><IconSettingsStep2Products/></div>
                                        <p>{isTool ? 'Amend Tools' : isLabor ? 'Amend Labor' : 'Amend Products'}</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

            </div>
            {(isToolStep2 || isTool) && (
                <small className='labor-small-text'>You can select the tools you require, these are purchasable from
                    the supplier you select.</small>

            )}
            {!isSmallScreen && (
                <HeaderList showSettingsButton={showSettingsButton} isTool={isTool} isLabor={isLabor}/>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: MaterialNameOwnProps) => {
    const { fragment, fragmentIndex } = ownProps;
    return {
        isVisible: fragment ? state.fragmentVisible.visibleFragments[`${fragment.id}-${fragmentIndex}`] : false,
        isLaborVisible: state.fragmentVisible.isLaborVisible,
        isToolVisible: state.fragmentVisible.isToolVisible,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    toggleFragmentVisibility: (fragment_id: number, fragmentIndex?: number) => {
        if (fragmentIndex !== undefined) {
            dispatch(toggleFragmentVisibility({ fragment_id, fragmentIndex }));
        }
    },
    toggleLaborVisibility: () => dispatch(toggleLaborVisibility()),
    toggleToolVisibility: () => dispatch(toggleToolVisibility()),
});


const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MaterialName);

import React, { useEffect, useState } from 'react';
import '../../components/Style List View/style.css';
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/Modals/ModalDelete/ModalDelete";
import {
    IconProjectGroup,
    IconRemove, IconSettings,
} from "../../IconComponents/IconComponents";
import { Loader } from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import { unifiedColumnFieldMapping } from "../../utils/ColumnFieldSorting";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import {deleteProjectArchitectById, getAllProjectsArchitect} from "../../api/APIWraper";
import {toast} from "react-toastify";
import {RootState} from "../../store";
import {useSelector} from "react-redux";
import {ArchitectProject} from "../../types/ArhitectProject";

const ProjectsListArchitect: React.FC = () => {
    const PROJECTS_PER_PAGE = 10;

    const navigate = useNavigate();
    const [projects, setProjects] = useState<ArchitectProject[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [projectToDelete, setProjectToDelete] = useState<ArchitectProject | null>(null);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [totalProjects, setTotalProjects] = useState<number>(0);

    const [paginationState, setPaginationState] = useState<{
        currentPage: number;
        sortBy: string | null;
        sortOrder: 'asc' | 'desc' | null;
    }>({
        currentPage: 1,
        sortBy: null,
        sortOrder: null
    });

    const handleSortChange = (column: string) => {
        const field = unifiedColumnFieldMapping[column];
        setPaginationState(prevState => ({
            ...prevState,
            sortBy: field,
            sortOrder: prevState.sortBy === field ? (prevState.sortOrder === 'asc' ? 'desc' : 'asc') : 'asc',
            currentPage: 1
        }));
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchAllProjectArchitect = async () => {
        const { currentPage, sortBy, sortOrder } = paginationState;
        setIsFetching(true);
        try {
            const response = await getAllProjectsArchitect(
                currentPage,
                PROJECTS_PER_PAGE,
                sortBy ?? undefined,
                sortOrder ?? undefined,
                undefined
            );
            setProjects(response.allProjectsArchitect);
            setTotalProjects(response.total);
        } catch (error) {
            console.error("Failed to fetch projects", error);
        } finally {
            setIsFetching(false);
        }
    };


    useEffect(() => {
        if (isAuthenticated) {
            fetchAllProjectArchitect()

        } else {
            navigate('/auth/login');
        }
    }, [paginationState, isAuthenticated, navigate]);

    const paginate = (pageNumber: number) => {
        setPaginationState(prevState => ({
            ...prevState,
            currentPage: pageNumber
        }));
    };

    const handleDelete = (project: ArchitectProject) => {
        setProjectToDelete(project);
    };

    const handleRemoveProject = async () => {
        if (projectToDelete) {
            try {
                const response = await deleteProjectArchitectById(projectToDelete.id)
                if (response.status !== 200) {
                    toast.error("Failed to delete project");
                }
                setProjectToDelete(null);
                setProjects(prevProjects => prevProjects.filter(project => project.id !== projectToDelete.id));
            } catch (error) {
                console.error("Error when deleting a project:", error);
                setError("Error when deleting a project");
            }
        }
    };

    const handleNavigate = (project:ArchitectProject) => {
        navigate(`/architect-page/${project.autodesk_project.type}/${project.autodesk_project.urn}`, {state: {id: project.id}})
    };

    if (isFetching) return <Loader />;
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        { to: '/', label: 'Cashbuild' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Home' },
                        { to: '', label: '>' },
                        { to: '/assistance', label: 'Project assistance' },
                        { to: '', label: '>' },
                        { to: '/projects', label: 'Project list' }
                    ]}
                />
            )}
            <div>
                <div className='name-content-project all-projects'>
                    <p>Project list</p>
                    <IconProjectGroup />
                </div>
                {!isSmallScreen && (
                    <div className='all-project-bom-header'>
                        <p className='all-project-bom-header-name'>Name</p>
                        <p className='all-project-bom-header-date'>Date</p>
                        <p className='all-project-bom-header-target'>Target Date</p>
                        <p className='all-project-bom-header-action'>Action</p>
                    </div>
                )}
                <div className='wrapper'>
                    <ul className="table-content-data-section-container-project">
                        {projects.map((project: ArchitectProject) => (
                            <li key={project.id}>
                                <section className="all-bom-project-data-section-project">
                                    <div className="all-bom-project-data-section-block-project">
                                        <p className='all-bom-project-content-title'>{project.title}</p>
                                        <p className='all-bom-project-content-data'>{new Date(project.created_at).toLocaleDateString()}</p>
                                        <p className='all-bom-project-content-target'>
                                            {project.target_date ? new Date(project.target_date).toLocaleDateString() : 'No date available'}
                                        </p>
                                    </div>
                                    <div className="table-content-data-section-field-actions-3btn">
                                        <button
                                            onClick={() => handleNavigate(project)}
                                            className='btn-view-project'
                                        >
                                            <span title='View project'
                                                  className='project-btn-view-span'>View project</span>
                                        </button>
                                        <button onClick={() => navigate(`/architect-users/${project.id}`)}
                                                className='team-action-btn'>
                                            <div title='User Permissions' className='setting'><IconSettings/></div>
                                        </button>
                                        <button onClick={() => handleDelete(project)} className='action-btn'>
                                            <div title='Remove project' className='delete'><IconRemove/></div>
                                        </button>
                                    </div>
                                </section>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <PaginationAssistance
                projectsPerPage={PROJECTS_PER_PAGE}
                totalProjects={totalProjects}
                paginate={paginate}
                currentPage={paginationState.currentPage}
            />
            {projectToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setProjectToDelete(null)}
                    onConfirm={handleRemoveProject}
                    onCancel={() => setProjectToDelete(null)}
                    message={`Are you sure you want to delete the project space "${projectToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    );
};


export default ProjectsListArchitect;
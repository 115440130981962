export enum UserRoleEnum {
    SAU = "SAU",
    MAU = "MAU",
    Customer = "Customer",
    Contractor = "Contractor",
    Architect = "Architect",
}

export const roleBom = (): boolean => {
    const role = localStorage.getItem('role') as UserRoleEnum;
    return role === UserRoleEnum.SAU || role === UserRoleEnum.Contractor || role === UserRoleEnum.Architect;
};

export const roleArhitector = (): boolean => {
    const role = localStorage.getItem('role') as UserRoleEnum;
    return role === UserRoleEnum.SAU || role === UserRoleEnum.Architect;
};
